import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NOTIFICATION_DISPOSITIONS } from './Notification.dispositions';

const ICONS = NOTIFICATION_DISPOSITIONS;
const DEFAULT_ICON = ['far', 'comment'];

const NotificationIcon = ({ source, className }) => {
  const icon = ICONS[source] || DEFAULT_ICON;
  return <FontAwesomeIcon icon={icon} className={className} />;
};

NotificationIcon.propTypes = {
  source: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default NotificationIcon;
