import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../Panel/Panel';
import SectionHeading from '../SectionHeading';
import BroadcastToggle from '../BroadcastToggle';

const BroadcastSettings = ({ optOuts, loading }) => {
  const isSubscribedToAppAdded = !optOuts.some(pref => pref.source === 'market' && pref.category === 'app_added');
  return (
    <Panel
      size="lg"
      isCollapsible
      isCollapsed
      isCollapseDisabled={false}
      label={<SectionHeading title="General Settings" subhead="General Notification Settings." loading={loading} />}
      className="subscription-settings"
    >
      <section className="app-subscription">
        <header className="app-subscription__header">
          <h3>New App Notifications</h3>
          <p>Get notified when new Apps are Added.</p>
        </header>
        <main className="app-subscription__body">
          <BroadcastToggle category="app_added" isSubscribed={isSubscribedToAppAdded} disabled={loading} />
        </main>
      </section>
    </Panel>
  );
};

BroadcastSettings.propTypes = {
  loading: PropTypes.bool,
  optOuts: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BroadcastSettings;
