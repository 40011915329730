import { compose, branch } from 'recompose';
import withVendor from './withVendor';
import withVendorPreview from './withVendorPreview';
import withVendorSnapshot from './withVendorSnapshot';
import VendorDetails from './VendorDetails';

const withPreviewOrSnapshot = branch(
  ({ match }) => match.params.version === 'preview',
  withVendorPreview,
  withVendorSnapshot
);
export default compose(branch(({ match }) => !!match.params.version, withPreviewOrSnapshot, withVendor))(VendorDetails);
