import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import TOGGLE_MODAL from '../../AppModal/ToggleModal.mutation';
import { DOWNLOAD_APP } from '../../AppModal/modalTypes';
import AssentToTerms from '../../AssentToTerms/AssentToTerms';
import Analytics from '../../../Analytics';

const recordDownload = async app =>
  Analytics.record('Download', { authenticated: 'false', appId: app.id, appName: app.name });

const UnauthenticatedDownload = ({ app, assentedToTerms }) => (
  <Mutation mutation={TOGGLE_MODAL} variables={{ modal: DOWNLOAD_APP }}>
    {showModal => (
      <AssentToTerms
        app={app}
        assentedToTerms={assentedToTerms}
        onSubmit={showModal}
        onExternalDownload={recordDownload}
      />
    )}
  </Mutation>
);

UnauthenticatedDownload.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }),
  assentedToTerms: PropTypes.bool
};

export default UnauthenticatedDownload;
