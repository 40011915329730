import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeButton } from 'fe-fabric-react';
import ExternalLink from '../../ExternalLink/ExternalLink';
import './NoDownload.css';

const NoDownload = ({ auth, location }) => {
  return (
    <>
      <p className="no-download">
        <FontAwesomeIcon icon={['far', 'info-circle']} className="unauthenticated" title="Authentication Required" />
        Downloading this app requires a FireEye subscription to use and is only accessible for FireEye users with an
        active FireEye Support account. If you already have an account, please{' '}
        <FeButton className="inline" feStyle="link" onClick={() => auth.login(location.pathname)} aria-label="Login">
          Login
        </FeButton>
        . Otherwise, please{' '}
        <ExternalLink href="https://www.fireeye.com/support/contacts/request-support-access.html" excludeIcon>
          Request Support Access
        </ExternalLink>{' '}
        or{' '}
        <ExternalLink href="https://www.fireeye.com/company/contact-us.html" excludeIcon>
          Contact Sales
        </ExternalLink>{' '}
        to learn more about becoming a FireEye customer.
      </p>
      <FeButton className="no-download__button" feStyle="secondary" disabled>
        <FontAwesomeIcon icon={['fas', 'download']} /> Download
      </FeButton>
    </>
  );
};

NoDownload.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default NoDownload;
