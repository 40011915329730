import React from 'react';
import PropTypes from 'prop-types';
import './Hero.css';

const Hero = ({ title, children }) => {
  return (
    <div className="hero">
      {children}
      {title && <h1 className="hero__title">{title}</h1>}
    </div>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default Hero;
