import { Component } from 'react';
import PropTypes from 'prop-types';

class Secure extends Component {
  render() {
    return this.props.render({
      auth: this.context.auth
    });
  }
}

Secure.contextTypes = {
  auth: PropTypes.object.isRequired
};

Secure.propTypes = {
  render: PropTypes.func.isRequired
};

export default Secure;
