import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TopNav = ({ feStyle, className, productType, children, renderLeft, renderRight, ...headerProps }) => {
  const headerClassName = classNames(
    'fe-top-nav',
    {
      'fe-top-nav__topbar': feStyle === 'topbar',
      'fe-top-nav__topnav': feStyle === 'topnav'
    },
    className
  );

  const leftClassName = classNames('fe-top-nav__title', {
    'fe-top-nav__bookend fe-top-nav__bookend--left': feStyle === 'topnav'
  });

  const rightClassName = classNames('fe-top-nav__action-container', {
    'fe-top-nav__bookend fe-top-nav__bookend--right': feStyle === 'topnav'
  });

  const navProps = {};
  if (children) {
    navProps.role = 'navigation';
    navProps['aria-label'] = 'Main';
  }

  return (
    <header {...headerProps} className={headerClassName}>
      <div className={leftClassName}>
        {productType && <div className={`fe-product fe-product--${productType}`} />}
        {renderLeft && renderLeft()}
      </div>
      <nav className="fe-top-nav__bar" {...navProps}>
        {children && (
          <ul className="fe-top-nav__bar-nav">
            {React.Children.map(children, child => (
              <li className="fe-top-nav__bar-nav-items">{child}</li>
            ))}
          </ul>
        )}
      </nav>
      <div className={rightClassName}>{renderRight && renderRight()}</div>
    </header>
  );
};

TopNav.propTypes = {
  feStyle: PropTypes.oneOf(['topbar', 'topnav']),
  className: PropTypes.string,
  productType: PropTypes.oneOf(['alpha', 'beta', 'gamma', 'delta', 'epsilon', 'zeta']),
  children: PropTypes.node,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func
};

TopNav.defaultProps = {
  feStyle: 'topnav'
};

export default TopNav;
