import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { graphql } from 'react-apollo';
import CustomPropTypes from '../../customPropTypes';
import ASSENT_TO_TERMS_MUTATION from './AssentToTerms.mutation';
import AwesomeCheckbox from '../AwesomeCheckbox/AwesomeCheckbox';
import DownloadLink from '../DownloadLink/DownloadLink';
import DownloadButton from '../DownloadButton/DownloadButton';

function AssentCheckbox({ app, assentToTerms, assentedToTerms }) {
  if (!app.isAssentRequired) return null;
  let preamble = null;
  if (app.type.id === 'iocs') {
    preamble = (
      <p className="preamble">
        These IOCs should be used as examples, starting points for tuning, or for testing. They should not be bulk
        uploaded to a production controller, as is.
      </p>
    );
  }

  const onAssentChanged = assent => assentToTerms({ variables: { id: app.id, assent } });
  return (
    <div className="form-group assent-form-group position-relative">
      {preamble}
      <AwesomeCheckbox onChange={onAssentChanged} checked={assentedToTerms} className="assent-to-terms">
        By checking this box, I agree to the Terms of Use for this software located&nbsp;
        <Link to="/termsofuse" className="assent-to-terms__link d-inline-block btn btn-link">
          here
        </Link>
        .
      </AwesomeCheckbox>
    </div>
  );
}

AssentCheckbox.propTypes = {
  app: PropTypes.shape({
    isAssentRequired: PropTypes.bool,
    type: CustomPropTypes.tag.isRequired
  }).isRequired,
  assentToTerms: PropTypes.func.isRequired,
  assentedToTerms: PropTypes.bool
};

const TermsInMarket = ({ render, app, assentToTerms, assentedToTerms, loading }) => {
  return (
    <>
      <AssentCheckbox app={app} assentToTerms={assentToTerms} assentedToTerms={assentedToTerms} />
      {render(
        app.distributions.map((distribution, index) => {
          const Download = distribution.uri ? DownloadLink : DownloadButton;
          return (
            <Download
              key={index}
              distribution={distribution}
              disabled={app.isAssentRequired && !assentedToTerms}
              loading={loading}
            />
          );
        })
      )}
    </>
  );
};

TermsInMarket.propTypes = {
  render: PropTypes.func,
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    distributions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    isAssentRequired: PropTypes.bool
  }).isRequired,
  assentToTerms: PropTypes.func.isRequired,
  assentedToTerms: PropTypes.bool,
  loading: PropTypes.bool
};

TermsInMarket.defaultProps = {
  assentedToTerms: false,
  render: button => button
};

export default graphql(ASSENT_TO_TERMS_MUTATION, {
  name: 'assentToTerms'
})(TermsInMarket);
