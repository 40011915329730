import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import CollectData from './CollectData';
import Analytics from '../../../Analytics';
import './DownloadApp.css';

class DownloadApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      company: '',
      role: '',
      phone: '',
      email: '',
      country: '',
      errors: [],
      loading: false,
      isIdentityPopulated: false
    };
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  notifySentry = (error, extras) => {
    Sentry.withScope(scope => {
      const { name, company, role, phone, email, country } = this.state;
      const contact = {
        name: name !== '',
        company: company !== '',
        role: role !== '',
        phone: phone !== '',
        email: email !== '',
        country: country !== ''
      };
      if (contact.email !== '') scope.setUser(email);
      scope.setExtras({
        contact,
        ...extras
      });
      scope.setLevel(Sentry.Severity.Critical);
      Sentry.captureException(error);
    });
  };

  onCompleted = ({ errors, download }) => {
    const { app } = this.props;
    if (errors.length === 0) {
      Analytics.record('Download', { authenticated: 'false', appId: app.id, appName: app.name });
      window.location.assign(download.url);
      this.props.closeModal();
    } else {
      Analytics.record('DownloadValidationError', { authenticated: 'false', appId: app.id, appName: app.name });
      const error = new Error('Unauthenticated Download Validation failed');
      error.name = 'DownloadValidationError';
      this.notifySentry(error, { errors });
      this.setState({ loading: false, errors });
    }
  };

  onError = error => {
    const { app } = this.props;
    Analytics.record('DownloadError', { authenticated: 'false', appId: app.id, appName: app.name });
    this.notifySentry(error);
    window.alert(error);
  };

  onClear = () => {
    this.setState({
      name: '',
      company: '',
      role: '',
      phone: '',
      email: '',
      country: '',
      errors: [],
      isIdentityPopulated: false
    });
  };

  onCancelled = () => {
    const { app } = this.props;
    Analytics.record('DownloadAbandoned', { authenticated: 'false', appId: app.id, appName: app.name });
  };

  onSubmit = () => {
    const { requestDownload } = this.props;
    const { name, company, role, phone, email, country } = this.state;
    const contact = { name, company, role, phone, email, country };
    this.setState({ loading: true });
    requestDownload(contact)
      .then(({ data: { requestDownload } }) => this.onCompleted(requestDownload))
      .catch(this.onError);
  };

  render() {
    const { isOpen } = this.props;
    const { name, company, role, phone, email, country, loading, errors } = this.state;
    const contact = { name, company, role, phone, email, country };

    return (
      <CollectData
        contact={contact}
        onChange={this.handleChange}
        onSubmit={this.onSubmit}
        onCancel={this.onCancelled}
        onClear={this.onClear}
        loading={loading}
        validationErrors={errors}
        isOpen={isOpen}
      />
    );
  }
}

DownloadApp.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  assentToTerms: PropTypes.bool.isRequired,
  requestDownload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array
};

DownloadApp.defaultProps = {
  isOpen: false,
  loading: false,
  errors: []
};

export default DownloadApp;
