import NotifcationsMenu from './NotificationsMenu';
import withNotificationsClient from '../../../withNotificationsClient';
import { getIdentity, ifAuthenticated } from '../../../notificationsIdentity';
import { branch, renderNothing, mapProps, renameProp } from 'recompose';
import { compose } from 'react-apollo';
import withNotificationStateCounts from '../withNotificationStateCounts';
import listNotifications from '../../Notifications/listNotifications';
import markAllAsSeen from '../../Notifications/markAllAsSeen';
import markAllAsRead from '../../Notifications/markAllAsRead';
import markAsRead from '../../Notifications/markAsRead';

const prepareNotifications = mapProps(ownerProps =>
  Object.assign({}, ownerProps, {
    notifications: ownerProps.notifications
      .slice(0, 10)
      .map(n => Object.assign({}, n, { createdAt: n.createdAt ? new Date(n.createdAt) : new Date() }))
  })
);

const composedComponent = compose(
  getIdentity,
  branch(
    ifAuthenticated,
    compose(
      withNotificationsClient,
      withNotificationStateCounts,
      listNotifications,
      markAllAsSeen,
      markAllAsRead,
      markAsRead,
      prepareNotifications,
      renameProp('notifications', 'items')
    ),
    renderNothing
  )
)(NotifcationsMenu);

composedComponent.displayName = 'ComposedNotificationsMenu';

export default composedComponent;
