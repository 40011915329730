import React from 'react';
import PropTypes from 'prop-types';
import { FeButton } from 'fe-fabric-react';
import DownloadIconWithText from '../DownloadIconWithText/DownloadIconWithText';

const DownloadButton = ({ loading, disabled, distribution }) => (
  <FeButton type="submit" feStyle="primary" disabled={loading || disabled}>
    <DownloadIconWithText loading={loading} type={distribution.type} />
  </FeButton>
);

DownloadButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  distribution: PropTypes.shape({
    type: PropTypes.string.isRequired
  }).isRequired
};

export default DownloadButton;
