import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FeIcon } from 'fe-fabric-react';

const SearchInput = ({ onSearchInputChanged, onClear, query, ...inputProps }) => {
  const classNames = 'fe-search';
  const inputClassNames = classnames('fe-search__input', { filled: !!query });
  const leftSectionClassName = classnames('fe-search__section--left', 'fe-search__icon--section');
  const rightSectionClassName = classnames('fe-search__section--right', 'fe-search__icon--section');
  return (
    <div className={classNames}>
      <input
        {...inputProps}
        aria-label="search input"
        type="text"
        value={query}
        onChange={onSearchInputChanged}
        className={inputClassNames}
      />
      <span className={leftSectionClassName}>
        <FeIcon icon="search" family="regular" />
      </span>
      <span className={rightSectionClassName} role="button" aria-label="clear" onClick={onClear}>
        <FeIcon icon="times-circle" family="regular" />
      </span>
    </div>
  );
};

SearchInput.propTypes = {
  onSearchInputChanged: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  query: PropTypes.string
};

export default SearchInput;
