import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import SimilarApps from './SimilarApps';

const SIMILAR_APPS = loader('./SimilarApps.graphql');
const props = ({ data: { app: { similar = [] } = { app: { related: [] } }, loading, error } }) => {
  return {
    apps: similar,
    loading,
    error
  };
};
const options = ({ id, limit = 4 }) => ({
  variables: { id, count: limit }
});
const withSimilarApps = graphql(SIMILAR_APPS, {
  alias: 'withSimilarApps',
  props,
  options
});

export default withSimilarApps(SimilarApps);
export { SIMILAR_APPS, props, options };
