import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import filterSets from './filtersSets';

const removeEmpty = obj =>
  Object.keys(obj)
    .filter(k => !isEmpty(obj[k]))
    .reduce((newObj, k) => Object.assign(newObj, { [k]: obj[k] }), {});

// Extract only whitelist variables from the querystring
const extractVariablesFromLocation = location => {
  const qsVars = queryString.parse(location.search);
  const values = Object.assign({ query: '', order: '' }, qsVars);
  if (values.order === '') values.order = null;
  filterSets.all.forEach(filter => {
    values[filter.group] = [values[filter.group] || []].reduce((acc, val) => acc.concat(val), []);
  });
  return values;
};

export { removeEmpty, extractVariablesFromLocation };
