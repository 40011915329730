import AuthenticatedDownload from './AuthenticatedDownload';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import AUTHENTICATED_DOWNLOAD_MUTATION from './AuthenticatedDownload.mutation';

const fragment = gql`
  fragment appDownload on App {
    downloadInfo {
      firstDownloaded
      lastDownloaded
    }
  }
`;

const getCacheUpdater = app => proxy => {
  const id = defaultDataIdFromObject(app);
  const data = proxy.readFragment({ id, fragment });
  if (data) {
    const downloadInfo = Object.assign({}, data.downloadInfo, {
      __typename: 'AppDownloadInfo',
      firstDownloaded: new Date().toJSON(),
      lastDownloaded: new Date().toJSON()
    });
    proxy.writeFragment({ id, fragment, data: Object.assign({}, data, { downloadInfo }) });
  }
};

const options = ({ app = {}, assentedToTerms = false }) => {
  const variables = { downloadRequest: { appId: app.id } };
  if (app.isAssentRequired) variables.downloadRequest.assentToTerms = assentedToTerms;
  const update = getCacheUpdater(app);
  return { variables, update };
};

const downloadApp = graphql(AUTHENTICATED_DOWNLOAD_MUTATION, { name: 'download', options });

const WrappedAuthenticatedDownload = compose(downloadApp)(AuthenticatedDownload);
WrappedAuthenticatedDownload.displayName = 'WrappedAuthenticatedDownload';
export default WrappedAuthenticatedDownload;
