import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { limit, NOTIFICATIONS_QUERY } from './listNotifications';
import { NOTIFICATION_COUNTS_QUERY } from './withNotificationStateCounts';

const SET_LAST_SEEN_NOTIFICATION = gql`
  mutation SetLastSeenNotification($id: ID!) {
    setLastSeenNotification(id: $id)
  }
`;

const skip = ({ isAuthenticated, loading, notifications = [] }) =>
  !isAuthenticated || loading || notifications.length === 0;

const options = ({ client, loading, notifications = [] }) => ({
  client,
  variables: {
    id: !loading && notifications.length > 0 ? notifications[0].id : undefined
  },
  optimisticResponse: {
    setLastSeenNotification: true
  },
  update
});

const update = cache => {
  const variables = { limit };
  const cachedData = cache.readQuery({ query: NOTIFICATIONS_QUERY, variables });
  const notifications = cachedData.notifications.map(n => Object.assign({}, n, { isSeen: true }));
  cache.writeQuery({ query: NOTIFICATIONS_QUERY, variables, data: { notifications } });

  const { unreadCount } = cache.readQuery({ query: NOTIFICATION_COUNTS_QUERY });
  cache.writeQuery({ query: NOTIFICATION_COUNTS_QUERY, data: { unseenCount: 0, unreadCount } });
};

const markAllAsSeen = graphql(SET_LAST_SEEN_NOTIFICATION, {
  name: 'markAllAsSeen',
  skip,
  options
});

export default markAllAsSeen;
export { SET_LAST_SEEN_NOTIFICATION, skip, options, update };
