import React from 'react';
import PropTypes from 'prop-types';
import BroadcastSettings from './BroadcastSettings';
import SubscriptionGroup from './SubscriptionGroup';
import './Settings.css';

const Settings = props => {
  const { downloadedApps, otherApps, ...commonProps } = props;
  return (
    <>
      <BroadcastSettings />
      <SubscriptionGroup
        title="Downloaded Apps"
        subhead="Automatically get notified when updates are available to apps you have downloaded."
        apps={downloadedApps}
        {...commonProps}
      />
      <SubscriptionGroup
        title="Other Apps"
        subhead="Automatically get notified when updates are available to apps you are watching."
        apps={otherApps}
        {...commonProps}
      />
    </>
  );
};

Settings.propTypes = {
  downloadedApps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      isSubscribable: PropTypes.bool,
      subscriptionInfo: PropTypes.shape({
        inApp: PropTypes.bool
      })
    })
  ).isRequired,
  otherApps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      isSubscribable: PropTypes.bool,
      subscriptionInfo: PropTypes.shape({
        inApp: PropTypes.bool
      })
    })
  ).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.any
};

export default Settings;
