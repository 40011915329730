import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from '../../Popover';
import { FeButton, FeBadge } from 'fe-fabric-react';
import MarkAllRead from '../../Notifications/MarkAllRead/MarkAllRead';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationList from '../../Notifications/NotificationList';
import './NotificationsMenu.css';

export const NotificationsMenuContent = ({
  items,
  unread,
  markAllRead,
  showSettingsIcon,
  loading,
  showDisposition,
  close,
  viewNotification,
  viewAll
}) => (
  <>
    <header className="popover-header">
      <span className="popover-header__title">Notifications</span>
      {items.length >= 1 && unread > 0 && <MarkAllRead onClick={markAllRead} />}
      {showSettingsIcon && (
        <NavLink
          className="fe-btn fe-btn--link fe-btn--sm popover-header__settings"
          to="/notifications/settings"
          exact
          onClick={close}
        >
          <FontAwesomeIcon className="popover-header__settings--icon" fixedWidth icon={['far', 'cog']} />
        </NavLink>
      )}
      <FeButton feStyle="link" size="sm" className="popover-header__closer" onClick={close}>
        <FontAwesomeIcon icon={['fal', 'times']} fixedWidth />
      </FeButton>
    </header>
    <NotificationList
      loading={loading}
      items={items}
      viewNotification={viewNotification}
      showDisposition={showDisposition}
    />
    {items.length >= 1 && (
      <FeButton
        onClick={viewAll}
        aria-label="View All Notifications"
        className="notifications-menu__view-all pointer"
        feStyle="link"
      >
        View All
      </FeButton>
    )}
  </>
);

class NotificationsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      items: [],
      unseen: 0,
      unread: 0
    };
  }

  onClose = () => document.body.classList.remove('notifications-no-scroll');

  onOpen = () => {
    this.markAllAsSeen();
    document.body.classList.add('notifications-no-scroll');
  };

  viewNotification = (notification, event) => {
    if (!notification.isRead) this.props.markAsRead(notification);
    this.props.onViewNotification(notification, event);
  };

  viewAll = event => {
    const { onViewAll } = this.props;
    if (onViewAll) onViewAll(event);
  };

  markAllRead = () => {
    const { markAllAsRead } = this.props;
    if (markAllAsRead) markAllAsRead();
  };

  markAllAsSeen = () => {
    const { unseen } = this.state;
    if (unseen === 0) return;
    const { markAllAsSeen } = this.props;
    if (markAllAsSeen) markAllAsSeen();
  };

  componentWillUnmount() {
    document.body.classList.remove('notifications-no-scroll');
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, items, unseen, unread } = nextProps;
    const nextState = {};
    if (loading !== prevState.loading) nextState.loading = loading;
    if (items) nextState.items = items;
    if (unseen !== prevState.unseen) nextState.unseen = unseen;
    if (unread !== prevState.unread) nextState.unread = unread;

    if (Object.keys(nextState).length > 0) return nextState;
    return null;
  }

  render() {
    const { loading, items, unseen, unread } = this.state;
    const { showDisposition, showSettingsIcon } = this.props;

    const trigger = ({ toggle }) => (
      <div onClick={toggle} className="notifications-menu__toggle pointer">
        <FontAwesomeIcon className="notifications-menu__popover--icon" icon={['fas', 'bell']} fixedWidth />
        {unseen > 0 && <FeBadge feStyle="alert" label={String(unseen)} max={99} />}
      </div>
    );

    return (
      <div className={classNames('notifications-menu', { 'notifications-menu--has-unread': unread > 0 })}>
        <Popover
          trigger={trigger}
          placement="bottom-end"
          modifiers={{ offset: { offset: 8 } }}
          className="notifications-menu__popover"
          onClose={this.onClose}
          onOpen={this.onOpen}
        >
          {({ close }) => (
            <NotificationsMenuContent
              loading={loading}
              items={items}
              unread={unread}
              markAllRead={this.markAllRead}
              showSettingsIcon={showSettingsIcon}
              showDisposition={showDisposition}
              close={close}
              viewNotification={this.viewNotification}
              viewAll={this.viewAll}
            />
          )}
        </Popover>
      </div>
    );
  }
}

NotificationsMenu.defaultProps = {
  loading: false,
  items: [],
  unseen: 0,
  unread: 0
};

NotificationsMenu.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onViewNotification: PropTypes.func,
  onViewAll: PropTypes.func,
  markAllAsSeen: PropTypes.func,
  markAllAsRead: PropTypes.func,
  markAsRead: PropTypes.func,
  showDisposition: PropTypes.bool,
  showSettingsIcon: PropTypes.bool,
  unseen: PropTypes.number,
  unread: PropTypes.number
};

export default NotificationsMenu;
