import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class Security extends Component {
  constructor(props) {
    super(props);
    this.auth = props.auth;
  }

  getChildContext() {
    return {
      auth: this.auth
    };
  }

  static childContextTypes = {
    auth: PropTypes.object.isRequired
  };

  render() {
    return <div className="security">{this.props.children}</div>;
  }
}

Security.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withRouter(Security);
