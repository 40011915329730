import Mixpanel from 'mixpanel-browser';

const identify = async cognitoUser => {
  const session = cognitoUser.signInUserSession;
  if (!session) return;
  const idTokenClaims = session.idToken.payload;
  const { sub, name, email, auth_time: last_auth } = idTokenClaims;
  const groups = idTokenClaims['cognito:groups'];
  const domain = email.split('@')[1].toLowerCase();
  Mixpanel.identify(sub);
  Mixpanel.people.set({ groups, name, domain, last_auth, $email: email });
};
const linkIds = async (newId, existingId) => Mixpanel.alias(newId, existingId);
const record = async (name, attributes = {}) => Mixpanel.track(name, attributes);
const reset = () => {
  Mixpanel.reset();
  Mixpanel.register({ origin: window.location.host });
};
const disable = () => Mixpanel.opt_out_tracking();
const enable = () => Mixpanel.opt_in_tracking();

export default { identify, linkIds, record, reset, disable, enable };
