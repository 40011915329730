import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

class NotificationGroup extends Component {
  state = {
    height: this.props.isOpen ? 'auto' : 0
  };

  toggle = () => {
    this.setState({
      height: this.state.height === 0 ? 'auto' : 0
    });
  };

  render() {
    const { list, className, render } = this.props;
    if (!list || list.length === 0) return null;
    return (
      <AnimateHeight duration={500} height={this.state.height} animateOpacity>
        <section className={className}>{list.map(render)}</section>
      </AnimateHeight>
    );
  }
}

NotificationGroup.defaultProps = {
  isOpen: false
};

NotificationGroup.propTypes = {
  className: PropTypes.string,
  render: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool
};

export default NotificationGroup;
