import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeButton } from 'fe-fabric-react';
import DownloadLink from '../../DownloadLink/DownloadLink';
import { titleize } from 'inflected';
import CustomPropTypes from '../../../customPropTypes';

const HelixParserDownload = ({ app, requirement }) => {
  const { description, id } = requirement;
  return (
    <>
      <p className="no-download">
        <FontAwesomeIcon icon={['far', 'info-circle']} className="key-required" title={titleize(id)} />
        {description}
      </p>
      {app.distributions.map((distribution, index) => {
        const DownloadButton = (
          <FeButton className="no-download__button" feStyle="secondary" disabled>
            <FontAwesomeIcon icon={['fas', 'download']} /> Download
          </FeButton>
        );
        const Download = distribution.uri ? DownloadLink : DownloadButton;
        return <Download key={index} distribution={distribution} />;
      })}
    </>
  );
};

HelixParserDownload.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  requirement: CustomPropTypes.downloadRequirement.isRequired
};

HelixParserDownload.displayName = 'HelixParserDownload';

export default HelixParserDownload;
