import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import { Carousel } from 'react-responsive-carousel';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import VideoThumbnail from '../../assets/images/temp-video-thumbnail.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './AppCarousel.css';

class AppCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      lightboxCurrentSlide: 0,
      lightboxIsOpen: false
    };
    this.videoRefs = [];
  }

  updateSlide = index => {
    let { currentSlide } = this.state;
    this.videoRefs.forEach(video => video.pause());
    if (currentSlide !== index) this.setState({ currentSlide: index });
  };

  updateLightboxSlide = index => {
    let { lightboxCurrentSlide } = this.state;

    if (lightboxCurrentSlide !== index) this.setState({ lightboxCurrentSlide: index });
  };

  toggleLightbox = () => {
    this.setState({ lightboxIsOpen: !this.state.lightboxIsOpen });
  };

  showLightbox = (index, element) => {
    if (/pointer/.test(element.props.className)) {
      this.setState({ lightboxCurrentSlide: index }, () => {
        this.setState({ lightboxIsOpen: true });
      });
    }
  };

  render() {
    const { overview, demos } = this.props;
    let slides = [];
    let lightboxSlides = [];

    const carouselSlide = (item, key) => {
      switch (item.__typename) {
        case 'Image':
          return (
            <div className={classNames('image-container', demos.length >= 1 ? 'pointer' : '')} key={key}>
              <img className="image-container__image" src={item.uri} alt={item.name} />
            </div>
          );

        case 'Video':
          return (
            <div className="video-container" key={key}>
              <VideoPlayer ref={node => (this.videoRefs[key] = node)} uri={item.uri} key={key} />
              <img className="video-thumbnail" src={VideoThumbnail} alt={item.description} />
            </div>
          );

        default:
          return null;
      }
    };

    const lightboxSlide = (item, key) => {
      return <img className="lightbox-carousel__image" src={item.x2Uri || item.x1Uri} key={key} alt={item.name} />;
    };

    if (overview.uri) {
      slides.push(carouselSlide(overview, 'overview'));
      if (demos.length >= 1) lightboxSlides.push(lightboxSlide(overview, 'overview'));
    }

    demos.forEach((item, index) => {
      slides.push(carouselSlide(item, index));
      if (item.__typename === 'Image') lightboxSlides.push(lightboxSlide(item, index));
    });

    return (
      <div className="app-carousel">
        <Carousel
          className="app-carousel-details"
          onClickItem={this.showLightbox}
          onChange={this.updateSlide}
          selectedItem={this.state.currentSlide}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={slides.length > 1}
        >
          {slides}
        </Carousel>
        <Modal size="fullscreen" isOpen={this.state.lightboxIsOpen} className="app-carousel-lightbox">
          {({ closeModal }) => {
            const onCloseClick = () => {
              this.toggleLightbox();
              closeModal();
            };

            return (
              <>
                <ModalHeader onCloseClick={onCloseClick} />
                <ModalBody>
                  <Carousel
                    className="lightbox-carousel"
                    dynamicHeight
                    onChange={this.updateLightboxSlide}
                    showIndicators={false}
                    showStatus={false}
                    selectedItem={this.state.lightboxCurrentSlide}
                    showThumbs={false}
                  >
                    {lightboxSlides}
                  </Carousel>
                </ModalBody>
              </>
            );
          }}
        </Modal>
      </div>
    );
  }
}

AppCarousel.propTypes = {
  name: PropTypes.string,
  overview: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    x1Uri: PropTypes.string,
    x2Uri: PropTypes.string
  }),
  demos: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      description: PropTypes.string,
      x1Uri: PropTypes.string,
      x2Uri: PropTypes.string
    }).isRequired
  ).isRequired
};

export default AppCarousel;
