import queryString from 'query-string';
import { Credentials, Hub, ConsoleLogger as Logger } from '@aws-amplify/core';
import {
  CognitoUser,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserSession
} from 'amazon-cognito-identity-js';

const ANONYMOUS_USERNAME = 'anonymous';
class Auth {
  constructor(amplifyAuth, history, logger) {
    this.amplifyAuth = amplifyAuth;
    this._config = amplifyAuth.configure();
    this._history = history;
    this._logger = logger || new Logger('Auth');
  }

  /**
   * Gets the current CognitoUser instance, complete with a populated CognitoSession, if available
   *
   * @memberof Auth
   * @returns Promise<CognitoUser>
   */
  currentUser = async () => {
    return new Promise((resolve, reject) => {
      const user = this.amplifyAuth.userPool.getCurrentUser();
      if (!user) {
        this._logger.debug('Failed to get user from user pool');
        reject('No current user');
        return;
      }

      // CognitoUser.getSession will:
      // 1- pull the tokens out of local storage
      // 2- validate that the tokens are still valid, and attempt to fetch new tokens if not
      // 3- set the signInUserSession on the user
      user.getSession(err => {
        if (err) {
          this._logger.debug('Failed to get the user session', err);
          reject(err);
          return;
        }
        resolve(user);
      });
    });
  };

  /**
   * Gets the current CognitoSession, if available
   *
   * @memberof Auth
   * @returns Promise<CognitoSession>
   */
  currentSession = async () => {
    return this.currentUser().then(user => user.getSignInUserSession());
  };

  /**
   * Determine if the user is authenticated or not
   *
   * @returns Promise<bool>
   * @memberof Auth
   */
  isAuthenticated = async () => {
    return this.currentUser()
      .then(() => true)
      .catch(() => false);
  };

  isAnonymous = async () => {
    return this.currentUser()
      .then(cognitoUser => cognitoUser.username === ANONYMOUS_USERNAME)
      .catch(() => true);
  };

  getUser = async () =>
    this.currentSession()
      .then(session => session.getIdToken().payload)
      .catch(() => undefined);

  getAccessToken = async () =>
    this.currentSession()
      .then(session => session.getAccessToken().jwtToken)
      .catch(() => undefined);

  /**
   * Redirects to the configured Cognito Hosted Login
   * Optionally directly authenticate with a specific provider
   *
   * @param {String} identityProvider
   * @memberof Auth
   */
  login = async fromUri => {
    localStorage.setItem(
      'secureRouterReferrerPath',
      JSON.stringify(fromUri ? { pathname: fromUri } : this._history.location)
    );
    const { domain, redirectSignIn, responseType, scope = [], identityProvider } = this._config.oauth;
    const options = this._config.oauth.options || {};
    const qsParams = {
      redirect_uri: redirectSignIn,
      response_type: responseType,
      client_id: options.ClientId || this._config.userPoolWebClientId,
      scope: scope.join(' ')
    };
    if (identityProvider) {
      qsParams.identity_provider = identityProvider;
    }

    const url = `https://${domain}/oauth2/authorize?${queryString.stringify(qsParams)}`;
    window.location.assign(url);
  };

  /**
   * Attempts to resume the user's login session.
   * Note that anonymous users will also be "authenticated" users after starting
   * an unauthenticated session
   *
   * @memberof Auth
   */
  resumeSession = async () => {
    this._logger.debug('Attempting to resumeSession');
    return this.currentUser().then(cognitoUser => cognitoUser.getSignInUserSession());
  };

  /**
   * Attempts to start a new anonymous session
   * This results in an authenticated session with anonymous credentials
   *
   * @memberof Auth
   */
  startAnonymousSession = async () => {
    this._logger.debug('Attempting to startAnonymousSession');
    return fetch(`${process.env.REACT_APP_API_URL}/token`, { method: 'POST' })
      .then(result => result.json())
      .then(result => result.AuthenticationResult)
      .then(
        result =>
          new CognitoUserSession({
            IdToken: new CognitoIdToken(result),
            AccessToken: new CognitoAccessToken(result),
            RefreshToken: new CognitoRefreshToken(result)
          })
      )
      .then(async session => {
        await Credentials.clear();
        const cognitoUser = new CognitoUser({
          Username: ANONYMOUS_USERNAME,
          Pool: this.amplifyAuth.userPool,
          Storage: this.amplifyAuth._storage
        });
        cognitoUser.setSignInUserSession(session);
        const cred = await Credentials.set(session, 'session');
        Hub.dispatch('anon_auth', { event: 'signIn', data: cognitoUser });
        this._logger.debug('sign in succefully with', cred);
        return session;
      })
      .catch(error => {
        this._logger.error("Can't start anonymous session", error);
        return undefined;
      });
  };

  /**
   * Attempts to resume the session if the user is authenticated, or start a new anonymous session otherwise
   *
   * @memberof Auth
   */
  resumeOrStartSession = async () =>
    this.isAuthenticated().then(async isAuthenticated => {
      if (isAuthenticated) return await this.resumeSession();
      else return await this.startAnonymousSession();
    });

  /**
   * Resets the current session by signing out the current user and calling
   * on Amplify's AuthClass to re-initialize, including attempting to pull tokens
   * out of the URL and establish a new Authenticated session
   *
   * @memberof Auth
   */
  resetSession = async () => {
    await Credentials.clear();
    const currentUser = this.amplifyAuth.userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
  };

  logout = async ({ global } = { global: false }) => {
    await this.amplifyAuth.signOut({ global });
  };
}

export default Auth;
