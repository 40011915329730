import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ExternalLink.css';

const ExternalLink = ({ href, children, excludeIcon, className, ...anchorProps }) => (
  <a
    href={href}
    target="_blank"
    className={classNames('external-link', className)}
    rel="noopener noreferrer"
    {...anchorProps}
  >
    {children}
    {excludeIcon ? null : (
      <FontAwesomeIcon icon={['fas', 'external-link-alt']} size="sm" className="external-link__icon" />
    )}
  </a>
);

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  excludeIcon: PropTypes.bool,
  className: PropTypes.string
};

ExternalLink.defaultProps = {
  excludeIcon: false
};

export default ExternalLink;
