import 'react-app-polyfill/ie11';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/function/name';
import 'core-js/fn/object/values';
import 'core-js/es6/symbol';
import 'core-js/fn/symbol/iterator';
import 'core-js/fn/string/includes';
import 'core-js/fn/object/entries';
import 'cross-fetch/polyfill';
import 'url-polyfill';
import 'core-js/fn/map';
import 'core-js/fn/math/sign';
import 'core-js/fn/array/find-index';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { createBrowserHistory } from 'history';
import { InMemoryCache } from 'apollo-cache-inmemory';
import createAuth from './amplifyConfig';
import createClient, { createFragmentMatcher } from './graphqlClient';
import createNotificationsClient from './notificationsClient';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import './FontAwesome';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import App from './App';
import './Fabric-Light.css';
import './FabricOverrides.css';
import './index.css';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
    release: `market@${process.env.REACT_APP_REVISION}`
  });
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.register({ origin: window.location.host });
if (window.navigator.userAgent === 'ReactSnap') mixpanel.opt_out_tracking();

JavascriptTimeAgo.locale(en);

const history = createBrowserHistory();
const fragmentMatcher = createFragmentMatcher();
const cache = new InMemoryCache({ fragmentMatcher });
const auth = createAuth(history, cache);
const client = createClient(auth, cache);
const notificationsClient = createNotificationsClient(auth);
const adminConfig = {};
const { REACT_APP_VENDOR_ADMIN_GROUPS } = process.env;
if (REACT_APP_VENDOR_ADMIN_GROUPS) {
  adminConfig.vendorAdminGroups = REACT_APP_VENDOR_ADMIN_GROUPS.split(',');
}

history.listen(() => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
});

window.snapSaveState = () => ({
  __APOLLO_STATE__: client.extract()
});

const Application = () => (
  <App
    client={client}
    adminConfig={adminConfig}
    notificationsClient={notificationsClient}
    auth={auth}
    history={history}
  />
);

const rootElement = document.getElementById('root');
const isPreRendered = rootElement.hasChildNodes();
if (isPreRendered) {
  hydrate(<Application />, rootElement);
} else {
  render(<Application />, rootElement);
}
