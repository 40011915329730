import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import noImageAvailable from '../../../assets/images/no-image-available.png';

const AppTileImage = ({ app }) => {
  let imageUrl = app.listing && app.listing.x2Uri ? app.listing.x2Uri : noImageAvailable;
  const style = { backgroundImage: `url(${imageUrl})` };
  return (
    <div className="tile__image-container">
      <div className="tile__image" style={style} />
    </div>
  );
};
AppTileImage.propTypes = {
  app: PropTypes.shape({
    listing: PropTypes.shape({
      x2Uri: PropTypes.string
    })
  }).isRequired
};

const AppTileBody = ({ app, linkTo }) => {
  if (app.summary) {
    return (
      <>
        <Link to={linkTo}>
          <AppTileImage app={app} />
        </Link>
        <div className="tile__summary">
          <h5 className="tile__summary-title">{app.name}</h5>
          <p className="tile__summary-text">{app.summary}</p>
        </div>
      </>
    );
  } else {
    return (
      <Link to={linkTo}>
        <AppTileImage app={app} />
        <h5 className="tile__summary-title">{app.name}</h5>
      </Link>
    );
  }
};
AppTileBody.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string.isRequired,
    summary: PropTypes.string
  }),
  linkTo: PropTypes.object
};

export default AppTileBody;
