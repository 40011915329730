import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { FeLoader } from 'fe-fabric-react';
import './Icon.css';

const Icon = ({ loading, size }) => {
  return (
    <CSSTransition in={loading} timeout={1000} classNames="fade" appear mountOnEnter unmountOnExit>
      <FeLoader feSize={size} className="loading-icon" />
    </CSSTransition>
  );
};

Icon.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['xxl', 'xl', 'l', 'sm'])
};

Icon.defaultProps = {
  size: 'l'
};

export default Icon;
