import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarMenu from '../AvatarMenu/AvatarMenu';
import { FeButton } from 'fe-fabric-react';
import { Query } from 'react-apollo';
import HEADER_IDENTITY_QUERY from './HeaderAuthItem.query';

const HeaderAuthItem = ({ auth, location }) => {
  if (window.navigator.userAgent === 'ReactSnap')
    return <FontAwesomeIcon icon={['fas', 'spinner-third']} spin size="2x" />;
  return (
    <Query query={HEADER_IDENTITY_QUERY}>
      {({ loading, error, data }) => {
        if (loading || error) {
          return null;
        }
        const {
          authentication: { isAuthenticated, identity }
        } = data;

        return isAuthenticated ? (
          <AvatarMenu
            identity={identity}
            settingsUrl={process.env.REACT_APP_USER_PROFILE_URL}
            onLogoutClick={() => auth.logout({ global: true })}
          />
        ) : (
          <FeButton onClick={() => auth.login(location.pathname)} feStyle="link" className="login">
            Login
          </FeButton>
        );
      }}
    </Query>
  );
};

HeaderAuthItem.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

export default HeaderAuthItem;
