import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import withAuth from './withAuth';

class RenderWrapper extends Component {
  render() {
    if (!this.props.authenticated) {
      return null;
    }

    const C = this.props.component;
    return this.props.render ? this.props.render(this.props.renderProps) : <C {...this.props.renderProps} />;
  }
}

RenderWrapper.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.func,
  render: PropTypes.func,
  renderProps: PropTypes.object
};

RenderWrapper.defaultProps = {
  authenticated: false
};

class SecureRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: null
    };

    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.createRenderWrapper = this.createRenderWrapper.bind(this);

    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  createRenderWrapper(renderProps) {
    return (
      <RenderWrapper
        authenticated={this.state.authenticated}
        login={this.props.auth.login}
        component={this.props.component}
        render={this.props.render}
        renderProps={renderProps}
      />
    );
  }

  render() {
    return <Route path={this.props.path} render={this.createRenderWrapper} />;
  }
}

SecureRoute.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired
  }).isRequired,
  path: PropTypes.string,
  component: PropTypes.func,
  render: PropTypes.func
};

export default withAuth(SecureRoute);
