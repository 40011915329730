import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'fe-fabric-react';
import { TagList, LinkTag } from '../Tag';
import noImageAvailable from '../../assets/images/no-image-available.png';

function toAbsoluteUrl(possiblyRelativeUrl) {
  const url = new URL(possiblyRelativeUrl, window.location.origin);
  return url.href;
}

const VendorInfo = ({ name, logo, about, tagSets }) => {
  const imgSrc = logo && logo.x2Uri ? toAbsoluteUrl(logo.x2Uri) : noImageAvailable;
  return (
    <Row>
      <Col
        className="vendor-details__logo"
        sm={12}
        md={{
          size: 10,
          offset: 1
        }}
        lg={{
          size: 4,
          offset: 1
        }}
      >
        <img src={imgSrc} alt={`${name} logo`} className="vendor-details__logo-img" />
      </Col>
      <Col className="vendor-details__description" md={12} lg={6}>
        <h1 className="vendor-details__name">{name}</h1>
        <div className="vendor-details__description--text" dangerouslySetInnerHTML={{ __html: about }} />
        <div className="vendor-details__tags">
          <h3>Tags</h3>
          <TagList tagSets={tagSets}>
            {({ tag, group }) => (
              <LinkTag to={{ pathname: '/vendors', search: `?${group}=${tag.id}` }}>{tag.name}</LinkTag>
            )}
          </TagList>
        </div>
      </Col>
    </Row>
  );
};

VendorInfo.displayName = 'VendorInfo';
VendorInfo.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    x2Uri: PropTypes.string
  }).isRequired,
  about: PropTypes.string.isRequired,
  tagSets: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  ).isRequired
};

export default VendorInfo;
