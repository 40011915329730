import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const FacebookButton = () => {
  const qs = { u: window.location.href };
  return (
    <a
      className="fe-btn fe-btn--link fe-btn--md"
      href={`https://www.facebook.com/sharer/sharer.php?${queryString.stringify(qs)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={['fab', 'facebook-f']} fixedWidth /> Facebook
    </a>
  );
};

export default FacebookButton;
