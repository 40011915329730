import React from 'react';
import PropTypes from 'prop-types';
import { FeIcon } from 'fe-fabric-react';

const DownloadIconWithText = ({ loading, type }) => {
  let icon;
  let text;
  switch (type) {
    case 'fireeye.com':
      icon = { family: 'solid', icon: 'external-link-alt' };
      text = 'Register on FireEye.com';
      break;
    case 'github':
      icon = { family: 'brand', icon: 'github' };
      text = 'Learn More';
      break;
    case 'itunes':
      icon = { family: 'brand', icon: 'apple' };
      text = 'Download on the App Store';
      break;
    case 'google':
      icon = { family: 'brand', icon: 'google-play' };
      text = 'Get it on Google Play';
      break;
    case 'bundle':
      icon = { family: 'regular', icon: 'box' };
      text = 'Go to Content Bundle';
      break;
    case 'fireeye':
    case 'binary':
      icon = { family: 'solid', icon: 'download' };
      text = 'Download';
      break;
    default:
      icon = { family: 'solid', icon: 'external-link-alt' };
      text = 'View on 3rd Party Site';
      break;
  }

  return (
    <>
      {loading ? <FeIcon icon="spinner-third" family="solid" spin /> : <FeIcon {...icon} className="fe-icon--left" />}{' '}
      {text}
    </>
  );
};

DownloadIconWithText.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default DownloadIconWithText;
