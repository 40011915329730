import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Tabs.css';

export const TabItem = ({ active, children }) => {
  const className = classnames('tabs__tab', { 'tabs__tab--active': !!active });
  return <li className={className}>{children}</li>;
};

TabItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node
};

const Tabs = ({ orientation, children, label, className, activeIndex }) => {
  const classNames = classnames('tabs', `tabs--${orientation}`, className);
  return (
    <nav className={classNames} aria-label={label}>
      <ul className="tabs__list">
        {React.Children.map(children, (child, index) => (
          <TabItem active={index === activeIndex}>{child}</TabItem>
        ))}
      </ul>
    </nav>
  );
};

Tabs.defaultProps = {
  orientation: 'horizontal',
  activeIndex: -1
};

Tabs.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.node,
  activeIndex: PropTypes.number,
  className: PropTypes.string,
  label: PropTypes.string
};

export default Tabs;
