import React from 'react';
import PropTypes from 'prop-types';
import DownloadLink from '../DownloadLink/DownloadLink';
import DownloadButton from '../DownloadButton/DownloadButton';

const TermsOn3rdPartySite = ({ loading, render, app, onExternalDownload }) => (
  <>
    <p>The Terms of Use for this software are subject to the licensing and terms on the 3rd party site.</p>
    {render(
      app.distributions.map((distribution, index) => {
        const Download = distribution.uri ? DownloadLink : DownloadButton;
        const onDownload = distribution.uri ? () => onExternalDownload(app) : () => {};
        return (
          <Download
            key={index}
            distribution={distribution}
            disabled={loading}
            loading={loading}
            onDownload={onDownload}
          />
        );
      })
    )}
  </>
);

TermsOn3rdPartySite.propTypes = {
  loading: PropTypes.bool,
  render: PropTypes.func,
  app: PropTypes.shape({
    distributions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  onExternalDownload: PropTypes.func.isRequired
};

TermsOn3rdPartySite.defaultProps = {
  render: button => button
};

export default TermsOn3rdPartySite;
