import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';

const AVAILABLE_FILTERS = loader('./AvailableFilters.graphql');
export const props = ({
  data: { products = [], thirdPartyProducts = [], categories = [], types = [], platforms = [], loading, error }
}) => {
  const appFilters = Object.assign(
    {},
    {
      products,
      thirdPartyProducts,
      categories,
      types,
      platforms
    }
  );
  const vendorFilters = Object.assign(
    {},
    {
      products,
      categories,
      type: [{ id: 'technology_partner', name: 'Technology Partner', __typename: 'Tag' }]
    }
  );
  return {
    availableFilters: {
      apps: appFilters,
      vendors: vendorFilters
    },
    loading: loading,
    error: error
  };
};

const withAvailableFilters = graphql(AVAILABLE_FILTERS, {
  alias: 'withAvailableFilters',
  props
});

withAvailableFilters.displayName = 'withAvailableFilters';

export default withAvailableFilters;
