import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import FeaturedApps from './FeaturedApps';

const FEATURED_APPS = loader('./FeaturedApps.graphql');
const props = ({ data: { paginatedApps: { apps = [] } = { apps: [] }, loading, error } }) => ({
  apps,
  loading,
  error
});
const listFeaturedApps = graphql(FEATURED_APPS, { alias: 'withFeaturedApps', props });

export default listFeaturedApps(FeaturedApps);
export { FEATURED_APPS, props };
