import React from 'react';
import Settings from './Settings';
import { UnauthorizedError } from '../../Error/Error';
import listAppsForNotificationSettings from './listAppsForNotificationSettings';
import { compose } from 'react-apollo';
import { branch, mapProps, renderComponent } from 'recompose';
import { getIdentity, ifAuthenticated } from '../../../notificationsIdentity';

const splitAppsByDownloadStatus = ({ apps = [], loading, error }) => {
  const downloadedApps = [];
  const otherApps = [];
  apps.forEach(app => {
    if (app.downloadInfo && app.downloadInfo.lastDownloaded) {
      downloadedApps.push(app);
    } else {
      otherApps.push(app);
    }
  });
  return { downloadedApps, otherApps, loading, error };
};

const ComposedSettings = compose(
  getIdentity,
  branch(
    ifAuthenticated,
    compose(
      listAppsForNotificationSettings,
      mapProps(splitAppsByDownloadStatus)
    ),
    renderComponent(() => <UnauthorizedError reason="You must authenticate to change Notification Settings" />)
  )
)(Settings);
ComposedSettings.displayName = 'ComposedSettings';

export default ComposedSettings;
