import React from 'react';
import PropTypes from 'prop-types';
import AppTile from './AppTile';
import VendorTile from './VendorTile';

const Tile = ({ data }) => {
  const tileTypes = {
    App: AppTile,
    Vendor: VendorTile,
    VendorSnapshot: VendorTile
  };
  const TileType = tileTypes[data.__typename];
  if (!TileType) return null;
  return <TileType data={data} />;
};

Tile.propTypes = {
  data: PropTypes.object.isRequired
};

Tile.displayName = 'Tile';
export default Tile;
