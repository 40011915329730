import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const PREVIEW_COMPATIBLE_APPS = loader('./PreviewCompatibleApps.graphql');

const skip = ({ vendor }) => vendor === undefined;

const options = ({ vendor }) => ({
  variables: {
    vendor,
    limit: 4
  }
});

const props = ({ data: { loading, vendor, error } }) => {
  let name = 'Vendor';
  let compatibleApps = { total: 0, apps: [] };
  if (vendor) {
    name = vendor.name;
    compatibleApps = vendor.compatibleApps;
  }
  return {
    loading,
    name,
    compatibleApps,
    error
  };
};

const withPreviewCompatibleApps = graphql(PREVIEW_COMPATIBLE_APPS, {
  alias: 'withPreviewCompatibleApps',
  skip,
  options,
  props
});

export default withPreviewCompatibleApps;
export { skip, options, props };
