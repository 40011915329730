import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CustomPropTypes from '../../customPropTypes';
import AuthenticatedDownload from './AuthenticatedDownload';
import UnauthenticatedDownload from './UnauthenticatedDownload/UnauthenticatedDownload';
import NoDownload from './NoDownload';
import EndpointTechPreviewDownload from './EndpointTechPreviewDownload/EndpointTechPreviewDownload';
import EndpointModuleDownload from './EndpointModuleDownload/EndpointModuleDownload';
import HelixParserDownload from './HelixParserDownload/HelixParserDownload';
import IntelFeedDownload from './IntelFeedDownload/IntelFeedDownload';

const REQUIREMENT_COMPONENTS = {
  ContactInfo: UnauthenticatedDownload,
  AuthenticationRequired: withRouter(NoDownload),
  EndpointTechPreview: EndpointTechPreviewDownload,
  EndpointModules: EndpointModuleDownload,
  ParserIncludedInProduct: HelixParserDownload,
  ThirdPartyIntelFeed: IntelFeedDownload,
};

const Download = ({ app, assentedToTerms, hasBeenDownloaded, subscribe }) => {
  if (app.downloadRequirements.length === 0)
    return (
      <AuthenticatedDownload
        app={app}
        assentedToTerms={assentedToTerms}
        hasBeenDownloaded={hasBeenDownloaded}
        subscribe={subscribe}
      />
    );
  return app.downloadRequirements
    .map((requirement, index) => {
      const Component = REQUIREMENT_COMPONENTS[requirement.id];
      return Component ? (
        <Component key={`requirement${index}`} app={app} assentedToTerms={assentedToTerms} requirement={requirement} />
      ) : null;
    })
    .filter(Boolean);
};

Download.defaultProps = {
  app: {
    downloadRequirements: [],
  },
};

Download.propTypes = {
  app: PropTypes.shape({
    downloadRequirements: PropTypes.arrayOf(CustomPropTypes.downloadRequirement).isRequired,
  }).isRequired,
  assentedToTerms: PropTypes.bool,
  hasBeenDownloaded: PropTypes.bool,
};

export default Download;
