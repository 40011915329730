/* eslint-disable react/display-name */
import React from 'react';
import withCompatibleApps from './withCompatibleApps';
import withPreviewCompatibleApps from './withPreviewCompatibleApps';
import withSnapshotCompatibleApps from './withSnapshotCompatibleApps';
import CompatibleApps from './CompatibleApps';

const compatibleApps = BaseComponent => props => {
  let Component;
  switch (props.version) {
    case undefined:
      Component = withCompatibleApps(BaseComponent);
      break;
    case 'preview':
      Component = withPreviewCompatibleApps(BaseComponent);
      break;
    default:
      Component = withSnapshotCompatibleApps(BaseComponent);
      break;
  }
  return <Component {...props} />;
};

export default compatibleApps(CompatibleApps);
