import gql from 'graphql-tag';

const VENDORS_FRAGMENT = gql`
  fragment VendorsSearchResults on VendorsPage {
    vendors {
      id
      name
      partnerType
      logo {
        uri
        x1Uri
        x2Uri
      }
      thirdPartyProducts {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`;

const VENDORS_SEARCH_RESULTS_QUERY = gql`
  query Vendors(
    $count: Int
    $start: Int
    $order: VendorSortOrder
    $query: String
    $type: VendorType
    $categories: [ID]
    $products: [ID]
  ) {
    vendors(
      count: $count
      start: $start
      order: $order
      query: $query
      type: $type
      categories: $categories
      products: $products
    ) {
      total
      ...VendorsSearchResults
    }
  }
  ${VENDORS_FRAGMENT}
`;

export { VENDORS_SEARCH_RESULTS_QUERY };
