import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TimeAgo from 'react-time-ago/no-tooltip';
import NotificationIcon from './NotificationIcon';
import CollapseIcon from '../../CollapseIcon/CollapseIcon';
import DISPOSITIONS from './Notification.dispositions';

const noOpOnClick = () => {};

const Message = ({ notification, onClick = noOpOnClick }) => {
  const onClickWithEvent = event => onClick(notification, event);
  if (!notification.url) return <span onClick={onClickWithEvent}>{notification.message}</span>;
  return (
    <a href={notification.url} onClick={onClickWithEvent}>
      {notification.message}
    </a>
  );
};

const NotificationBanner = ({
  hasGroup,
  isOpen,
  className,
  showDisposition,
  notification,
  onClick,
  children,
  ...rest
}) => {
  const notificationClassName = classNames('notification', `notification--${notification.category}`, className, {
    'notification--read': notification.isRead,
    'notification--unread': !notification.isRead,
    'notification--has-group': hasGroup,
    [`notification--${(notification.disposition || '').toLowerCase()}`]: showDisposition
  });
  let collapseIconRef = React.createRef();

  return (
    <aside className={notificationClassName} style={rest.style}>
      <header className="notification__header">
        <span className="notification__header-source">{notification.source}</span>
        <TimeAgo className="notification__header-date" timeStyle="short">
          {notification.createdAt}
        </TimeAgo>
      </header>
      <section className="notification__body">
        <NotificationIcon
          className="notification__body-icon"
          source={showDisposition ? notification.disposition : 'default'}
        />
        <p
          className="notification__body-message"
          onClick={() => {
            if (hasGroup) collapseIconRef.current.toggle();
          }}
          title={notification.message}
        >
          {hasGroup && (
            <CollapseIcon className="notification__body-message-group-icon" isOpen={isOpen} ref={collapseIconRef} />
          )}
          <Message notification={notification} onClick={onClick} />
        </p>
      </section>
      {children}
    </aside>
  );
};

NotificationBanner.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    disposition: PropTypes.oneOf(DISPOSITIONS).isRequired,
    message: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date),
    isSeen: PropTypes.bool.isRequired,
    isRead: PropTypes.bool.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  hasGroup: PropTypes.bool,
  isOpen: PropTypes.bool,
  showDisposition: PropTypes.bool
};

export default NotificationBanner;
export { DISPOSITIONS };
