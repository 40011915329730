import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FeButton } from 'fe-fabric-react';
import deepEqual from 'deep-equal';
import classNames from 'classnames';
import Loading from '../Loading/Loading';
import LoadingIcon from '../Loading/Icon';
import Tile from './Tile/Tile';
import './TileList.css';

class TileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiles: [],
      loading: false,
      hasMore: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (nextProps.loading !== prevState.loading) nextState.loading = nextProps.loading;
    if (nextProps.hasMore !== prevState.hasMore) nextState.hasMore = nextProps.hasMore;
    if (!deepEqual(nextProps.tiles, prevState.tiles)) nextState.tiles = nextProps.tiles;

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  delay(index, batchSize) {
    const divisor = batchSize || 1;
    return (index % divisor) * 0.025;
  }

  render() {
    const { tiles, loading, hasMore } = this.state;
    const { children, showFourTilesPerRow, onLoadMore, className, noWrap } = this.props;
    const hasTiles = tiles.length > 0;
    const loadMoreText = `Load${loading ? 'ing' : ' More'}`;

    return (
      <div className={classNames('tile-list', className)}>
        {children && <div className="tile-list__title">{children}</div>}
        {loading && !hasTiles && <Loading />}
        <Row className={classNames('tile-list__tiles flex-row', { 'no-wrap': noWrap })}>
          {tiles.map((tile, index) => (
            <Col
              key={`${tile.id}-${index}`}
              className="tile-list__container"
              sm={12}
              md={4}
              lg={showFourTilesPerRow ? 3 : 4}
              xl={showFourTilesPerRow ? 3 : 4}
            >
              <Tile data={tile} />
            </Col>
          ))}
        </Row>
        {hasMore && hasTiles && (
          <FeButton
            className="tile-list__load-more"
            onClick={onLoadMore}
            feStyle="primary"
            size="lg"
            disabled={loading}
          >
            {loadMoreText} <LoadingIcon loading={loading} size="sm" />
          </FeButton>
        )}
      </div>
    );
  }
}

TileList.propTypes = {
  showFourTilesPerRow: PropTypes.bool,
  tiles: PropTypes.array.isRequired,
  batchSize: PropTypes.number,
  children: PropTypes.node,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  className: PropTypes.string,
  /* Ignores regular flex-row rules and will only show 3 tiles in md resolution */
  noWrap: PropTypes.bool
};

TileList.defaultProps = {
  hasMore: false,
  batchSize: 1
};

export default TileList;
