import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { ifUnauthenticated } from '../../notificationsIdentity';

const NOTIFICATION_COUNTS_QUERY = gql`
  query NotificationStateCounts {
    unseenCount
    unreadCount
  }
`;

const getCountProps = ({ data: { loading, error, unseenCount, unreadCount } }) => {
  const unseen = loading || error ? 0 : unseenCount;
  const unread = loading || error ? 0 : unreadCount;
  return { unseen, unread };
};

const getCountOptions = ({ client }) => ({ client });

const withNotificationStateCounts = graphql(NOTIFICATION_COUNTS_QUERY, {
  skip: ifUnauthenticated,
  props: getCountProps,
  options: getCountOptions
});

export default withNotificationStateCounts;
export { NOTIFICATION_COUNTS_QUERY, getCountProps, getCountOptions };
