/* Format: DISPOSITON: [FontAwesomeIconArray] */
const NOTIFICATION_DISPOSITIONS = {
  SUCCESS: ['far', 'check-circle'],
  INFO: ['far', 'info-circle'],
  WARNING: ['far', 'info-circle'],
  ERROR: ['far', 'exclamation-triangle']
};

const DISPOSITIONS = Object.keys(NOTIFICATION_DISPOSITIONS);

export { NOTIFICATION_DISPOSITIONS };
export default DISPOSITIONS;
