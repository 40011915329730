import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import './SubscriptionToggle.css';

const SubscriptionToggle = ({ id, label, isSubscribed, disabled, onChange }) => (
  <div className="subscription-toggle">
    <label htmlFor={id}>
      <span className="subscription-toggle__label">{label}</span>
      <Switch
        id={id}
        className="subscription-toggle__switch"
        checked={isSubscribed}
        disabled={disabled}
        onChange={onChange}
        checkedIcon={<span className="subscription-toggle__icon subscription-toggle__icon--checked">Yes</span>}
        uncheckedIcon={<span className="subscription-toggle__icon subscription-toggle__icon--unchecked">No</span>}
        onColor="#00729F"
        offColor="#868686"
        height={24}
        width={58}
      />
    </label>
  </div>
);

SubscriptionToggle.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default SubscriptionToggle;
