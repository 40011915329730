import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeCollapse from '../FeCollapse/FeCollapse';
import classnames from 'classnames';
import CollapseIcon from '../CollapseIcon/CollapseIcon';

class Panel extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isCollapsed: !!props.isCollapsed,
      isCollapseDisabled: props.isCollapseDisabled
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.isCollapseDisabled !== state.isCollapseDisabled) newState.isCollapseDisabled = props.isCollapseDisabled;
    if (Object.keys(newState).length > 0) return newState;
    return null;
  }

  toggle() {
    if (!this.props.isCollapsible) return;
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    const { isCollapsed, isCollapseDisabled } = this.state;
    const { label, children, isCollapsible, className = 'fePanel', iconProps } = this.props;
    const isOpen = !isCollapsed;

    const sectionClassName = classnames(className, {
      [`${className}--collapsible`]: isCollapsible,
      open: isOpen
    });

    return (
      <section className={sectionClassName}>
        <header className={`${className}__header`} onClick={this.toggle}>
          {isCollapsible ? <CollapseIcon isOpen={isOpen} disabled={isCollapseDisabled} {...iconProps} /> : null}
          {label}
        </header>
        <FeCollapse tag="main" className={`${className}__body`} isOpen={isOpen}>
          {children}
        </FeCollapse>
      </section>
    );
  }
}

Panel.propTypes = {
  isCollapsible: PropTypes.bool,
  isCollapseDisabled: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  label: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  iconProps: PropTypes.object
};

export default Panel;
