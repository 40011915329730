import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

export const APPS_FOR_NOTIFICATION_SETTINGS_QUERY = loader('./ListAppsForNotificationSettings.graphql');

export const updateQuery = (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev;
  const nextResult = { ...prev };
  nextResult.paginatedApps.apps = [...prev.paginatedApps.apps, ...fetchMoreResult.paginatedApps.apps];
  return nextResult;
};

export const options = { variables: { start: 0 }, fetchPolicy: 'cache-first' };

export const props = ({
  data: { loading, error, fetchMore, paginatedApps: { apps, total } = { apps: [], total: 0 } }
}) => {
  const appsLength = apps.length;
  const hasMore = total > 0 && appsLength < total;
  if (hasMore && !loading && !error) {
    const variables = { start: appsLength };
    fetchMore({ variables, updateQuery });
  }
  const nextProps = { loading: loading || hasMore, error, apps };
  return nextProps;
};

const listAppsForNotificationSettings = graphql(APPS_FOR_NOTIFICATION_SETTINGS_QUERY, {
  alias: 'withAppsForNotifications',
  options,
  props
});

export default listAppsForNotificationSettings;
