import Analytics from './Analytics';

function recordAnalytics(notification) {
  const { id, source, category, disposition } = notification;
  Analytics.record('NotificationView', { id, source, category, disposition });
}

function goToNotification(notification, location, history) {
  if (!notification) return;
  recordAnalytics(notification);

  if (!notification.url) return;

  const url = new URL(notification.url);
  const sameProtocol = url.protocol === location.protocol;
  const sameHost = url.host === location.host;

  if (sameProtocol && sameHost) {
    const notificationPathAndSearch = url.pathname + url.search;
    const locationPathAndSearch = location.pathname + location.search;
    const differentPathAndSearch = notificationPathAndSearch !== locationPathAndSearch;
    if (differentPathAndSearch) history.push(notificationPathAndSearch);
  } else {
    location.assign(notification.url);
  }
}

export default goToNotification;
