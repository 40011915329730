import Stream from './Stream';
import { compose } from 'react-apollo';
import { getIdentity } from '../../../notificationsIdentity';
import withNotificationsClient from '../../../withNotificationsClient';
import withNotificationStateCounts from '../withNotificationStateCounts';
import listNotifications from '../listNotifications';
import markAllAsRead from '../markAllAsRead';
import markAsRead from '../markAsRead';
import { withState, withHandlers, mapProps, lifecycle } from 'recompose';
import takeWhile from 'lodash.takewhile';

const ensureNotificationsHaveDates = list =>
  list.map(n => Object.assign({}, n, { createdAt: n.createdAt ? new Date(n.createdAt) : new Date() }));

const split = (list = [], startId) => {
  let notifications = list;
  let incomingNotifications = [];
  if (startId) {
    const startIndex = list.findIndex(n => n.id === startId);
    notifications = list.slice(startIndex);
    incomingNotifications = takeWhile(list, n => n.id !== startId);
  }
  return {
    notifications: ensureNotificationsHaveDates(notifications),
    incomingNotifications: ensureNotificationsHaveDates(incomingNotifications)
  };
};

const prepareNotifications = mapProps(props => {
  const { notifications = [], startId } = props;
  const splitNotifications = split(notifications, startId);
  return Object.assign({}, props, splitNotifications);
});

const enqueueNewNotificationsAfter = compose(
  withState('startId', 'setStartId', null),
  withHandlers({
    loadNewNotifications: ({ setStartId, notifications }) => () =>
      setStartId(notifications.length > 0 ? notifications[0].id : null)
  }),
  lifecycle({
    componentDidUpdate() {
      const { loading, startId, setStartId, notifications } = this.props;
      if (!loading && !startId && notifications.length > 0) setStartId(notifications[0].id);
    }
  }),
  prepareNotifications
);

const composedComponent = compose(
  getIdentity,
  withNotificationsClient,
  withNotificationStateCounts,
  listNotifications,
  markAllAsRead,
  markAsRead,
  enqueueNewNotificationsAfter
)(Stream);

export default composedComponent;
export { split };
