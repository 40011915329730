import gql from 'graphql-tag';
import { limit, NOTIFICATIONS_QUERY } from './listNotifications';
import { NOTIFICATION_COUNTS_QUERY } from './withNotificationStateCounts';
import { Hub } from '@aws-amplify/core';

// With AppSync, the PUBLISHER controls the set of fields exposed to subscribers
// In other words, the selection set doesn't matter here
const SUBSCRIBE_TO_NOTIFICATIONS = gql`
  subscription onQueuedNotificationPublished($recipient: ID!) {
    publishedNotification(recipient: $recipient) {
      id
    }
  }
`;

const onSubscriptionData = ({
  client: cache,
  subscriptionData: {
    data: { publishedNotification }
  }
}) => {
  delete publishedNotification.recipient;

  const variables = { limit };
  const cachedNotifications = cache.readQuery({ query: NOTIFICATIONS_QUERY, variables }).notifications;
  const notifications = [publishedNotification, ...cachedNotifications];
  cache.writeQuery({ query: NOTIFICATIONS_QUERY, variables, data: { notifications } });

  const counts = cache.readQuery({ query: NOTIFICATION_COUNTS_QUERY });
  if (!publishedNotification.isSeen) counts.unseenCount++;
  if (!publishedNotification.isRead) counts.unreadCount++;
  cache.writeQuery({ query: NOTIFICATION_COUNTS_QUERY, data: counts });
  Hub.dispatch('notifications', {
    event: publishedNotification.category,
    data: publishedNotification,
    message: publishedNotification.message
  });
};

export { SUBSCRIBE_TO_NOTIFICATIONS, onSubscriptionData };
