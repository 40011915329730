import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const LIST_BROADCAST_OPT_OUTS = gql`
  query BroadcastPreferences {
    optOuts: preferences {
      source
      category
    }
  }
`;

export const options = ({ client }) => ({ client });

export const props = ({ data: { loading, error, optOuts = [] } }) => ({ loading, error, optOuts });

export const listBroadcastOptOuts = graphql(LIST_BROADCAST_OPT_OUTS, { options, props });
