import React from 'react';
import PropTypes from 'prop-types';
import TileList from '../../TileList/TileList';

const SimilarApps = ({ tag: Tag, apps, ...rest }) => (
  <TileList tiles={apps} {...rest} className="similar-apps">
    <Tag>Similar Apps</Tag>
  </TileList>
);

SimilarApps.displayName = 'SimilarApps';
SimilarApps.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  apps: PropTypes.arrayOf(PropTypes.object).isRequired
};
SimilarApps.defaultProps = {
  tag: 'h2',
  apps: []
};

export default SimilarApps;
