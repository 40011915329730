import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';
import deepEqual from 'deep-equal';
import { Query } from 'react-apollo';
import Error from '../Error/Error';
import SearchResults from '../SearchResults/SearchResults';
import { VENDORS_SEARCH_RESULTS_QUERY } from './VendorSearchResults.query';
import Analytics from '../../Analytics';
import '../AppSearchResults/AppSearchResults.css';

const sortOptions = [{ label: 'Relevance', id: null }, { label: 'A-Z', id: 'A_TO_Z' }, { label: 'Z-A', id: 'Z_TO_A' }];

class VendorSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query,
      order: props.order,
      filters: props.filters,
      wait: true
    };
    this.recordAnalytics = this.recordAnalytics.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (nextProps.wait !== prevState.wait) nextState.wait = nextProps.wait;
    if (nextProps.query !== prevState.query) nextState.query = nextProps.query;
    if (nextProps.order !== prevState.order) nextState.order = nextProps.order;
    // remove GraphQL props that get in the way of an actual comparison and cause extra state updates
    const compareFilters = Object.assign({}, nextProps.filters);
    delete compareFilters['__typename'];
    delete compareFilters[Symbol('id')];
    if (!deepEqual(compareFilters, prevState.filters)) nextState.filters = nextProps.filters;

    if (Object.keys(nextState).length > 0) {
      nextState.recordedAnalytics = false;
      return nextState;
    }
    return null;
  }

  computeQueryVariables(start = 0, count) {
    const { query, order, filters } = this.state;
    const variables = { query, order, start, count };
    for (var key in filters) {
      var collection = filters[key];
      if (Array.isArray(collection) && collection.length > 0) {
        var values = collection.map(f => f.id);
        if (key.endsWith('s')) variables[key] = values;
        else variables[key] = values[0].toUpperCase();
      }
    }
    return variables;
  }

  updateQuery(prev, { fetchMoreResult }) {
    if (!fetchMoreResult) return prev;
    if (fetchMoreResult.vendors.vendors.length === prev.vendors.total) return prev;

    const nextResult = {
      vendors: Object.assign({}, prev.vendors, {
        vendors: [...prev.vendors.vendors, ...fetchMoreResult.vendors.vendors]
      })
    };
    return nextResult;
  }

  serializeFilterSetForAnalytics(collection) {
    return (collection || []).map(f => f.name).join(';');
  }

  recordAnalytics({ vendors: { total, vendors } }) {
    const { batchSize } = this.props;
    const allVendorsInFirstBatch = vendors.length === total && vendors.length <= batchSize;
    const isFirstBatchOfMany = vendors.length === batchSize;
    const recordEvent = allVendorsInFirstBatch || isFirstBatchOfMany;
    if (recordEvent) {
      const { query, order, filters } = this.state;
      const eventData = {
        query,
        order,
        typeFilter: this.serializeFilterSetForAnalytics(filters.type),
        productFilters: this.serializeFilterSetForAnalytics(filters.products),
        categoryFilters: this.serializeFilterSetForAnalytics(filters.categories),
        count: total
      };
      Analytics.record('Vendor Search', eventData);
    }
  }

  render() {
    const { onSort, onRemoveFilter, batchSize } = this.props;
    const { wait, order, filters = {} } = this.state;

    if (wait) {
      return <SearchResults tiles={[]} order={null} onRemoveFilter={onRemoveFilter} batchSize={batchSize} loading />;
    }

    const variables = this.computeQueryVariables(0, batchSize);
    return (
      <Query query={VENDORS_SEARCH_RESULTS_QUERY} variables={variables} skip={wait} onCompleted={this.recordAnalytics}>
        {({ error, loading, fetchMore, data }) => {
          if (error) return <Error error={error} />;
          const { vendors: paginatedVendors = {} } = data;
          const { vendors = [], total: vendorsCount = 0 } = paginatedVendors;
          const hasMore = vendors.length < vendorsCount;
          const loadMore = () =>
            fetchMore({
              query: VENDORS_SEARCH_RESULTS_QUERY,
              variables: Object.assign({}, variables, { start: vendors.length }),
              updateQuery: this.updateQuery
            });
          return (
            <SearchResults
              items={vendors}
              total={vendorsCount}
              sortOptions={sortOptions}
              batchSize={batchSize}
              loading={loading}
              hasMore={hasMore}
              loadMore={loadMore}
              order={order}
              onSort={onSort}
              filters={filters}
              onRemoveFilter={onRemoveFilter}
            />
          );
        }}
      </Query>
    );
  }
}

VendorSearchResults.propTypes = {
  query: PropTypes.string,
  order: PropTypes.string,
  filters: CustomPropTypes.filterSet,
  onSort: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  wait: PropTypes.bool,
  batchSize: PropTypes.number
};

VendorSearchResults.defaultProps = {
  query: '',
  order: null,
  filters: {
    products: [],
    thirdPartyProducts: [],
    categories: [],
    types: [],
    platforms: []
  },
  wait: false,
  batchSize: 24
};

export default VendorSearchResults;
