import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ExternalLink from '../ExternalLink/ExternalLink';
import DownloadIconWithText from '../DownloadIconWithText/DownloadIconWithText';

const className = 'fe-btn fe-btn--md fe-btn--primary';

const DownloadLink = ({ distribution, onDownload }) => {
  const distributionUrl = new URL(distribution.uri);
  if (distributionUrl.host === window.location.host) {
    return (
      <Link to={distributionUrl.pathname} className={className}>
        <DownloadIconWithText type={distribution.type} />
      </Link>
    );
  } else {
    return (
      <ExternalLink href={distribution.uri} excludeIcon className={className} onClick={onDownload}>
        <DownloadIconWithText type={distribution.type} />
      </ExternalLink>
    );
  }
};

DownloadLink.propTypes = {
  distribution: PropTypes.shape({
    type: PropTypes.string.isRequired,
    uri: PropTypes.string
  }).isRequired,
  onDownload: PropTypes.func
};

DownloadLink.defaultProps = {
  onDownload: () => {}
};

export default DownloadLink;
