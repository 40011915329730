import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loading from '../../Loading/Icon';
import { FeBadge } from 'fe-fabric-react';

const SectionHeading = ({ title, subhead, count, loading, disabled }) => {
  const className = classnames('title', { pointer: !disabled });
  return (
    <>
      <h2 className={className}>{title}</h2>
      {count !== undefined && <FeBadge label={String(count)} max={1000} className="counter" />}
      <Loading loading={loading} />
      {subhead && <p className="subhead">{subhead}</p>}
    </>
  );
};

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subhead: PropTypes.string,
  count: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SectionHeading;
