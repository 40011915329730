import React from 'react';
import PropTypes from 'prop-types';
import MarkAllRead from '../MarkAllRead/MarkAllRead';
import IncomingQueue from '../IncomingQueue/IncomingQueue';
import NotificationList from '../NotificationList';
import { FeButton } from 'fe-fabric-react';

class Stream extends React.Component {
  state = {
    notifications: [],
    incomingNotifications: [],
    loading: false,
    hasMore: false,
    loadingMore: false,
    unread: 0
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { notifications, incomingNotifications, loading, hasMore, loadingMore, unread } = nextProps;
    const nextState = {
      notifications,
      incomingNotifications,
      loading,
      hasMore,
      loadingMore,
      unread
    };

    return nextState;
  }

  viewNotification = notification => {
    if (!notification.isRead) this.props.markAsRead(notification);
    this.props.viewNotification(notification);
  };

  render() {
    const { notifications, incomingNotifications, loading, hasMore, loadingMore, unread } = this.state;
    const { loadMore, markAllAsRead, loadNewNotifications, titleClassName } = this.props;

    return (
      <>
        <div className={titleClassName}>
          <h2>Notifications</h2>
          {unread > 0 && <MarkAllRead onClick={markAllAsRead} />}
        </div>
        <IncomingQueue notifications={incomingNotifications} release={loadNewNotifications} />
        <NotificationList loading={loading} items={notifications} viewNotification={this.viewNotification} />
        {!loading && hasMore && (
          <FeButton
            className="view-all-notifications__load-more mx-auto"
            onClick={loadMore}
            feStyle="primary"
            disabled={loadingMore}
          >
            Load More
          </FeButton>
        )}
      </>
    );
  }
}

Stream.propTypes = {
  loading: PropTypes.bool,
  notifications: PropTypes.array.isRequired,
  incomingNotifications: PropTypes.array,
  viewNotification: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  markAllAsRead: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  unread: PropTypes.number,
  loadNewNotifications: PropTypes.func.isRequired,
  titleClassName: PropTypes.string
};

Stream.defaultProps = {
  loading: true,
  notifications: [],
  hasMore: false,
  loadMore: () => {},
  loadingMore: false,
  unread: 0
};

export default Stream;
