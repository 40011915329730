import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subscription } from 'react-apollo';
import { SUBSCRIBE_TO_NOTIFICATIONS, onSubscriptionData } from './subscribeToNotifications';

class NotificationsSubscription extends Component {
  state = {
    recipient: undefined
  };

  componentDidMount = async () => {
    const { auth } = this.props;
    const isAnonymous = await auth.isAnonymous();
    const user = await auth.getUser();
    if (isAnonymous) this.setState({ recipient: undefined });
    else this.setState({ recipient: user.sub });
  };

  render = () => {
    const { recipient } = this.state;
    const { client } = this.props;
    if (!recipient) return null;
    return (
      <Subscription
        client={client}
        subscription={SUBSCRIBE_TO_NOTIFICATIONS}
        variables={{ recipient }}
        onSubscriptionData={onSubscriptionData}
      />
    );
  };
}

NotificationsSubscription.propTypes = {
  auth: PropTypes.shape({
    isAnonymous: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired
  }).isRequired,
  client: PropTypes.object.isRequired
};

export default NotificationsSubscription;
