import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import apolloLogger from 'apollo-link-logger';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionHandshakeLink } from 'aws-appsync/lib/link/subscription-handshake-link';
import { NonTerminatingHttpLink } from 'aws-appsync/lib/link/non-terminating-http-link';
import { RetryLink } from 'apollo-link-retry';
import { createHttpLink } from 'apollo-link-http';
import { ConsoleLogger as Logger } from '@aws-amplify/core';

const createOnErrorLink = logger => {
  return onError(({ graphQLErrors, networkError }) => {
    logger.error({ graphQLErrors, networkError });
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (networkError) logger.error(`[Network error]: ${networkError}`);
  });
};

const createAuthLink = auth => {
  return setContext(async () => {
    const authorization = await auth.getAccessToken();
    return { headers: { authorization } };
  });
};

const createRequestLink = uri => {
  return ApolloLink.split(
    operation => {
      const { query } = operation;
      const { kind, operation: graphqlOperation } = getMainDefinition(query);
      const isSubscription = kind === 'OperationDefinition' && graphqlOperation === 'subscription';
      return isSubscription;
    },
    ApolloLink.from([new NonTerminatingHttpLink('subsInfo', { uri }, true), new SubscriptionHandshakeLink('subsInfo')]),
    createHttpLink({ uri })
  );
};

const createRetryingRequestLink = requestLink => {
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  });
  return ApolloLink.from([retryLink, requestLink]);
};

const createClient = auth => {
  const logger = new Logger('Notifications GraphQL');
  const uri = process.env.REACT_APP_NOTIFICATION_URL;
  const cache = new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'BroadcastPreference':
          return `${object.source}:${object.category}`;
        default:
          return object.id || object._id;
      }
    }
  });
  const requestLink = createRequestLink(uri);
  const links = [createOnErrorLink(logger), createAuthLink(auth), createRetryingRequestLink(requestLink)];

  if (process.env.REACT_APP_APOLLO_LOG !== undefined) {
    links.unshift(apolloLogger);
  }

  return new ApolloClient({
    link: ApolloLink.from(links),
    cache
  });
};

export default createClient;
export { createRetryingRequestLink, createRequestLink, createAuthLink, createOnErrorLink };
