import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';
import deepEqual from 'deep-equal';
import { Query } from 'react-apollo';
import Error from '../Error/Error';
import SearchResults from '../SearchResults/SearchResults';
import { APP_SEARCH_RESULTS_QUERY, JUST_THE_APPS_MAAM } from './AppSearchResults.query';
import Analytics from '../../Analytics';
import './AppSearchResults.css';

const sortOptions = [
  { label: 'Relevance', id: null },
  { label: 'A-Z', id: 'ALPHABETICAL' },
  { label: 'Newest to Oldest', id: 'NEWEST_PUBLISHED' }
];
class AppSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query,
      order: props.order,
      filters: props.filters,
      wait: true
    };
    this.recordAnalytics = this.recordAnalytics.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (nextProps.wait !== prevState.wait) nextState.wait = nextProps.wait;
    if (nextProps.query !== prevState.query) nextState.query = nextProps.query;
    if (nextProps.order !== prevState.order) nextState.order = nextProps.order;
    // remove GraphQL props that get in the way of an actual comparison and cause extra state updates
    const compareFilters = Object.assign({}, nextProps.filters);
    delete compareFilters['__typename'];
    delete compareFilters[Symbol('id')];
    if (!deepEqual(compareFilters, prevState.filters)) nextState.filters = nextProps.filters;

    if (Object.keys(nextState).length > 0) {
      nextState.recordedAnalytics = false;
      return nextState;
    }
    return null;
  }

  computeQueryVariables(start = 0, count) {
    const { query, order, filters } = this.state;
    const variables = { query, order, start, count };
    for (var key in filters) {
      var collection = filters[key];
      if (Array.isArray(collection)) variables[key] = collection.map(f => f.id);
    }
    return variables;
  }

  updateQuery(prev, { fetchMoreResult }) {
    if (!fetchMoreResult) return prev;
    if (fetchMoreResult.paginatedApps.apps.length === prev.paginatedApps.appsCount) return prev;

    const nextResult = {
      paginatedApps: Object.assign({}, prev.paginatedApps, {
        apps: [...prev.paginatedApps.apps, ...fetchMoreResult.paginatedApps.apps]
      })
    };
    return nextResult;
  }

  serializeFilterSetForAnalytics(collection) {
    return (collection || []).map(f => f.name).join(';');
  }

  recordAnalytics({ paginatedApps: { total, apps } }) {
    const { batchSize } = this.props;
    const allAppsInFirstBatch = apps.length === total && apps.length <= batchSize;
    const isFirstBatchOfMany = apps.length === batchSize;
    const recordEvent = allAppsInFirstBatch || isFirstBatchOfMany;
    if (recordEvent) {
      const { query, order, filters } = this.state;
      const eventData = {
        query,
        order,
        productFilters: this.serializeFilterSetForAnalytics(filters.products),
        thirdPartyProductsFilters: this.serializeFilterSetForAnalytics(filters.thirdPartyProducts),
        categoryFilters: this.serializeFilterSetForAnalytics(filters.categories),
        typeFilters: this.serializeFilterSetForAnalytics(filters.types),
        platformFilters: this.serializeFilterSetForAnalytics(filters.platforms),
        count: total
      };
      Analytics.record('Search', eventData);
    }
  }

  render() {
    const { onSort, onRemoveFilter, batchSize } = this.props;
    const { wait, order, filters = {} } = this.state;

    if (wait) {
      return <SearchResults apps={[]} order={null} batchSize={batchSize} onRemoveFilter={onRemoveFilter} loading />;
    }

    const variables = this.computeQueryVariables(0, batchSize);
    return (
      <Query query={APP_SEARCH_RESULTS_QUERY} variables={variables} skip={wait} onCompleted={this.recordAnalytics}>
        {({ error, loading, fetchMore, data }) => {
          if (error) return <Error error={error} />;
          const { paginatedApps = {} } = data;
          const { apps = [], total: appsCount = 0 } = paginatedApps;
          const hasMore = apps.length < appsCount;
          const loadMore = () =>
            fetchMore({
              query: JUST_THE_APPS_MAAM,
              variables: Object.assign({}, variables, { start: apps.length }),
              updateQuery: this.updateQuery
            });
          return (
            <SearchResults
              items={apps}
              total={appsCount}
              sortOptions={sortOptions}
              batchSize={batchSize}
              loading={loading}
              hasMore={hasMore}
              loadMore={loadMore}
              order={order}
              onSort={onSort}
              filters={filters}
              onRemoveFilter={onRemoveFilter}
            />
          );
        }}
      </Query>
    );
  }
}

AppSearchResults.propTypes = {
  query: PropTypes.string,
  order: PropTypes.string,
  filters: CustomPropTypes.filterSet,
  onSort: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  wait: PropTypes.bool,
  batchSize: PropTypes.number
};

AppSearchResults.defaultProps = {
  query: '',
  order: null,
  filters: {
    products: [],
    thirdPartyProducts: [],
    categories: [],
    types: [],
    platforms: []
  },
  wait: false,
  batchSize: 24
};

export default AppSearchResults;
