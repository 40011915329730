import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const families = {
  regular: 'far',
  solid: 'fas',
  light: 'fal',
  brand: 'fab'
};

const SVGIcon = ({ family, icon, className, ...iconProps }) => {
  let realIcon = icon;
  let realFamily = family;
  // Some of the Fabric components are suuuuuper janky and haven't been updated
  // to properly use the icon property. The next few lines exist solely to address that
  if (icon.includes(' ')) {
    [realFamily, realIcon] = icon.split(' ');
  }
  realIcon = realIcon.replace(/fa-/, '');
  return <FontAwesomeIcon icon={[families[realFamily], realIcon]} className={className} {...iconProps} />;
};

const SVGStack = ({ className, ...stackProps }) => {
  const classNames = classnames('fa-layers', 'fa-fw', className);
  return <span className={classNames} {...stackProps} />;
};

export { SVGIcon, SVGStack };
