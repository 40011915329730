import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, FeHero } from 'fe-fabric-react';
import Hero from '../../components/Hero/Hero';
import Search from '../../components/Search/Search';
import FeaturedApps from '../../components/FeaturedApps';
import NewAndNoteworthyApps from '../../components/NewAndNoteworthyApps';
import ListFilters from '../../components/ListFilters';
import ShowModalButton from '../../components/AppModal/ShowModalButton';
import * as ModalTypes from '../../components/AppModal/modalTypes';
import queryString from 'query-string';
import { removeEmpty } from '../../queryStringUtils';
import filterSets from '../../filtersSets';
import './Home.css';

function createOnApplyFilters(history) {
  return filters => {
    const qsFilters = {};
    filterSets.apps.forEach(filter => (qsFilters[filter.group] = (filters[filter.group] || []).map(i => i.id)));
    history.push({
      pathname: '/apps',
      search: `?${queryString.stringify(qsFilters)}`
    });
  };
}

function createOnSearch(history) {
  return query => {
    const qsVars = removeEmpty({
      query
    });
    history.push({
      pathname: '/apps',
      search: `?${queryString.stringify(qsVars)}`
    });
  };
}

const Home = ({ history }) => {
  const onApplyFilters = createOnApplyFilters(history);
  const onSearch = createOnSearch(history);

  return (
    <div className="app-content home">
      <Hero>
        <FeHero
          title="Welcome to the FireEye Market"
          descriptionContent={
            <>
              <p>Discover apps and vendors that integrate with and extend your FireEye experience.</p>
              <Link to="/apps" className="fe-btn fe-btn--secondary fe-btn--lg browse-button browse-button--apps">
                Browse Apps
              </Link>
              <Link to="/vendors" className="fe-btn fe-btn--secondary fe-btn--lg browse-button browse-button--vendors">
                Browse Vendors
              </Link>
            </>
          }
        />
      </Hero>
      <Container className="app-content__container" fluid>
        <Row className="no-gutters">
          <Col className="filters-col" lg={3}>
            <ListFilters filterSet={filterSets.apps} onApplyFilters={onApplyFilters} applyInitiallyDisabled={false} />
          </Col>
          <Col className="right-col" xs={12} lg={9}>
            <Container className="search-bar__container">
              <Search onSearch={onSearch} suppressClear />
            </Container>
            <Container className="apps__container">
              <FeaturedApps />
              <FeHero
                className="promo promo__get-involved"
                descriptionContent={
                  <>
                    <h2>Get Involved</h2>
                    <p className="promo__text">
                      Do you have an idea for the FireEye Market? Do you want to contribute an app?
                      <br /> Contact us to get started.
                    </p>
                    <Link to="/faq" className="fe-btn fe-btn--secondary fe-btn--md">
                      More Info
                    </Link>
                    <ShowModalButton
                      modal={ModalTypes.CONTACT_US}
                      button={{
                        feStyle: 'primary'
                      }}
                    >
                      Contact Us
                    </ShowModalButton>
                  </>
                }
              />
              <NewAndNoteworthyApps limit={3} />
              <FeHero
                className="promo promo__browse"
                descriptionContent={
                  <>
                    <h2 className="promo__title">
                      Interested in discovering everything that our Market catalog has to offer?
                    </h2>
                    <Link to="/browse" className="fe-btn fe-btn--secondary fe-btn--md">
                      Browse
                    </Link>
                  </>
                }
              />
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Home;
export { createOnApplyFilters, createOnSearch };
