import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const AUTHENTICATION = loader('./Authentication.graphql');

const props = ({ data: { authentication } }) => {
  const isAuthenticated = !!(authentication && authentication.isAuthenticated);
  return { isAuthenticated };
};

const withAuth = graphql(AUTHENTICATION, {
  alias: 'withAuth',
  props
});

export default withAuth;
export { AUTHENTICATION, props };
