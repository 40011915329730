import Amplify from '@aws-amplify/core';
import '@aws-amplify/cache';
import AmplifyAuth from '@aws-amplify/auth';
import Auth from './Auth';
import { listenToAuthEventsForAnalytics, listenToAuthEventsForGraphQL } from './listeners';

const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ['openid', 'profile', 'phone', 'email', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin + '/implicit/callback',
      redirectSignOut: window.location.origin,
      responseType: 'code',
      identityProvider: process.env.REACT_APP_IDP,
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  }
};

Amplify.configure(config);

const createAuth = (history, cache) => {
  const auth = new Auth(AmplifyAuth, history);
  listenToAuthEventsForGraphQL(cache);
  listenToAuthEventsForAnalytics();
  return auth;
};

export default createAuth;
