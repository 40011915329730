import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';
import LinkListRow from './LinkListRow';

const IntegrationDetails = ({
  solutionBriefs,
  caseStudies,
  pressReleases,
  blogPosts,
  dataSheets,
  guides,
  marketplaces,
  videos,
}) => {
  const hasSolutionBriefs = solutionBriefs.length > 0;
  const hasCaseStudies = caseStudies.length > 0;
  const hasPressReleases = pressReleases.length > 0;
  const hasBlogPosts = blogPosts.length > 0;
  const hasDataSheets = dataSheets.length > 0;
  const hasGuides = guides.length > 0;
  const hasMarketplaces = marketplaces.length > 0;
  const hasVideos = videos.length > 0;
  const shouldRenderTable = [
    hasSolutionBriefs,
    hasCaseStudies,
    hasPressReleases,
    hasBlogPosts,
    hasDataSheets,
    hasGuides,
    hasMarketplaces,
    hasVideos
  ].some(has => has);

  if (!shouldRenderTable) return null;

  return (
    <table>
      <tbody>
        {hasBlogPosts && <LinkListRow title="Blog Posts" list={blogPosts} />}
        {hasCaseStudies && <LinkListRow title="Case Studies" list={caseStudies} />}
        {hasDataSheets && <LinkListRow title="Data Sheets" list={dataSheets} />}
        {hasGuides && <LinkListRow title="Guides" list={guides} />}
        {hasMarketplaces && <LinkListRow title="Marketplaces" list={marketplaces} />}
        {hasPressReleases && <LinkListRow title="Press Releases" list={pressReleases} />}
        {hasSolutionBriefs && <LinkListRow title="Solution Briefs" list={solutionBriefs} />}
        {hasVideos && <LinkListRow title="Videos" list={videos} />}
      </tbody>
    </table>
  );
};
IntegrationDetails.displayName = 'IntegrationDetails';
IntegrationDetails.propTypes = {
  solutionBriefs: PropTypes.arrayOf(CustomPropTypes.link).isRequired,
  caseStudies: PropTypes.arrayOf(CustomPropTypes.link).isRequired,
  pressReleases: PropTypes.arrayOf(CustomPropTypes.link).isRequired,
  blogPosts: PropTypes.arrayOf(CustomPropTypes.link).isRequired,
  dataSheets: PropTypes.arrayOf(CustomPropTypes.link).isRequired,
  guides: PropTypes.arrayOf(CustomPropTypes.link).isRequired,
  marketplaces: PropTypes.arrayOf(CustomPropTypes.link).isRequired,
  videos: PropTypes.arrayOf(CustomPropTypes.link).isRequired
};

export default IntegrationDetails;
