import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const VENDOR_SNAPSHOT = loader('./VendorSnapshot.graphql');

const options = ({
  match: {
    params: { id, version }
  }
}) => ({
  variables: { id, version }
});

const props = ({ data: { loading, vendorSnapshot: vendor, error } }) => ({
  loading,
  vendor,
  error
});

const withVendorSnapshot = graphql(VENDOR_SNAPSHOT, {
  alias: 'withVendorSnapshot',
  options,
  props
});

export default withVendorSnapshot;
export { options, props };
