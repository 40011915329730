import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import './FeCollapse.css';

class FeCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      height: props.isOpen ? this.props.openHeight : this.props.closedHeight
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.isOpen !== state.isOpen) {
      newState.isOpen = props.isOpen;
      newState.height = props.isOpen ? props.openHeight : props.closedHeight;
    }
    if (Object.keys(newState).length > 0) return newState;
    return null;
  }

  render() {
    const { children, className, wrapperClassName, delay, duration, easing, tag: Tag, ...otherProps } = this.props;
    delete otherProps.closedHeight;
    delete otherProps.isOpen;
    delete otherProps.openHeight;

    return (
      <Tag
        className={classNames(wrapperClassName, {
          [`${wrapperClassName}--open`]: this.state.isOpen,
          [`${wrapperClassName}--closed`]: !this.state.isOpen
        })}
      >
        <AnimateHeight
          className={className && `${className}__container`}
          contentClassName={className}
          delay={delay}
          duration={duration}
          easing={easing}
          height={this.state.height}
          {...otherProps}
        >
          {children}
        </AnimateHeight>
      </Tag>
    );
  }
}

FeCollapse.defaultProps = {
  wrapperClassName: 'fe-collapse',
  closedHeight: 0,
  delay: 0,
  duration: 500,
  easing: 'ease-out',
  isOpen: true,
  openHeight: 'auto',
  tag: 'div'
};

FeCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closedHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  delay: PropTypes.number,
  duration: PropTypes.number,
  easing: PropTypes.string,
  isOpen: PropTypes.bool,
  openHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tag: PropTypes.string,
  wrapperClassName: PropTypes.string
};

export default FeCollapse;
