import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';
import classNames from 'classnames';
import FeCollapse from '../FeCollapse/FeCollapse';
import { FeButton, FeBadge } from 'fe-fabric-react';
import Sticky from 'react-stickynode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListFilterGroup from '../ListFilterGroup/ListFilterGroup';
import LoadingIcon from '../Loading/Icon';
import filterSets from '../../filtersSets';
import './ListFilters.css';

class ListFilters extends Component {
  constructor(props) {
    super(props);
    const { activeFilters, filtersType, loading } = this.props;
    this.state = {
      isOpen: false,
      activeFilters,
      filters: {},
      filterSet: filterSets[filtersType],
      loading
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onFilterGroupItemClick = (group, selectionSet) => {
    const { onApplyFilters } = this.props;
    if (!onApplyFilters) return;
    const activeFilters = Object.assign({}, this.state.activeFilters);
    activeFilters[group] = selectionSet;
    this.setState({ activeFilters });
    onApplyFilters(activeFilters);
  };

  onClearClick = () => {
    const { onApplyFilters } = this.props;
    if (!onApplyFilters) return;
    const activeFilters = {};
    this.state.filterSet.forEach(fs => {
      activeFilters[fs.group] = [];
    });
    this.setState({ activeFilters });
    onApplyFilters(activeFilters);
  };

  static getDerivedStateFromProps(props, state) {
    const { activeFilters, availableFilters, filtersType, loading, error } = props;

    const stateDiff = {};
    if (activeFilters !== state.activeFilters) stateDiff.activeFilters = activeFilters;
    if (filtersType !== state.filtersType) stateDiff.filtersType = filtersType;
    if (availableFilters && filtersType) {
      const filters = availableFilters[filtersType];
      if (filters !== state.filters) stateDiff.filters = filters;
    }
    if (loading !== state.loading) stateDiff.loading = loading;
    if (error !== state.error) stateDiff.error = error;

    if (Object.keys(stateDiff).length === 0) return null;
    return stateDiff;
  }

  render() {
    const { activeFilters, filters, filterSet, isOpen, loading } = this.state;
    const filterCollections = Object.values(activeFilters).filter(Array.isArray);
    const filtersCount = filterCollections.reduce((accumulator, collection) => accumulator + collection.length, 0);
    const filtersCountDisplay = filtersCount > 0 ? <FeBadge label={String(filtersCount)} /> : null;
    const isClearDisabled = filterCollections.every(collection => collection.length === 0);
    return (
      <div
        className={classNames('filters', {
          'filters--open': isOpen,
          'filters--closed': !isOpen
        })}
      >
        <div className="filters__title-wrapper filters__title-wrapper--static">
          <div className="filters__title">
            <span className="filters__title-content">
              <FontAwesomeIcon className="filters__title-content--icon" icon={['far', 'filter']} fixedWidth /> Filters{' '}
              {filtersCountDisplay}
              <LoadingIcon loading={loading} />
            </span>
            <FeButton
              disabled={isClearDisabled}
              onClick={this.onClearClick}
              feStyle="link"
              className="filters__title-clear-button"
            >
              Clear All
            </FeButton>
          </div>
        </div>
        <div className="filters__title-wrapper filters__title-wrapper--collapsible">
          <Sticky innzerZ={101}>
            <div className="filters__title" onClick={this.toggle}>
              <span className="filters__title-content">
                <FontAwesomeIcon
                  className="filters__title-content--icon"
                  icon={['far', isOpen ? 'times-circle' : 'filter']}
                  fixedWidth
                />{' '}
                Filters {filtersCountDisplay}
                <LoadingIcon loading={loading} />
              </span>
              <FeButton
                disabled={isClearDisabled}
                onClick={this.onClearClick}
                feStyle="link"
                className="filters__title-clear-button"
              >
                Clear All
              </FeButton>
            </div>
          </Sticky>
        </div>
        <FeCollapse isOpen={isOpen} closedHeight="auto" animateOpacity wrapperClassName="filters__collapse">
          {filterSet.map(fs => (
            <ListFilterGroup
              name={fs.label}
              key={fs.group}
              group={fs.group}
              list={filters[fs.group]}
              selected={activeFilters[fs.group]}
              onClick={this.onFilterGroupItemClick}
            />
          ))}
        </FeCollapse>
      </div>
    );
  }
}

ListFilters.propTypes = {
  activeFilters: CustomPropTypes.filterSet,
  availableFilters: PropTypes.shape({
    apps: CustomPropTypes.filterSet,
    vendors: CustomPropTypes.filterSet
  }).isRequired,
  error: PropTypes.object,
  filtersType: PropTypes.oneOf(['apps', 'vendors']).isRequired,
  onApplyFilters: PropTypes.func,
  loading: PropTypes.bool
};

ListFilters.defaultProps = {
  activeFilters: {},
  availableFilters: {},
  filtersType: 'apps'
};

ListFilters.displayName = 'ListFilters';
export default ListFilters;
