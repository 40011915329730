import { ConsoleLogger } from '@aws-amplify/core';
import { getAuthDataForGraphQL } from '../authUtils';

class AuthListenerForGraphQL {
  constructor({ cache, logger = new ConsoleLogger('AuthForGraphQL') }) {
    this.cache = cache;
    this.logger = logger;
    this.setAuthData = this.setAuthData.bind(this);
    this.setAnonAuthData = this.setAnonAuthData.bind(this);
    this.clearAuthData = this.clearAuthData.bind(this);
    this.onAuthEvent = this.onAuthEvent.bind(this);
    this.onAnonAuthEvent = this.onAnonAuthEvent.bind(this);
  }

  getCognitoUserSession(cognitoUser) {
    let session = cognitoUser.getSignInUserSession();
    if (!session) {
      cognitoUser.getSession(() => {});
      session = cognitoUser.getSignInUserSession();
    }
    return session;
  }

  setAuthData(cognitoUser) {
    const session = this.getCognitoUserSession(cognitoUser);
    const userInfo = session.getIdToken().payload;
    const data = getAuthDataForGraphQL(userInfo);
    this.logger.debug('setAuthData', { data });
    this.cache.writeData({ data });
  }

  setAnonAuthData(cognitoUser) {
    const session = this.getCognitoUserSession(cognitoUser);
    const payload = session.getIdToken().payload;
    const name = cognitoUser.getUsername();
    const userInfo = Object.assign({ name, preferred_username: name }, payload);
    const data = getAuthDataForGraphQL(userInfo);
    data.isAuthenticated = false;
    this.logger.debug('setAnonAuthData', { data });
    this.cache.writeData({ data });
  }

  clearAuthData() {
    const data = getAuthDataForGraphQL();
    this.logger.debug('clearAuthData', { data });
    this.cache.writeData({ data });
  }

  onAuthEvent({ payload: { event, data } }) {
    switch (event) {
      case 'signIn':
        this.setAuthData(data);
        break;

      case 'signOut':
        this.clearAuthData();
        break;

      default:
        break;
    }
  }

  onAnonAuthEvent({ payload: { event, data } }) {
    if (event === 'signIn') {
      this.setAnonAuthData(data);
    }
  }
}

export default AuthListenerForGraphQL;
