import React from 'react';
import PropTypes from 'prop-types';
import Error from '../Error/Error';
import TileList from '../TileList/TileList';

const FeaturedApps = props => {
  const { error, ...rest } = props;
  rest.tiles = rest.apps;
  if (error) return <Error error={error} />;

  return (
    <TileList {...rest} className="featured">
      <h1>Featured Apps</h1>
    </TileList>
  );
};

FeaturedApps.displayName = 'FeaturedApps';
FeaturedApps.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  tiles: PropTypes.arrayOf(PropTypes.object)
};

export default FeaturedApps;
