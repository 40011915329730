import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const VENDOR_DETAILS = loader('./VendorDetailsPreview.graphql');

const options = ({
  match: {
    params: { id }
  }
}) => ({
  variables: { id }
});

const props = ({ data: { loading, vendor, error } }) => ({
  loading,
  vendor,
  error
});

const withVendorPreview = graphql(VENDOR_DETAILS, {
  alias: 'withVendorPreview',
  options,
  props
});

export default withVendorPreview;
export { options, props };
