import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './TagList.css';

const TagList = ({ tagSets, className, children }) => {
  let items = [];
  for (const [group, tags] of Object.entries(tagSets)) {
    items = items.concat(tags.map(tag => ({ group, tag })));
  }
  if (items.length === 0) return null;
  return (
    <ul className={classnames('tag-list', className)}>
      {items
        .sort((a, b) => a.tag.name.localeCompare(b.tag.name))
        .map(item => (
          <li key={`${item.group}:${item.tag.id}`} className="tag-list__item">
            {children(item)}
          </li>
        ))}
    </ul>
  );
};

TagList.displayName = 'TagList';
TagList.propTypes = {
  children: PropTypes.func.isRequired,
  tagSets: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  ).isRequired,
  className: PropTypes.string
};

export default TagList;
