const gql = require('graphql-tag');

const AUTHENTICATED_DOWNLOAD_MUTATION = gql`
  mutation RequestAuthenticatedDownload($downloadRequest: RequestDownloadInput!) {
    requestDownload(downloadRequest: $downloadRequest) {
      download {
        url
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default AUTHENTICATED_DOWNLOAD_MUTATION;
