import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'fe-fabric-react';
import Hero from '../../components/Hero/Hero';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import NotFound from '../../containers/NotFound/NotFound';
import App from '../../components/AppDetails';
import SimilarApps from '../../components/AppDetails/SimilarApps';
import BackLink from '../../components/BackLink';
import DownloadApp from '../../components/AppModal/DownloadApp';
import Analytics from '../../Analytics';
import './AppDetails.css';

const AppDetails = ({ url, error, loading, id, app, isAuthenticated, assentToTerms, closeModal, isModalOpen }) => {
  if (error) {
    if (NotFound.showNotFoundFromError(error)) {
      return <NotFound />;
    }
    return <Error error={error} />;
  }

  if (!loading) Analytics.record('AppView', { appId: app.id, appName: app.name });

  return (
    <>
      <div className="app-content app-details">
        {!loading && (
          <Helmet>
            <title>{app.name}</title>
            <meta name="description" content={app.summary} />
            <meta property="og:title" content={app.name + ' | FireEye Market'} />
            <meta property="og:description" content={app.summary} />
            <meta property="og:url" content={window.location.origin + url} />
            <meta property="og:image" content={app.overview.uri} />
          </Helmet>
        )}
        <Hero>
          <BackLink fallback={{ location: '/browse', title: 'Search' }} />
        </Hero>
        <Container className="app-details__container">
          {loading && !app && <Loading />}
          {app && <App app={app} isAuthenticated={isAuthenticated} assentedToTerms={assentToTerms} />}
          <Row>
            <Col xs={12} lg={{ size: 10, offset: 1 }}>
              <SimilarApps tag="h2" id={id} limit={4} showFourTilesPerRow noWrap />
            </Col>
          </Row>
        </Container>
      </div>
      {!loading && app && (
        <DownloadApp app={app} isOpen={isModalOpen} closeModal={closeModal} assentToTerms={assentToTerms} />
      )}
    </>
  );
};

AppDetails.propTypes = {
  url: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.object,
  id: PropTypes.string.isRequired,
  app: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  assentToTerms: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool
};

export default AppDetails;
