import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';
import { titleize } from 'inflected';
import Error from '../Error/Error';
import { Container, Row, Col } from 'fe-fabric-react';
import ListFilters from '../ListFilters';
import Search from '../Search/Search';
import AppSearchResults from '../AppSearchResults/AppSearchResults';
import VendorSearchResults from '../VendorSearchResults/VendorSearchResults';

const SearchFilterAndSort = ({
  activeFilters,
  error,
  loading,
  onApplyFilters,
  onRemoveFilter,
  onSearch,
  onSort,
  order,
  query,
  pageType
}) => {
  if (error) return <Error error={error} />;
  const ResultsContainer = pageType === 'apps' ? AppSearchResults : VendorSearchResults;
  const placeholderText = 'Search ' + titleize(pageType);
  return (
    <Row className="app-content__container--row no-gutters">
      <Col className="filters-col" lg={3}>
        <ListFilters filtersType={pageType} activeFilters={activeFilters} onApplyFilters={onApplyFilters} />
      </Col>
      <Col className="right-col" xs={12} lg={9}>
        <Container className="search-bar__container">
          <Row className="no-gutters">
            <Col>
              <Search query={query} onSearch={onSearch} placeholderText={placeholderText} />
            </Col>
          </Row>
        </Container>
        <Container className="results__container">
          <ResultsContainer
            query={query}
            order={order}
            filters={activeFilters}
            onSort={onSort}
            onRemoveFilter={onRemoveFilter}
            wait={loading}
          />
        </Container>
      </Col>
    </Row>
  );
};

SearchFilterAndSort.propTypes = {
  pageType: PropTypes.oneOf(['apps', 'vendors']).isRequired,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  loading: PropTypes.bool,
  query: PropTypes.string,
  order: PropTypes.string,
  activeFilters: CustomPropTypes.filterSet,
  error: PropTypes.object
};

SearchFilterAndSort.defaultProps = {
  loading: false,
  type: 'apps'
};

export default SearchFilterAndSort;
