import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const IDENTITY_QUERY = gql`
  query IdentityForNotifications {
    authentication @client {
      isAuthenticated
      identity {
        sub
      }
    }
  }
`;

const getIdentity = graphql(IDENTITY_QUERY, {
  props: props => {
    const {
      data: { loading, authentication }
    } = props;
    if (loading) return { isAuthenticated: false, recipient: undefined };
    const { isAuthenticated, identity } = authentication;
    const recipient = identity ? identity.sub : undefined;
    return { isAuthenticated, recipient };
  }
});

const ifAuthenticated = ({ isAuthenticated, recipient }) => isAuthenticated && recipient;
const ifUnauthenticated = ({ isAuthenticated }) => !isAuthenticated;

export { IDENTITY_QUERY, getIdentity, ifAuthenticated, ifUnauthenticated };
