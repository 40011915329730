import React from 'react';
import { Container } from 'fe-fabric-react';
import Hero from '../Hero/Hero';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const wrap = component => (
  <div className="app-content">
    <Hero>
      <div className="go-back">
        <span className="link">&nbsp;</span>
      </div>
    </Hero>
    <Container className="app-content__container" fluid>
      {component}
    </Container>
  </div>
);

const ComponentLoading = ({ error, retry, pastDelay }) => {
  if (error) {
    return wrap(
      <div className="error">
        <FontAwesomeIcon className="error__icon" icon={['fal', 'exclamation-circle']} />
        <h1>Sorry, something went wrong.</h1>
        <p>
          Please <button onClick={retry}>Retry</button>, or reach out to us at{' '}
          <a href="mailto:market@fireeye.com">market@fireeye.com</a> and let us know what went wrong.
        </p>
      </div>
    );
  } else if (pastDelay) {
    return wrap(<Loading />);
  } else {
    return null;
  }
};

export default ComponentLoading;
