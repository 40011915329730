import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'fe-fabric-react';
import { NotificationsConsumer } from '../../NotificationsContext';
import NotificationsSubscription from '../../components/Notifications/NotificationsSubscription';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ComponentLoading from '../../components/ComponentLoading';
import AppModal from '../../components/AppModal/AppModal';
import Home from '../Home/Home';
import { Apps, Vendors } from '../Browse';
import AppDetails from '../AppDetails';
import VendorDetails from '../VendorDetails';
import FAQ from '../FAQ/FAQ';
import NotFound from '../NotFound/NotFound';
import NotificationCenter from '../NotificationCenter/NotificationCenter';
import TermsOfUse from '../TermsOfUse/TermsOfUse';

const Admin = lazy(() => import(/* webpackChunkName: "vendor-admin" */ '../Admin'));
const AdminApps = lazy(() => import(/* webpackChunkName: "vendor-admin" */ '../Admin/Apps'));
const Explorer = lazy(() => import(/* webpackChunkName: "graphiql" */ '../Explorer/Explorer'));
const SiteMap = lazy(() => import(/* webpackChunkName: "sitemap" */ '../SiteMap'));

class SecureContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSessionInitiated: false
    };
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  async componentDidMount() {
    const { auth } = this.props;
    this.session = auth.resumeOrStartSession().then(session => {
      if (session) this.setState({ isSessionInitiated: true });
      else auth.login();
      return session;
    });
  }

  render() {
    const { history, auth } = this.props;

    return (
      <>
        <Container className="app-container">
          <NotificationsConsumer>
            {value => <NotificationsSubscription auth={auth} client={value.client} />}
          </NotificationsConsumer>
          <Header history={history} auth={auth} showNav />
          <Suspense fallback={<ComponentLoading />}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path={['/browse', '/apps']} exact component={Apps} />
              <Route path="/vendors" exact component={Vendors} />
              <Route path="/apps/:appId" exact component={AppDetails} />
              <Route path="/vendors/:id/:version?" exact component={VendorDetails} />
              <Route path="/faq" exact component={FAQ} />
              <Route path="/graphiql" exact component={Explorer} />
              <Route path="/notifications/:option?" exact component={NotificationCenter} />
              <Route path="/admin/:tab?/edit/:appId?" component={AdminApps} />
              <Route path="/admin/:tab?/create" component={AdminApps} />
              <Route path="/admin/:tab?" component={Admin} />
              <Route path="/termsofuse" exact component={TermsOfUse} />
              <Route path="/sitemap" exact component={SiteMap} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
          <Footer />
        </Container>
        <AppModal />
      </>
    );
  }
}

export default SecureContent;
