import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackToHomeLink = () => (
  <div className="go-back">
    <Link to="/" className="link">
      <FontAwesomeIcon icon={['far', 'arrow-circle-left']} fixedWidth />
      <span>Back to Homepage</span>
    </Link>
  </div>
);

export default BackToHomeLink;
