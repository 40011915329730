import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FeCollapse from '../FeCollapse/FeCollapse';
import CollapseIcon from '../CollapseIcon/CollapseIcon';
import { FeSearchField } from 'fe-fabric-react';
import AwesomeCheckbox from '../AwesomeCheckbox/AwesomeCheckbox';
import './ListFilterGroup.css';

class ListFilterGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayList: props.list,
      isFilterGroupOpen: true,
      isListOpen: false,
      isOverHeight: false,
      searchValues: props.list.map(item => item.name),
      searchDirty: false,
      searchList: props.list,
      selected: props.selected
    };
    this.toggleSeeAll = this.toggleSeeAll.bind(this);
    this.toggleFilterGroup = this.toggleFilterGroup.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.onItemCheckedChanged = this.onItemCheckedChanged.bind(this);
    this.onFilterSearch = this.onFilterSearch.bind(this);
  }

  /* Filters */
  toggleSeeAll(event) {
    this.setState({
      isListOpen: event.target.checked
    });
  }

  toggleFilterGroup() {
    this.setState({
      isFilterGroupOpen: !this.state.isFilterGroupOpen
    });
  }

  isItemSelected(item) {
    return this.state.selected.map(v => v.id).includes(item.id);
  }

  onItemCheckedChanged(item, checked) {
    const { selected, searchList } = this.state;
    let selectionSet = selected.slice(0);
    let selectedItem = searchList.find(s => s.id === item.id);

    if (checked && !selectionSet.find(s => s.id === selectedItem.id)) {
      selectionSet.push(selectedItem);
    } else {
      selectionSet = selectionSet.filter(s => s.id !== selectedItem.id);
    }

    if (this.props.onClick) this.props.onClick(this.props.group, selectionSet);
  }

  /* Search */

  onFilterSearch(e) {
    let filteredFilters = this.props.list.filter(l => e.list.find(item => item === l.name));
    let displayList = [];
    let searchDirty = e.subStr.length > 0;

    displayList = !searchDirty
      ? filteredFilters
      : filteredFilters.map(filter =>
          Object.assign({}, filter, { name: filter.name.replace(new RegExp(e.subStr, 'gi'), s => `<em>${s}</em>`) })
        );

    this.setState({
      displayList: displayList,
      searchDirty,
      searchList: filteredFilters
    });
  }

  /* Lifecycle Methods */
  static getDerivedStateFromProps(props, state) {
    const { initiallyVisible, selected } = props;
    const { isListOpen, isOverHeight, searchList } = state;

    let newState = {
      isOverHeight: initiallyVisible > 0 && searchList.length > initiallyVisible,
      selected: selected
    };

    // If an item that should be hidden is selected, open the FilterGroup
    if (!isListOpen && isOverHeight) {
      newState.selected.forEach(sl => {
        newState.isListOpen = searchList.slice(initiallyVisible).some(s => sl.id === s.id);
      });
    }
    return newState;
  }

  componentDidUpdate(pProps) {
    // Run once, when props.list actually gets populated with data
    if (pProps.list.length < this.props.list.length) {
      this.setState({
        displayList: this.props.list,
        searchValues: this.props.list.map(item => item.name),
        searchList: this.props.list
      });
    }
  }

  render() {
    const { group, initiallyVisible, name, list } = this.props;
    const { displayList, isFilterGroupOpen, isOverHeight, searchValues, searchDirty } = this.state;
    const isOpen = isFilterGroupOpen && displayList && list.length > 0;

    return (
      <div className="filter-group">
        <div id={group} className="filter-group__title" onClick={this.toggleFilterGroup}>
          <div className="filter-group__title-text" role="button" tabIndex="0" title={name}>
            <CollapseIcon className="filter-group__title-text--icon" fixedWidth isOpen={isOpen} /> {name}
          </div>
        </div>
        <FeCollapse
          className={classNames('filter-group__collapse', {
            'over-height': isOverHeight
          })}
          isOpen={isOpen}
        >
          {(isOverHeight || searchDirty) && (
            <FeSearchField
              className="filter-group__collapse--search"
              list={searchValues}
              onChange={this.onFilterSearch}
            />
          )}
          <ul className="filter-group__collapse--list">
            {displayList.map((item, i) => {
              return (
                <li
                  key={item.id}
                  className={classNames({ 'pad-bottom': i > initiallyVisible - 1 && i === displayList.length - 1 })}
                >
                  <AwesomeCheckbox
                    onChange={checked => this.onItemCheckedChanged(item, checked)}
                    checked={this.isItemSelected(item)}
                    value={item.id}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.name }} />
                  </AwesomeCheckbox>
                </li>
              );
            })}
          </ul>
        </FeCollapse>
        {isOverHeight && <div className="filter-group__collapse--fade-out" />}
      </div>
    );
  }
}

ListFilterGroup.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  initiallyVisible: PropTypes.number,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired
};

ListFilterGroup.defaultProps = {
  name: '',
  initiallyVisible: 9,
  list: [],
  selected: []
};

ListFilterGroup.displayName = 'ListFilterGroup';

export default ListFilterGroup;
