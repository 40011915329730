import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container } from 'fe-fabric-react';
import BackToHomeLink from '../../components/BackToHomeLink';
import queryString from 'query-string';
import difference from 'lodash.difference';
import isEqual from 'lodash.isequal';
import { titleize } from 'inflected';
import { extractVariablesFromLocation, removeEmpty } from '../../queryStringUtils';
import Hero from '../../components/Hero/Hero';
import SearchFilterAndSort from '../../components/SearchFilterAndSort';
import filterSets from '../../filtersSets';
import './Browse.css';

function areQueryFiltersDifferentFromStateFilters(filterSet, qsFilters, stateFilters) {
  return filterSet
    .map(filter => difference(qsFilters[filter.group], (stateFilters[filter.group] || []).map(i => i.id)))
    .some(a => a.length !== 0);
}

function activeFiltersFromExtracted(filterSet, filters, extracted) {
  const activeFilters = {};
  filterSet.forEach(fs => {
    const allFiltersForGroup = filters[fs.group] || [];
    activeFilters[fs.group] = allFiltersForGroup.filter(x => extracted[fs.group].includes(x.id));
  });
  return activeFilters;
}

function calculateNextState(activeFilters, filterSet, filters, location, query, order) {
  const extracted = extractVariablesFromLocation(location);
  const queryDoesNotMatch = query !== extracted.query;
  const orderDoesNotMatch = order !== extracted.order;
  const filtersDoNotMatch = areQueryFiltersDifferentFromStateFilters(filterSet, extracted, activeFilters);
  const variables = { query, order };
  if (queryDoesNotMatch) variables.query = extracted.query;
  if (orderDoesNotMatch) variables.order = extracted.order;
  if (filtersDoNotMatch) variables.activeFilters = activeFiltersFromExtracted(filterSet, filters, extracted);
  return { ...variables };
}

class Browse extends Component {
  constructor(props) {
    super(props);
    const { availableFilters, error, loading, location, pageType } = props;
    const { query, order, ...extracted } = extractVariablesFromLocation(location);

    this.state = {
      activeFilters: activeFiltersFromExtracted(filterSets[pageType], availableFilters[pageType], extracted),
      filters: availableFilters[pageType],
      filterSet: filterSets[pageType],
      error,
      loading,
      location,
      order,
      pageType,
      query
    };

    this.onSearch = this.onSearch.bind(this);
    this.onSort = this.onSort.bind(this);
    this.syncStateWithLocation = this.syncStateWithLocation.bind(this);
    this.handlePopState = this.handlePopState.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.onRemoveFilter = this.onRemoveFilter.bind(this);
    this.onApplyFilters = this.onApplyFilters.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { availableFilters, location } = this.props;
    const { activeFilters, filterSet, loading, order, query, pageType } = this.state;
    if (prevProps.loading !== loading && !isEqual(prevProps.availableFilters, availableFilters)) {
      const caluclatedState = calculateNextState(
        activeFilters,
        filterSet,
        availableFilters[pageType],
        location,
        order,
        query
      );
      this.setState(caluclatedState);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const stateDiff = {};
    if (props.location !== state.location) stateDiff.location = props.location;
    if (props.loading !== state.loading) stateDiff.loading = props.loading;
    if (props.error !== state.error) stateDiff.error = props.error;
    if (props.pageType !== state.pageType) stateDiff.pageType = props.pageType;
    if (props.availableFilters && props.pageType) {
      const filters = props.availableFilters[props.pageType];
      const calculatedState = calculateNextState(
        state.activeFilters,
        state.filterSet,
        filters,
        props.location,
        state.order,
        state.query
      );
      const { query, order, activeFilters } = calculatedState;
      if (query !== state.query) stateDiff.query = query;
      if (order !== state.order) stateDiff.order = order;
      if (activeFilters && activeFilters !== state.activeFilters) stateDiff.activeFilters = activeFilters;
    }
    if (Object.keys(stateDiff).length === 0) return null;
    return stateDiff;
  }

  onSearch(query) {
    this.updateLocation({ query });
    this.setState({ query });
  }

  onSort(option) {
    const order = option.id;
    this.updateLocation({ order });
    this.setState({ order });
  }

  handlePopState(event) {
    event.preventDefault();
    this.syncStateWithLocation();
  }

  syncStateWithLocation() {
    const { activeFilters, filterSet, filters, location, order, query } = this.state;
    const newState = calculateNextState(activeFilters, filterSet, filters, location, query, order);
    this.setState(newState);
  }

  updateLocation(update) {
    const { location } = this.state;
    const vars = extractVariablesFromLocation(location);
    const qsVars = removeEmpty(Object.assign(vars, update));
    this.props.history.push({
      pathname: location.pathname,
      search: `?${queryString.stringify(qsVars, { sort: false })}`
    });
  }

  onApplyFilters(filters) {
    const { filterSet } = this.state;
    const filterIds = {};
    filterSet.forEach(fs => (filterIds[fs.group] = (filters[fs.group] || []).map(i => i.id)));
    this.updateLocation(filterIds);
    this.setState({ activeFilters: filters });
  }

  onRemoveFilter(nextFilters) {
    this.onApplyFilters(nextFilters);
  }

  componentDidMount() {
    window.onpopstate = this.handlePopState;
  }

  componentWillUnmount() {
    window.onpopstate = () => {};
  }

  render() {
    const { activeFilters, error, loading, order, pageType, query } = this.state;
    const pageTitle = 'Browse ' + titleize(pageType);
    return (
      <div className={`app-content browse browse__${pageType}`}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Hero>
          <BackToHomeLink />
        </Hero>
        <Container className="app-content__container" fluid>
          <SearchFilterAndSort
            activeFilters={activeFilters}
            loading={loading}
            error={error}
            order={order}
            onApplyFilters={this.onApplyFilters}
            onRemoveFilter={this.onRemoveFilter}
            onSearch={this.onSearch}
            onSort={this.onSort}
            pageType={pageType}
            query={query}
          />
        </Container>
      </div>
    );
  }
}

Browse.propTypes = {
  pageType: PropTypes.oneOf(['apps', 'vendors']).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  filters: PropTypes.object,
  availableFilters: PropTypes.object
};

export default Browse;
