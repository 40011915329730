import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeButton, FeListItemGroup } from 'fe-fabric-react';
import Popover from '../Popover';
import './ListSort.css';

class ListSort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: props.selectedKey,
      isOpen: undefined
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (nextProps.selectedKey !== prevState.selectedKey) nextState.selectedKey = nextProps.selectedKey;

    if (Object.keys(nextState) > 0) return nextState;
    return null;
  }

  render() {
    const { label, sortOptions, selectedKey, onOptionSelected } = this.props;
    const activeItem = sortOptions.find(opt => opt.id === selectedKey);
    let activeItemLabel;
    if (activeItem) {
      activeItemLabel = activeItem.label;
    }

    let buttonLabel = label;
    if (activeItemLabel) buttonLabel = `${label}: ${activeItemLabel}`;

    const disabled = sortOptions.length === 0;

    const trigger = ({ toggle, isOpen }) => (
      <FeButton onClick={toggle} feStyle="secondary" disabled={disabled}>
        {buttonLabel}{' '}
        <FontAwesomeIcon icon={['far', isOpen ? 'angle-up' : 'angle-down']} fixedWidth className="fe-icon" />
      </FeButton>
    );

    return (
      <div className="sort-dropdown">
        <Popover trigger={trigger} className="sort-dropdown__popover" placement="bottom-end">
          {({ close }) => {
            const onItemClick = ({ itemIndex }) => {
              const option = sortOptions[itemIndex];
              if (onOptionSelected) onOptionSelected(option);
              close();
            };
            return (
              <FeListItemGroup list={sortOptions} activeId={selectedKey} onItemClick={onItemClick} feStyle="dropdown" />
            );
          }}
        </Popover>
      </div>
    );
  }
}
ListSort.propTypes = {
  label: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string
    })
  ),
  selectedKey: PropTypes.string,
  onOptionSelected: PropTypes.func
};

ListSort.defaultProps = {
  label: 'Sort',
  sortOptions: []
};

export default ListSort;
