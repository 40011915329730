import gql from 'graphql-tag';

const CONTACT_US_MUTATION = gql`
  mutation ContactUs($contactAndMessage: ContactUsInput!) {
    contactUs(contactAndMessage: $contactAndMessage) {
      errors {
        field
        messages
      }
    }
  }
`;

export default CONTACT_US_MUTATION;
