import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import TopNav from './TopNav/TopNav';
import Logo from './Logo';
import NotificationsMenu from '../Notifications/NotificationsMenu';
import HeaderAuthItem from './HeaderAuthItem/HeaderAuthItem';
import goToNotification from '../../goToNotification';
import './Header.css';

class Header extends Component {
  constructor(props) {
    super(props);

    this.onViewNotification = this.onViewNotification.bind(this);
    this.onViewAll = this.onViewAll.bind(this);
    this.renderPersonalization = this.renderPersonalization.bind(this);
  }

  onViewNotification(notification, event) {
    if (event && event.preventDefault) event.preventDefault();
    goToNotification(notification, window.location, this.props.history);
  }

  onViewAll(event) {
    if (event && event.preventDefault) event.preventDefault();
    const { history } = this.props;
    if (history.location.pathname !== '/notifications') history.push('/notifications');
  }

  renderPersonalization() {
    const { showNotifications, showAuth, auth, history } = this.props;
    return (
      <>
        {showNotifications && (
          <NotificationsMenu onViewNotification={this.onViewNotification} onViewAll={this.onViewAll} showSettingsIcon />
        )}
        {showAuth && <HeaderAuthItem auth={auth} location={history.location} />}
      </>
    );
  }

  render() {
    const { showNav } = this.props;
    return (
      <TopNav className="header" renderLeft={Logo} renderRight={this.renderPersonalization}>
        {showNav && [
          <NavLink to="/apps" key="apps" className="fe-btn fe-btn--link">
            Apps
          </NavLink>,
          <NavLink to="/vendors" key="vendors" className="fe-btn fe-btn--link">
            Vendors
          </NavLink>
        ]}
      </TopNav>
    );
  }
}

Header.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  showNotifications: PropTypes.bool,
  showAuth: PropTypes.bool,
  showNav: PropTypes.bool
};

Header.defaultProps = {
  showNotifications: true,
  showAuth: true,
  showNav: true
};

export default Header;
