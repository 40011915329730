import React from 'react';
import PropTypes from 'prop-types';
import NotificationBanner from './NotificationBanner';
import NotificationGroup from './NotificationGroup';
import './Notification.css';

const Notification = props => {
  const { notification, viewNotification, showDisposition, expandGroupedNotifications } = props;
  const hasGroup = notification.notifications && notification.notifications.length >= 1;
  let groupRef = React.createRef();

  const groupClickHandler = e => {
    groupRef.current.toggle();
  };

  const clickHandler = hasGroup ? groupClickHandler : viewNotification;

  return (
    <NotificationBanner {...props} hasGroup={hasGroup} isOpen={expandGroupedNotifications} onClick={clickHandler}>
      {notification.notifications && notification.notifications.length > 0 && (
        <NotificationGroup
          className="notification__collapse"
          list={notification.notifications}
          render={n => (
            <NotificationBanner
              key={n.id}
              className="notification--is-child"
              notification={n}
              onClick={viewNotification}
              showDisposition={showDisposition}
            />
          )}
          ref={groupRef}
          isOpen={expandGroupedNotifications}
        />
      )}
    </NotificationBanner>
  );
};

Notification.defaultProps = {
  showDisposition: false,
  expandGroupedNotifications: false
};

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  viewNotification: PropTypes.func.isRequired,
  showDisposition: PropTypes.bool,
  expandGroupedNotifications: PropTypes.bool
};

export default Notification;
