import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import CustomPropTypes from '../../customPropTypes';

export const LinkTag = ({ children, to, onClick, disabled, active }) => {
  const className = classnames('fe-pill-box', 'tag', {
    active,
    disabled
  });
  return (
    <Link to={to} onClick={onClick} className={className}>
      <span className="label">{children}</span>
    </Link>
  );
};

LinkTag.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: CustomPropTypes.reactRouterLocation(),
  disabled: PropTypes.bool,
  active: PropTypes.bool
};

export default LinkTag;
