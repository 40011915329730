import React from 'react';
import PropTypes from 'prop-types';
import { FeButton } from 'fe-fabric-react';
import { Mutation } from 'react-apollo';
import TOGGLE_MODAL from './ToggleModal.mutation';

const ShowModalButton = ({ modal, button, children }) => (
  <Mutation mutation={TOGGLE_MODAL} variables={{ modal }}>
    {showModal => (
      <FeButton {...button} onClick={showModal}>
        {children}
      </FeButton>
    )}
  </Mutation>
);

ShowModalButton.propTypes = {
  children: PropTypes.node,
  modal: PropTypes.string.isRequired,
  button: PropTypes.object
};

export default ShowModalButton;
