import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import ShowModalButton from '../../components/AppModal/ShowModalButton';
import { CONTACT_US } from '../../components/AppModal/modalTypes';
import './Footer.css';

const Footer = ({ showTermsOfUse, showFAQ, showFeedback }) => {
  const TermsOfUseItem = () =>
    showTermsOfUse ? (
      <li className="footer__links-link">
        <NavLink to="/termsofuse">Terms of use</NavLink>
      </li>
    ) : null;

  const FAQItem = () =>
    showFAQ ? (
      <li className="footer__links-link">
        <NavLink to="/faq">FAQs</NavLink>
      </li>
    ) : null;

  const FeedbackItem = () =>
    showFeedback ? (
      <li className="footer__links-link">
        <ShowModalButton modal={CONTACT_US} button={{ feStyle: 'link' }}>
          Feedback
        </ShowModalButton>
      </li>
    ) : null;
  return (
    <footer className="footer">
      <div className="footer__copyright">&copy; 2018-{new Date().getFullYear()} FireEye, Inc. All rights reserved.</div>
      <ul className="footer__links">
        <li className="footer__links-link">
          <ExternalLink href="https://www.fireeye.com/company/privacy.html" excludeIcon>
            Privacy & Cookies
          </ExternalLink>
        </li>
        <li className="footer__links-link">
          <ExternalLink href="https://www.fireeye.com/company/privacy-shield-commitment.html" excludeIcon>
            Privacy Shield
          </ExternalLink>
        </li>
        <TermsOfUseItem />
        <FAQItem />
        <li className="footer__links-link">
          <ExternalLink
            href="https://fireeyecommunity.force.com/CustomerCommunity/s/topic/0TO0c000000cE3eGAE/market"
            excludeIcon
          >
            Community
          </ExternalLink>
        </li>
        <FeedbackItem />
      </ul>
    </footer>
  );
};

Footer.propTypes = {
  showTermsOfUse: PropTypes.bool,
  showFAQ: PropTypes.bool,
  showFeedback: PropTypes.bool
};

Footer.defaultProps = {
  showTermsOfUse: true,
  showFAQ: true,
  showFeedback: true
};

export default Footer;
