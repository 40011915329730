import React from 'react';
import PropTypes from 'prop-types';
import TermsIncludedInApp from './TermsIncludedInApp';
import TermsIncludedInRepository from './TermsIncludedInRepository';
import TermsInMarket from './TermsInMarket';
import TermsOn3rdPartySite from './TermsOn3rdPartySite';
import './AssentToTerms.css';

const AssentToTerms = ({ app, assentedToTerms, onSubmit, onExternalDownload, loading }) => {
  const onFormSubmit = e => e.preventDefault() & onSubmit();
  const wrapInForm = button => (
    <form className="assent" onSubmit={onFormSubmit}>
      {button}
    </form>
  );
  switch (app.type.id) {
    case 'freeware_apps':
      return <TermsIncludedInApp app={app} loading={loading} render={wrapInForm} />;
    case 'open-source_software':
      return (
        <TermsIncludedInRepository
          app={app}
          loading={loading}
          render={wrapInForm}
          onExternalDownload={onExternalDownload}
        />
      );
    case 'product_extensions':
      if (app.platforms.map(p => p.id).includes('third_party')) {
        return (
          <TermsOn3rdPartySite
            app={app}
            loading={loading}
            render={wrapInForm}
            onExternalDownload={onExternalDownload}
          />
        );
      }
    // fall through
    case 'iocs':
    case 'orchestration_add-on':
    case 'xagent_add-on':
    default:
      return <TermsInMarket app={app} assentedToTerms={assentedToTerms} render={wrapInForm} loading={loading} />;
  }
};

AssentToTerms.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired,
  assentedToTerms: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onExternalDownload: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default AssentToTerms;
