import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { ifUnauthenticated } from '../../notificationsIdentity';
import { limit, NOTIFICATIONS_QUERY } from './listNotifications';
import { NOTIFICATION_COUNTS_QUERY } from './withNotificationStateCounts';

const SET_NOTIFICATION_READ = gql`
  mutation MarkNotificationRead($id: ID!) {
    updateNotification(id: $id, isSeen: true, isRead: true) {
      id
      source
      category
      disposition
      message
      url
      isSeen
      isRead
      createdAt
    }
  }
`;

const update = (cache, { data: { updateNotification: notification } }) => {
  if (!notification) return;

  const variables = { limit };
  const { notifications } = cache.readQuery({ query: NOTIFICATIONS_QUERY, variables });
  const updatedNotification = notifications.find(n => n.id === notification.id);
  if (!updatedNotification) return;

  updatedNotification.isSeen = true;
  updatedNotification.isRead = true;
  cache.writeQuery({ query: NOTIFICATIONS_QUERY, variables, data: { notifications } });

  const cachedCounts = cache.readQuery({ query: NOTIFICATION_COUNTS_QUERY });
  const data = Object.assign({}, cachedCounts, { unseenCount: cachedCounts.unseenCount - 1 });
  cache.writeQuery({ query: NOTIFICATION_COUNTS_QUERY, data });
};

const options = ({ client }) => ({ client, update });

const props = ({ updateNotification }) => ({
  markAsRead: notification =>
    updateNotification({
      variables: { id: notification.id },
      optimisticResponse: { updateNotification: Object.assign({}, notification, { isSeen: true }) }
    })
});

const markAsRead = graphql(SET_NOTIFICATION_READ, {
  name: 'updateNotification',
  skip: ifUnauthenticated,
  options,
  props
});

export default markAsRead;
export { SET_NOTIFICATION_READ, options, props, update };
