import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { ifUnauthenticated } from '../../notificationsIdentity';
import { withState, withProps, branch } from 'recompose';

const limit = 20;

const NOTIFICATIONS_QUERY = gql`
  query Notifications($limit: Int, $nextId: ID) {
    notifications(limit: $limit, nextId: $nextId) {
      id
      source
      category
      disposition
      message
      url
      isSeen
      isRead
      createdAt
    }
  }
`;

const buildUpdateQuery = (setHasMore, setLoadingMore) => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    setHasMore(false);
    setLoadingMore(false);
    return prev;
  }
  const { notifications } = fetchMoreResult;
  if (notifications.length < limit) setHasMore(false);
  if (notifications.length === limit) setHasMore(true);
  setLoadingMore(false);
  return Object.assign({}, prev, { notifications: [...prev.notifications, ...notifications] });
};

const buildLoadMore = (notifications = [], fetchMore, setHasMore, setLoadingMore) => () => {
  setLoadingMore(true);
  const nextId = notifications.length === 0 ? undefined : notifications[notifications.length - 1].id;
  fetchMore({
    variables: { limit, nextId },
    updateQuery: buildUpdateQuery(setHasMore, setLoadingMore)
  });
};

const listNotificationsProps = ({
  data: { loading, error, notifications = [], fetchMore },
  ownProps: { hasMore, setHasMore, loadingMore, setLoadingMore }
}) => ({
  loading,
  error,
  notifications,
  hasMore: notifications.length < limit ? false : hasMore,
  loadMore: buildLoadMore(notifications, fetchMore, setHasMore, setLoadingMore),
  loadingMore
});

const listNotificationsOptions = ({ client }) => ({ client, variables: { limit } });

const listNotifications = graphql(NOTIFICATIONS_QUERY, {
  skip: ifUnauthenticated,
  props: listNotificationsProps,
  options: listNotificationsOptions
});

export default compose(
  withState('hasMore', 'setHasMore', true),
  withState('loadingMore', 'setLoadingMore', false),
  branch(ifUnauthenticated, withProps({ notifications: [], loading: false, hasMore: false, loadingMore: false })),
  listNotifications
);
export {
  limit,
  NOTIFICATIONS_QUERY,
  buildUpdateQuery,
  buildLoadMore,
  listNotificationsProps,
  listNotificationsOptions
};
