import { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class OktaLoginRedirect extends Component {
  componentDidMount = async () => {
    const { auth, location } = this.props;
    const isAnonymous = await auth.isAnonymous();
    const isRoot = location.pathname === '/';
    const qsParams = queryString.parse(location.search);
    const isOktaIssuer = qsParams.iss === `https://${process.env.REACT_APP_IDP}`;
    if (isAnonymous && isRoot && isOktaIssuer) await auth.login('/');
  };

  render() {
    return null;
  }
}

OktaLoginRedirect.propTypes = {
  auth: PropTypes.shape({
    isAnonymous: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
};

export default OktaLoginRedirect;
