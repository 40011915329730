import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';
import ExternalLink from '../ExternalLink/ExternalLink';

const LinkListRow = ({ title, list }) => (
  <tr>
    <th>{title}</th>
    <td>
      <ul>
        {list.map(item => (
          <li key={item.uri}>
            <ExternalLink href={item.uri}>{item.name}</ExternalLink>
          </li>
        ))}
      </ul>
    </td>
  </tr>
);
LinkListRow.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(CustomPropTypes.link).isRequired
};

export default LinkListRow;
