import React from 'react';
import { Container, Row, Col } from 'fe-fabric-react';
import { Helmet } from 'react-helmet';
import BackToHomeLink from '../../components/BackToHomeLink/BackToHomeLink';
import Hero from '../../components/Hero/Hero';
import Analytics from '../../Analytics';
import './FAQ.css';

const FAQ = () => {
  Analytics.record('FAQ');
  return (
    <div className="app-content faq">
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <Hero title="Frequently Asked Questions">
        <BackToHomeLink />
      </Hero>
      <Container className="faq__container" fluid>
        <Row>
          <Col>
            <h2>About FireEye Market</h2>
            <p>
              The FireEye Market is a place to discover free tools created by FireEye for the greater security
              community, and product extensions and plugins into FireEye’s products and services. Learn more with this{' '}
              <a
                href="https://videoshare.fireeye.com/watch/oX3jtiisZF8A9Yua8ZdCUm"
                rel="noopener noreferrer"
                target="_blank"
              >
                introductory video.
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6">
            <h2>Getting Started</h2>
            <dl>
              <dt>How do I sign up for a login?</dt>
              <dd>
                <p>
                  Logins are only provided to FireEye customers, which opens up access to additional content and
                  features not available to the public.
                </p>
              </dd>
              <dt>Do I need to be a FireEye customer to download content?</dt>
              <dd>
                <p>
                  We offer many freeware and OSS tools in the Market for everyone to use. However, we do have some tools
                  that require product subscriptions. Those include apps that might use our product APIs, or
                  plugins/packages that are installed into our platform.
                </p>
              </dd>
              <dt>What content lives in the Market?</dt>
              <dd>
                <p>
                  The Market is a mixture of freeware and OSS tools, product extensions/plugins, and additional content
                  to augment and improve your FireEye experience. The Market content contains everything from full
                  standalone web, desktop and mobile apps, to plugins and orchestration packages that add new
                  capabilities to FireEye products. We even share things as simple as Python scripts might do standalone
                  work or tie together two solutions.
                </p>
              </dd>
            </dl>
          </Col>
          <Col sm="12" lg="6">
            <h2>Roadmap and Contributing</h2>
            <dl>
              <dt>Do you have a published roadmap? What’s coming next?</dt>
              <dd>
                <p>
                  We do have a roadmap, but nothing that is officially published. However, we are working on additional
                  collaboration and dev tools to begin allowing partners and customers to develop their own content for
                  the Market, and to share it with the greater FireEye community. Stay tuned for additional updates!
                </p>
              </dd>
              <dt>I have an idea for a new app. How do I share it with you?</dt>
              <dd>
                <p>
                  Reach out to{' '}
                  <a href="mailto:market@fireeye.com?subject=I have an idea for a new app">market@fireeye.com</a> if you
                  have an idea you want to share or request.
                </p>
              </dd>
              <dt>I want to create my own app for the Market. How do I do that?</dt>
              <dd>
                <p>
                  Reach out to{' '}
                  <a href="mailto:market@fireeye.com?subject=I want to create my own app for the Market">
                    market@fireeye.com
                  </a>{' '}
                  if you want to contribute your own app.
                </p>
              </dd>
            </dl>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQ;
