import { compose, mapProps } from 'recompose';
import AppDetails from './AppDetails';
import withAuth from './withAuth';
import withTermsAssent from './withTermsAssent';
import withApp from './withApp';
import withCloseModal from './withCloseModal';
import withDownloadModal from './withDownloadModal';

const withRouteProps = mapProps(({ match: { params: { appId }, url }, history }) => ({ id: appId, url, history }));

export default compose(
  withRouteProps,
  withAuth,
  withTermsAssent,
  withApp,
  withDownloadModal,
  withCloseModal
)(AppDetails);
