import React from 'react';
import PropTypes from 'prop-types';
import Error from '../../Error/Error';
import Panel from '../../Panel/Panel';
import AppSubscription from './AppSubscription';
import SectionHeading from './SectionHeading';

class SubscriptionGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: props.apps,
      loading: props.loading,
      error: props.error
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { apps, loading, error } = props;
    const nextProps = { apps };
    if (loading !== state.loading) nextProps.loading = loading;
    if (error !== state.error) nextProps.error = error;
    return nextProps;
  }

  render() {
    const { apps, loading, error } = this.state;
    const { title, subhead, ...otherProps } = this.props;
    const disabled = apps.length === 0;
    return (
      <Panel
        isCollapsible
        isCollapsed
        isCollapseDisabled={disabled}
        size="lg"
        label={
          <SectionHeading title={title} subhead={subhead} count={apps.length} loading={loading} disabled={disabled} />
        }
        className="subscription-settings"
      >
        {error && <Error {...otherProps} />}
        {apps.map(app => (
          <AppSubscription key={app.id} app={app} />
        ))}
      </Panel>
    );
  }
}

SubscriptionGroup.propTypes = {
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  subhead: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.object
};

export default SubscriptionGroup;
