import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import BackToHomeLink from '../../components/BackToHomeLink/BackToHomeLink';
import Hero from '../../components/Hero/Hero';
import './NotFound.css';

const NotFound = () => (
  <div className="app-content not-found">
    <Helmet>
      <title>404: Not Found</title>
    </Helmet>
    <Hero>
      <BackToHomeLink />
    </Hero>
    <div className="app-content__container">
      <FontAwesomeIcon className="not-found__icon" icon={['fal', 'question-circle']} />
      <h1>Sorry, page not found!</h1>
      <p>
        Please try again, or reach out to us at <a href="mailto:market@fireeye.com">market@fireeye.com</a> and let us
        know what went wrong.
      </p>
    </div>
  </div>
);

NotFound.showNotFoundFromError = error => {
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.some(err => err.extensions && err.extensions.code === 'BAD_USER_INPUT')
  ) {
    return true;
  }
  return false;
};

export default NotFound;
