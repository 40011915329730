import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { limit, NOTIFICATIONS_QUERY } from './listNotifications';
import { NOTIFICATION_COUNTS_QUERY } from './withNotificationStateCounts';

const SET_LAST_READ_NOTIFICATION = gql`
  mutation SetLastReadNotification($id: ID!) {
    setLastReadNotification(id: $id)
  }
`;

const skip = ({ isAuthenticated, loading, notifications = [] }) =>
  !isAuthenticated || loading || notifications.length === 0;

const options = ({ client, loading, notifications = [] }) => ({
  client,
  variables: {
    id: !loading && notifications.length > 0 ? notifications[0].id : undefined
  },
  optimisticResponse: {
    setLastReadNotification: true
  },
  update
});

const update = cache => {
  const variables = { limit };
  const cachedData = cache.readQuery({ query: NOTIFICATIONS_QUERY, variables });
  const notifications = cachedData.notifications.map(n => Object.assign({}, n, { isSeen: true, isRead: true }));
  cache.writeQuery({ query: NOTIFICATIONS_QUERY, variables, data: { notifications } });
  cache.writeQuery({ query: NOTIFICATION_COUNTS_QUERY, data: { unseenCount: 0, unreadCount: 0 } });
};

const markAllAsRead = graphql(SET_LAST_READ_NOTIFICATION, {
  name: 'markAllAsRead',
  skip,
  options
});

export default markAllAsRead;
export { SET_LAST_READ_NOTIFICATION, skip, options, update };
