import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './AwesomeCheckbox.css';

const AwesomeCheckbox = ({ checked, onChange, children, className }) => (
  <div className={classNames('form-check', 'awesome-check', 'pointer', { 'awesome-check--checked': checked })}>
    <label>
      <input type="checkbox" onChange={e => onChange(e.target.checked)} checked={checked} className={className} />
      <FontAwesomeIcon
        icon={checked ? ['fas', 'check-square'] : ['fal', 'square']}
        fixedWidth
        size="lg"
        className="awesome-check__input-icon"
      />
      {children}
    </label>
  </div>
);

AwesomeCheckbox.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string
};

AwesomeCheckbox.defaultProps = {
  checked: false
};

export default AwesomeCheckbox;
