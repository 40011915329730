import PropTypes from 'prop-types';

const snapshotActor = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  email: PropTypes.string
});

const CustomPropTypes = {
  filterSet: (props, propName, componentName) => {
    const propTypes = {
      [propName]: PropTypes.objectOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
          })
        )
      ).isRequired
    };
    const filterSet = Object.assign({}, props[propName]);
    delete filterSet['__typename'];
    const propsToValidate = { [propName]: filterSet };
    PropTypes.checkPropTypes(propTypes, propsToValidate, propName, componentName);
  },
  reactRouterLocation: () =>
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        state: PropTypes.object
      })
    ]),
  tag: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  link: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired
  }),
  contact: PropTypes.shape({
    email: PropTypes.string.isRequired
  }),
  snapshotActor,
  downloadRequirement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    email: PropTypes.string
  }),
  snapshotSummary: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    publishedVersion: PropTypes.string,
    publishedOn: PropTypes.string,
    publishedBy: snapshotActor,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired
      })
    ).isRequired,
    newerVersions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired
      })
    ).isRequired
  })
};

export default CustomPropTypes;
