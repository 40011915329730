import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const ASSENTED_TO_TERMS = loader('./AssentedToTerms.graphql');

const props = ({ data: { assentedToTerms, loading, error } }) => {
  if (loading || error) return { assentToTerms: false };
  const { assent } = assentedToTerms;
  return { assentToTerms: assent };
};

const options = ({ id }) => ({ variables: { id } });

const withTermsAssent = graphql(ASSENTED_TO_TERMS, {
  alias: 'withAssent',
  props,
  options
});

export default withTermsAssent;
export { ASSENTED_TO_TERMS, props, options };
