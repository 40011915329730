import React from 'react';
import PropTypes from 'prop-types';
import Popover from '../Popover';
import { FeButton } from 'fe-fabric-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TwitterButton from './TwitterButton';
import LinkedInButton from './LinkedInButton';
import FacebookButton from './FacebookButton';
import './SocialShare.css';

const SocialShare = props => {
  if (!props.enabled) return null;

  // eslint-disable-next-line react/prop-types
  const trigger = ({ toggle, isOpen }) => (
    <FeButton onClick={toggle} feStyle="secondary">
      Share <FontAwesomeIcon icon={['far', isOpen ? 'angle-up' : 'angle-down']} fixedWidth className="icon" />
    </FeButton>
  );

  return (
    <div className="social-share">
      <Popover trigger={trigger} className="social-share__popover" placement="bottom-start">
        <TwitterButton text={`${props.title}: ${props.summary}`} />
        <LinkedInButton {...props} />
        <FacebookButton />
      </Popover>
    </div>
  );
};

SocialShare.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  enabled: PropTypes.bool
};

SocialShare.defaultProps = {
  enabled: false
};

export default SocialShare;
