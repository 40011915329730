import React from 'react';
import PropTypes from 'prop-types';
import DownloadButton from '../DownloadButton/DownloadButton';

const TermsIncludedInApp = ({ loading, render, app }) => (
  <>
    <p>The Terms of Use for this software are subject to the licensing and terms outlined in the freeware app.</p>
    {render(
      app.distributions.map((distribution, index) => (
        <DownloadButton key={index} distribution={distribution} disabled={loading} loading={loading} />
      ))
    )}
  </>
);

TermsIncludedInApp.propTypes = {
  loading: PropTypes.bool,
  render: PropTypes.func,
  app: PropTypes.shape({
    distributions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
};

TermsIncludedInApp.defaultProps = {
  render: button => button
};

export default TermsIncludedInApp;
