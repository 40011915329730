import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'fe-fabric-react';
import CustomPropTypes from '../../customPropTypes';
import NotFound from '../../containers/NotFound/NotFound';
import Hero from '../../components/Hero/Hero';
import BackLink from '../../components/BackLink';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import { CompatibleApps, IntegrationDetails, VendorInfo, VendorOverview } from '../../components/VendorDetails';
import './VendorDetails.css';

const VendorDetails = ({ match, vendor, loading, error }) => {
  if (error) return <Error error={error} />;
  if (!loading && !vendor) return <NotFound />;
  const {
    params: { id, version }
  } = match;
  const {
    name,
    logo,
    about,
    products,
    thirdPartyProducts,
    categories,
    websiteUrl,
    partnerType,
    contacts,
    blogPosts,
    caseStudies,
    dataSheets,
    solutionBriefs,
    pressReleases,
    guides,
    marketplaces,
    videos
  } = vendor || {};
  const tagSets = { products, categories };
  const infoProps = { name, logo, about, tagSets };
  const overviewProps = { websiteUrl, partnerType, contacts };
  const integrationProps = { solutionBriefs, caseStudies, pressReleases, blogPosts, dataSheets, guides, marketplaces, videos };

  const title = `${name} - Vendor Details`;
  const url = `${process.env.REACT_APP_PUBLIC_URL || window.location.origin}${match.url}`;

  return (
    <div className="app-content vendor-details">
      {!loading && (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={about} />
          <meta property="og:title" content={name + ' - Vendor Details | FireEye Market'} />
          <meta property="og:description" content={about} />
          <meta property="og:url" content={url} />
          {logo && <meta property="og:image" content={logo.x2uri} />}
        </Helmet>
      )}
      <Hero>
        <BackLink fallback={{ location: '/vendors', title: 'Vendors' }} />
      </Hero>
      <Container className="vendor-details__container">
        {loading && !vendor && <Loading />}
        {vendor && <VendorInfo {...infoProps} />}
        <Row className="vendor-details__info">
          <Col xs={12} lg={{ size: 4, offset: 1 }} className="vendor-details__overview">
            <h3>Vendor Overview</h3>
            {vendor && <VendorOverview {...overviewProps} />}
          </Col>
          <Col xs={12} lg={{ size: 6 }} className="vendor-details__docs-and-support">
            <h3>Integration Details</h3>
            {vendor && <IntegrationDetails {...integrationProps} />}
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ size: 10, offset: 1 }}>
            <CompatibleApps vendor={id} version={version} thirdPartyProducts={thirdPartyProducts} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

VendorDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      version: PropTypes.string
    }).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      x2Uri: PropTypes.string.isRequired
    }).isRequired,
    about: PropTypes.string.isRequired,
    websiteUrl: PropTypes.string,
    products: PropTypes.arrayOf(CustomPropTypes.tag.isRequired).isRequired,
    thirdPartyProducts: PropTypes.arrayOf(CustomPropTypes.tag.isRequired).isRequired,
    categories: PropTypes.arrayOf(CustomPropTypes.tag.isRequired).isRequired,
    partnerType: PropTypes.string,
    contacts: PropTypes.arrayOf(CustomPropTypes.contact),
    blogPosts: PropTypes.arrayOf(CustomPropTypes.link),
    caseStudies: PropTypes.arrayOf(CustomPropTypes.link),
    dataSheets: PropTypes.arrayOf(CustomPropTypes.link),
    solutionBriefs: PropTypes.arrayOf(CustomPropTypes.link),
    pressReleases: PropTypes.arrayOf(CustomPropTypes.link),
    guides: PropTypes.arrayOf(CustomPropTypes.link),
    marketplaces: PropTypes.arrayOf(CustomPropTypes.link)
  }),
  loading: PropTypes.bool,
  error: PropTypes.object
};

export default VendorDetails;
