import { compose } from 'react-apollo';
import BroadcastToggle from './BroadcastToggle';
import withNotificationsClient from '../../../../withNotificationsClient';
import { showMarketBroadcastsForCategory, hideMarketBroadcastsForCategory } from './toggleMarketBroadcastVisibility';

const ConnectedBroadcastToggle = compose(
  withNotificationsClient,
  showMarketBroadcastsForCategory,
  hideMarketBroadcastsForCategory
)(BroadcastToggle);
ConnectedBroadcastToggle.displayName = 'ConnectedBroadcastToggle';
export default ConnectedBroadcastToggle;
