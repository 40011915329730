import gql from 'graphql-tag';

const HEADER_IDENTITY_QUERY = gql`
  query getHeaderIdentity {
    authentication @client {
      isAuthenticated
      identity {
        preferred_username
        name
        groups
      }
    }
  }
`;

export default HEADER_IDENTITY_QUERY;
