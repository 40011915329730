import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Manager, Reference, Popper } from 'react-popper';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';
import './Popover.css';

const duration = 250;
const defaultStyle = {
  opacity: 0,
  transition: `opacity ${duration}ms cubic-bezier(0, 0, 0.2, 1)`,
  willChange: 'opacity, transform',
};
const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

class Popover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleOutsideEvent = this.handleOutsideEvent.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (nextProps.isOpen !== undefined && nextProps.isOpen !== prevState.isOpen) nextState.isOpen = nextProps.isOpen;

    if (Object.keys(nextState).length > 0) return nextState;
    return null;
  }

  close() {
    const { isOpen } = this.state;
    if (isOpen) this.setState({ isOpen: false }, this.props.onClose);
  }

  toggle() {
    const { isOpen } = this.state;
    const newState = { isOpen: !isOpen };
    let onStateChange;
    if (!newState.isOpen) onStateChange = this.props.onClose;
    else onStateChange = this.props.onOpen;
    this.setState(newState, onStateChange);
  }

  handleOutsideEvent(event) {
    const { isOpen } = this.state;
    if (!isOpen) return;
    const referenceNodeContainsEventTarget = this.referenceNode && this.referenceNode.contains(event.target);
    const popperNodeContainsEventTarget = this.popperNode && this.popperNode.contains(event.target);
    if (event.target !== null && event.target.href && event.target.href.search('admin/apps')) this.close();
    if (!referenceNodeContainsEventTarget && !popperNodeContainsEventTarget) {
      this.close();
    }
  }

  componentDidMount() {
    document.addEventListener('touchend', this.handleOutsideEvent);
    document.addEventListener('click', this.handleOutsideEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideEvent);
    document.removeEventListener('touchend', this.handleOutsideEvent);
  }

  componentDidUpdate() {
    if (this.scheduleUpdate) this.scheduleUpdate();
  }

  render() {
    const { isOpen } = this.state;
    const { trigger, children, className, onClose, onOpen, ...popperProps } = this.props;
    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={(node) => (this.referenceNode = node)} className="popover-trigger">
              <div ref={ref} className="popover__toggle">
                {trigger({ toggle: this.toggle, isOpen })}
              </div>
            </div>
          )}
        </Reference>

        <Portal>
          <Transition in={isOpen} timeout={duration} mountOnEnter unmountOnExit>
            {(state) => (
              <Popper innerRef={(node) => (this.popperNode = node)} {...popperProps}>
                {({ ref, style, placement, scheduleUpdate }) => {
                  this.scheduleUpdate = scheduleUpdate;
                  return (
                    <div
                      className={classnames('popover', className)}
                      ref={ref}
                      style={{ ...style, ...defaultStyle, ...transitionStyles[state] }}
                      data-placement={placement}
                    >
                      <div className="popover__body">
                        {typeof children === 'function' ? children({ close: this.close }) : children}
                      </div>
                    </div>
                  );
                }}
              </Popper>
            )}
          </Transition>
        </Portal>
      </Manager>
    );
  }
}

Popover.propTypes = {
  isOpen: PropTypes.bool,
  trigger: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  placement: PropTypes.oneOf([
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
  ]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default Popover;
