import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';
import NewAndNoteworthyApps from './NewAndNoteworthyApps';

const NEW_AND_NOTEWORTHY_APPS = loader('./NewAndNoteworthy.graphql');
const props = ({ data: { paginatedApps: { apps = [] } = { apps: [] }, loading, error } }) => ({
  apps,
  loading,
  error
});
const options = ({ limit }) => ({
  variables: { limit }
});
const listNewAndNoteworthyApps = graphql(NEW_AND_NOTEWORTHY_APPS, {
  alias: 'withNewAndNoteworthyApps',
  props,
  options
});

export default listNewAndNoteworthyApps(NewAndNoteworthyApps);
export { NEW_AND_NOTEWORTHY_APPS, props, options };
