import React from 'react';
import PropTypes from 'prop-types';
import { FeLoader } from 'fe-fabric-react';
import './Loading.css';

const Loading = ({ size, text, inline }) => (
  <FeLoader className="loading" label={text} feSize={size} isLabelInline={inline} />
);

Loading.defaultProps = {
  size: 'xxl',
  text: 'Loading'
};

Loading.propTypes = {
  size: PropTypes.oneOf(['xxl', 'xl', 'l', 'sm']),
  text: PropTypes.string,
  inline: PropTypes.bool
};

export default Loading;
