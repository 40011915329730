import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { LIST_BROADCAST_OPT_OUTS } from '../BroadcastSettings/listBroadcastOptOuts';

const source = 'market';

export const SHOW_BROADCASTS_FOR_CATEGORY = gql`
  mutation ShowBroadcasts($preference: BroadcastPreferenceInput!) {
    unhideBroadcasts(preference: $preference) {
      source
      category
    }
  }
`;

export const showBroadcastsCacheUpdate = (proxy, { data: { unhideBroadcasts } }) => {
  const data = proxy.readQuery({ query: LIST_BROADCAST_OPT_OUTS });
  if (!data.optOuts) return;
  const index = data.optOuts.findIndex(
    opt => opt.source === unhideBroadcasts.source && opt.category === unhideBroadcasts.category
  );

  if (index >= 0) {
    data.optOuts.splice(index, 1);
    proxy.writeQuery({ query: LIST_BROADCAST_OPT_OUTS, data });
  }
};

export const showBroadcastsOptions = ({ category, client }) => ({
  client,
  variables: { preference: { source, category } },
  optimisticResponse: { unhideBroadcasts: { source, category, __typename: 'BroadcastPreference' } },
  update: showBroadcastsCacheUpdate
});

export const showMarketBroadcastsForCategory = graphql(SHOW_BROADCASTS_FOR_CATEGORY, {
  name: 'subscribe',
  options: showBroadcastsOptions
});

export const HIDE_BROADCASTS_FOR_CATEGORY = gql`
  mutation ShowBroadcasts($preference: BroadcastPreferenceInput!) {
    hideBroadcasts(preference: $preference) {
      source
      category
    }
  }
`;

export const hideBroadcastsCacheUpdate = (proxy, { data: { hideBroadcasts } }) => {
  const data = proxy.readQuery({ query: LIST_BROADCAST_OPT_OUTS });
  if (!data.optOuts) data.optOuts = [];
  const existingOptOut = data.optOuts.find(
    opt => opt.source === hideBroadcasts.source && opt.category === hideBroadcasts.category
  );
  if (existingOptOut) return;
  data.optOuts.push(hideBroadcasts);
  proxy.writeQuery({ query: LIST_BROADCAST_OPT_OUTS, data });
};

export const hideBroadcastsOptions = ({ category, client }) => ({
  client,
  variables: { preference: { source, category } },
  optimisticResponse: { hideBroadcasts: { source, category, __typename: 'BroadcastPreference' } },
  update: hideBroadcastsCacheUpdate
});

export const hideMarketBroadcastsForCategory = graphql(HIDE_BROADCASTS_FOR_CATEGORY, {
  name: 'unsubscribe',
  options: hideBroadcastsOptions
});
