import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

class VideoPlayer extends Component {
  state = {
    playing: false
  };
  onPlay = () => {
    this.setState({ playing: true });
  };
  pause = () => {
    this.setState({ playing: false });
  };
  render() {
    return (
      <ReactPlayer
        controls
        playing={this.state.playing}
        className="react-player"
        onPlay={this.onPlay}
        url={this.props.uri}
        width="100%"
        height="100%"
      />
    );
  }
}

VideoPlayer.propTypes = {
  uri: PropTypes.string.isRequired
};

export default VideoPlayer;
