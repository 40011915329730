import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchInput from './SearchInput';
import './Search.css';

// We can't use a functional component here because we need to use component
// local state. We need component local state because the input needs to be
// a Controlled Component. Read more here: https://reactjs.org/docs/forms.html#controlled-components
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { query: props.query };
    this.searchInputChanged = this.searchInputChanged.bind(this);
    this.clearTextInput = this.clearTextInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchInputChanged(event) {
    const {
      target: { value: query }
    } = event;
    this.setState(prevState => (prevState.query === query ? null : { query }));
  }

  clearTextInput(event) {
    event.preventDefault();
    this.setState({ query: '' }, () => {
      if (!this.props.suppressClear) this.performSearch('');
    });
  }

  onSubmit(event) {
    event.preventDefault();
    const { query } = this.state;
    this.performSearch(String(query).trim());
  }

  performSearch(query) {
    const { onSearch } = this.props;
    if (!onSearch) return;
    onSearch(query);
  }

  render() {
    const { query } = this.state;
    return (
      <form action="." onSubmit={this.onSubmit} className="search-bar">
        <SearchInput
          placeholder={this.props.placeholderText}
          query={query}
          onSearchInputChanged={this.searchInputChanged}
          onClear={this.clearTextInput}
        />
      </form>
    );
  }
}

Search.displayName = 'Search';

Search.propTypes = {
  query: PropTypes.string,
  onSearch: PropTypes.func,
  suppressClear: PropTypes.bool,
  placeholderText: PropTypes.string
};

Search.defaultProps = {
  query: '',
  placeholderText: 'Search Apps'
};

export default Search;
