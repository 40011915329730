import gql from 'graphql-tag';

const DOWNLOAD_APP_MUTATION = gql`
  mutation RequestDownload($downloadRequest: RequestDownloadInput!) {
    requestDownload(downloadRequest: $downloadRequest) {
      download {
        url
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default DOWNLOAD_APP_MUTATION;
