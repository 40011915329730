import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const APP_DETAILS = loader('./AppDetails.graphql');

const props = ({ data: { app, loading, error } }) => ({
  app,
  loading,
  error
});

const options = ({ id }) => ({ variables: { id } });

const withApp = graphql(APP_DETAILS, {
  alias: 'withApp',
  props,
  options
});

export default withApp;
export { APP_DETAILS, props, options };
