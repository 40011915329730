/*
  Central Configuration for the Filter Sets that are displayed to the customer
*/
const filterSets = {
  all: [
    { label: 'FireEye Products', group: 'products' },
    { label: 'Third Party Products', group: 'thirdPartyProducts' },
    { label: 'Categories', group: 'categories' },
    { label: 'Types', group: 'types' },
    { label: 'Platforms', group: 'platforms' },
    { label: 'Vendor Type', group: 'type' }
  ],
  apps: [
    { label: 'FireEye Products', group: 'products' },
    { label: 'Third Party Products', group: 'thirdPartyProducts' },
    { label: 'Categories', group: 'categories' },
    { label: 'Types', group: 'types' },
    { label: 'Platforms', group: 'platforms' }
  ],
  vendors: [
    { label: 'Partnership Type', group: 'type' },
    { label: 'FireEye Products', group: 'products' },
    { label: 'Categories', group: 'categories' }
  ]
};

export const defaultFilters = type => Object.assign({}, ...filterSets[type].map(fs => ({ [fs.group]: [] })));
export default filterSets;
