import React from 'react';
import { Container, Row, Col } from 'fe-fabric-react';
import { Helmet } from 'react-helmet';
import BackToHomeLink from '../../components/BackToHomeLink/BackToHomeLink';
import Hero from '../../components/Hero/Hero';
import Analytics from '../../Analytics';
import './TermsOfUse.css';
import ExternalLink from '../../components/ExternalLink/ExternalLink';

const TermsOfUse = () => {
  Analytics.record('TermsOfUse');
  return (
    <div className="app-content terms-of-use">
      <Helmet>
        <title>Terms of Use</title>
      </Helmet>
      <Hero title="Terms of Use">
        <BackToHomeLink />
      </Hero>
      <Container className="terms-of-use__container" fluid>
        <Row>
          <Col>
            <h2>FIREYE MARKET TERMS OF USE</h2>
            <p>
              The FireEye Market (the &quot;Market&quot;) is owned and operated by FireEye, Inc. and its affiliated
              entities (&quot;FireEye&quot; or &quot;we&quot; or &quot;us&quot;). Market is an online marketplace where
              we make available various downloadable software tools, plug-ins, apps, connectors, integrations,
              extensions, libraries, configurations open source tools, and other software applications, including any
              artifacts, such as code and documentation, provided with any of the foregoing (collectively &quot;Market
              Products&quot;). Market Products may interoperate with applicable FireEye Products (as defined below).
            </p>
            <p>
              To access portions of the Market or certain resources, you may be asked to provide certain registration
              details or other information. It is a condition of your use of the Market, and you hereby represent and
              warrant, that you are of the legal age of majority in the jurisdiction in which you reside and that all
              information you provide is correct, current and complete. If you choose, or you are provided with, a user
              name, password or other information as part of our security procedures, you must treat such information as
              confidential and you must not disclose it to any third party. You agree that you are solely responsible to
              us for all activities identified with your account. You agree to immediately notify FireEye of any
              unauthorized use of your user name or password or any other breach of security.
            </p>
            <ol>
              <li>
                <em>Binding Agreement</em>. By visiting the Market website, you agree that your visiting and use of the
                Market website is governed by these FireEye Market Terms of Use (&quot;Terms of Use&quot;), which you
                acknowledge represent a binding agreement between you and FireEye. If you are agreeing to these Terms of
                Use on behalf of a company or other organization, you represent that you have the authority to bind that
                company or organization to these Terms of Use, and the terms &quot;you&quot; and &quot;your&quot; will
                refer to that company or organization. If you do not have that authority, or if you do not agree with
                these Terms of Use, you may not use the Market or any of the Market Products. FireEye reserves the right
                to make changes to Market and to these Terms of Use at any time. Your continued use of Market and any
                Market Products will constitute your acceptance of any new or amended Terms of Use.
              </li>
              <li>
                <em>Availability of the Market</em>. We will not be liable if, for any reason, all or any part of the
                Market is unavailable at any time or for any period. From time to time, we may restrict access to all or
                part of the Market to registered users or another subset of users. You are solely responsible for making
                all arrangements necessary to access the Market.
              </li>
              <li>
                <em>IMPORTANT – USER ACCEPTANCE</em>: THESE TERMS OF USE FORM A BINDING CONTRACT BETWEEN YOU AND US WHEN
                ACCEPTED BY YOU. YOU ACCEPT THESE TERMS OF USE BY (1) ACCESSING OR USING THE MARKET, (2) INDICATING
                ACCEPTANCE OF THESE TERMS WHEN THEY ARE PRESENTED ONLINE, SUCH AS BY CHECKING A BOX CAPTIONED WITH
                ACCEPTANCE LANGUAGE OR CLICKING AN ICON BEARING AN “ACCEPT” OR SIMILAR LEGEND OR BY OTHERWISE
                ELECTRONICALLY SIGNING THIS AGREEMENT; OR (3) EXERCISING OR PURPORTING TO EXERCISE ANY OF THE RIGHTS
                GRANTED TO YOU UNDER THESE TERMS OF USE, INCLUDING DOWNLOADING OR USING ANY MARKET PRODUCT. IF YOU DO
                NOT AGREE TO THESE TERMS OF USE, YOU MAY NOT USE THE MARKET.
              </li>
              <li>
                <em>The Market</em>.
                <ol type="i">
                  <li>
                    <em>Overview</em>. In the Market, you can access, browse, purchase (if applicable) and/or download
                    Market Products. Market Products may be provided in source code or object code form. Market Products
                    include any updates, upgrades and other changes thereto and versions thereof that you later use or
                    download from the Market. FireEye may make some Market Products available via the Market at no
                    charge and others for a price. Market Products made available on the Market may be provided and
                    licensed to you by FireEye or by third parties (“Publishers”).
                  </li>
                  <li>
                    <em>FireEye Products &amp; Services Purchased Separately</em>. The Market does not include FireEye
                    Products and Services, as defined in Section 5(ii) below. You must purchase FireEye Products and
                    Services pursuant to a separate agreement with FireEye (“FireEye Purchase Agreement”).
                  </li>
                  <li>
                    <em>Technology Partners and Contributors to Market</em>. You may not post Market Products to the
                    Market unless you have entered into a FireEye technology integration partner program pursuant to a
                    separate written agreement or FireEye’s Contributor Agreement. Such separate agreements shall
                    control over these Terms of Use with respect to your posting of Market Products to the Market to the
                    extent that these Terms of Use conflict with that separate agreement.
                  </li>
                </ol>
              </li>
              <li>
                <em>Market Products</em>.
                <ol type="i">
                  <li>
                    <em>Market Products Provided &quot;As Is&quot;</em>. Market Products may or may not have been
                    reviewed, tested or validated by FireEye. The posting and availability of a Market Product shall not
                    be construed as making any representation, warranty, guarantee or promise apart from those set forth
                    in these Terms of Use, nor that your use of a Market Product is permitted by third parties or any
                    applicable laws and regulations. You are solely responsible for independently testing and validating
                    any Market Product before deploying it in a production environment, and evaluating whether using any
                    Market Product is permitted in your jurisdiction.
                  </li>
                  <li>
                    <em>Use of Market Products with FireEye Products</em>. You may use and/or download a Market Product
                    for your use on a device on which you have installed (or otherwise access) a validly licensed and/or
                    purchased FireEye Product or Service offering with which the Market Product is designed to
                    interoperate (&quot;FireEye Product/Services&quot;). Market Products can be used only on devices
                    that also have a validly licensed and/or acquired FireEye Product/Service installed and meet certain
                    other technical requirements described in the associated documentation in the Market as such
                    documentation is amended from time to time by FireEye.
                  </li>
                  <li>
                    <em>Evaluation of Market Products.</em> FireEye may offer free evaluation and/or other limited
                    versions of Market Products so you can preview Market Products before you download and/or purchase
                    the full version. If the Market Product is only provided for evaluation purposes, your rights are
                    limited to an evaluation license that permits you to use, download, install, and/or operate the
                    Evaluation Market Product for a limited period of time (&quot;Evaluation Period&quot;), and it will
                    only be accessible by a limited number of temporary users, in each case as determined by FireEye or,
                    if applicable, the Publisher. On the expiry of the Evaluation Period, the Evaluation Market Product
                    will cease to function and/or you will be required to remove and delete all copies of such
                    Evaluation Market Product in your possession or control.
                  </li>
                  <li>
                    <em>Third Party Market Products</em>. You acknowledge that the Market includes listings for Market
                    Products that are provided by a third-party Publisher (the, &quot;Third Party Products&quot;).
                    Notwithstanding anything to the contrary in these Terms of Use, you acknowledge and agree that: (1)
                    you must use your own discretion when you obtain Third Party Products through the Market and you
                    should read the terms and conditions and privacy policies of the applicable third party Publishers,
                    and (2) FireEye does not own or control any third party websites or the Third Party Products, and
                    (3) you will not hold us responsible and FireEye shall not be liable for your access to or use of
                    (i) any such third-party website (including its content, terms and conditions, privacy policy, or
                    anything else); (ii) the Third Party Products; or (iii) any claims arising out of your use of the
                    Third Party Products, under any circumstances. For clarity, subject to the foregoing, all
                    Third-Party Products are included in the definition of &quot;Market Products&quot; for purposes of
                    these Terms of Use.
                  </li>
                  <li>
                    <em>Support and Maintenance of Market Products</em>. Market Products may come with no support and
                    maintenance or may have varying levels of support and maintenance. If FireEye supports a FireEye
                    published Market Product, it shall be indicated that the Market Product is supported and a support
                    contact will be listed. Any available support and maintenance of Third Party Products shall be as
                    described in the Market or in the associated documentation for that Market Product and shall be
                    subject to any limitations and restrictions set forth in the Market and/or such documentation.
                    Failure of a Publisher (including FireEye) to provide support and maintenance will not entitle you
                    to any refund of fees in connection with obtaining the applicable Market Product, support and
                    maintenance, and/or to pursue any monetary claim against a Publisher (including FireEye). For
                    purposes of further clarity, you agree and acknowledge that FireEye has no responsibility for
                    providing support and maintenance with respect to any Market Product.
                  </li>
                </ol>
              </li>
              <li>
                <em>License and Restrictions</em>.
                <ol type="i">
                  <li>
                    <em>Separate License to Use Market Products</em>. Your use of a Market Product will be governed by
                    the terms and conditions of an end user license agreement between you and the Publisher of the
                    Market Product (a “EULA”). If FireEye is the Publisher of a Market Product, then that Market Product
                    will be governed by these Terms of Use, including Section 6(ii) (the “FireEye Standard EULA”) below
                    and any additional end user license terms included in or with the Market Product (collectively, the
                    &quot;FireEye EULA&quot;). In the event of a conflict between the terms of the Market Product end
                    user license terms and the FireEye Standard EULA, whether the Market Product is paid for or not,
                    then the terms of the Market Product end user license terms govern your use of the product. In the
                    event a third party is the Publisher of a Market Product, the Market Product will be governed by the
                    end user license terms included in or with the Market Product (the &quot;Third Party Publisher
                    EULA&quot;). Further, certain Market Products may be open source and subject to open source license
                    terms (an “Open Source License”). You acknowledge and agree that FireEye has the right to enforce
                    these Terms of Use and the FireEye EULA against you and that the Publisher has the right to enforce
                    the Third-Party Publisher EULA against you. If you do not want to comply with the applicable EULA or
                    Open Source License for a Market Product, you must not download and use that Market Product.
                  </li>
                  <li>
                    <em>FireEye Standard EULA</em>.
                    <ol type="a">
                      <li>
                        <em>License</em>. FireEye grants you a limited, worldwide, non-transferable, revocable license
                        to use the Market Products published by FireEye solely for your internal business use and only
                        on hardware systems owned, leased or controlled by you. If the Market Product is offered
                        specifically for use with a FireEye Product or Service, then you may only use that Market
                        Product with the designated FireEye Product or Service, and this license is only valid during
                        the term of your license agreement with FireEye (the “FireEye License”) for the applicable
                        FireEye Product or Service for which the Market Product was made available and shall immediately
                        terminate upon any termination or expiration of that FireEye License.
                      </li>
                      <li>
                        <em>Additional Restrictions</em>. You may not (i) modify, (ii) reverse engineer, decompile or
                        disassemble, (ii) create any derivative works of or (iv) distribute, transfer, resell, rent,
                        lease, or sublicense any rights in the Market Products published by FireEye, unless otherwise
                        expressly authorized in writing by FireEye. If FireEye makes available a source code version of
                        the Market Product, notwithstanding the foregoing, you may modify the source code versions in
                        accordance with these Terms of Use and the applicable EULA; provided, that FireEye will have no
                        obligation to provide support for any modified versions and you shall have the sole
                        responsibility for supporting and maintaining such modified source code versions.
                      </li>
                      <li>
                        <em>Ownership</em>. The Market Products are protected by copyright and other intellectual
                        property laws and treaties. FireEye or its licensors own all right, title, and interest in and
                        to the Market Products published by FireEye and all intellectual property rights therein and
                        thereto. The Market Products are licensed and not sold.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <em>Privacy and Your Data</em>.
                <ol type="a">
                  <li>
                    <em>Information Provided to Third Party Publisher</em>. You acknowledge, agree, consent and
                    authorize FireEye to, in connection with your download of one or more Third Party Product(s),
                    provide the third party Publisher with the personal information provided by you in completing the
                    download, and to the extent applicable, the purchase (including without limitation, your name, your
                    IP address, company name (if any), addresses (including e-mail address) and phone number).
                  </li>
                  <li>
                    <em>Consent to Use Data and Personal Information</em>. You hereby expressly acknowledge, agree and
                    consent that Publishers and FireEye (if FireEye is not the Publisher) may collect and use personal
                    information, technical data and related information, (including without limitation, your name, your
                    IP address, company name (if any), addresses (including e- mail address) and phone number) as well
                    as technical information relating to your device, system, Market Product, and FireEye Product that
                    is gathered periodically to facilitate the provision of software updates, product support, marketing
                    efforts and other services to you related to the Market Products and/or FireEye Products. Publishers
                    and FireEye (if FireEye is not the Publisher) may use this information, as long as it is in a form
                    that does not personally identify you, to improve its products and services, develop new offerings,
                    and/or to provide services or technology to you (including with respect to FireEye, the Market,
                    Market Products and FireEye Products).
                  </li>
                  <li>
                    <em>Third Party Publisher Access to Your Data and Personal Information</em>. If you evaluate and/or
                    install Third Party Products, those Third Party Products may transmit your data and personal
                    information to the third-party Publisher who may be able to obtain access to your data and
                    information related to your FireEye Products through the applicable FireEye Product’s application
                    programming interface (API). Such access may result in the disclosure, modification or deletion of
                    your data by those Publishers or their Third Party Products. Further, the Publisher and its agents
                    and partners may collect and use data pertaining to your configuration and/or use of the Market
                    Product(s) according to terms described in the Publisher’s privacy policies. FireEye is not
                    responsible for any transmission, collection, disclosure, modification, use or deletion of your
                    data, as described in this paragraph, by or through Third Party Products or their Publishers. Any
                    information FireEye receives from you is subject to the FireEye Privacy Policy located at{' '}
                    <ExternalLink href="https://www.fireeye.com/company/privacy.html">
                      https://www.fireeye.com/company/privacy.html
                    </ExternalLink>
                    .
                  </li>
                </ol>
              </li>
              <li>
                <em>Reviews of Market Products</em>. The Market allows you to post reviews of Market Products you have
                used, and to post comments on such reviews. Such reviews and comments will be posted under your name as
                submitted to the applicable FireEye.com web pages. If you do not want your name to appear with any
                reviews or comments, do not post any reviews or comments on the Market. To be useful to other users,
                those reviews and comments you post must be made in good faith after reasonable evaluation of the full
                Market Product. If you do post reviews or comments, you grant FireEye a nonexclusive, royalty- free,
                fully paid up, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt,
                publish, translate, create derivative works from, distribute, and display such reviews and comments
                throughout the world in any media.
                <p>You acknowledge and agree that:</p>
                <ul>
                  <li>
                    If you post a review of, or comment on, a Market Product for which you are the Publisher, you must
                    disclose your affiliation.
                  </li>
                  <li>
                    If you post a review of, or comment on, a competitor&#39;s Market Product, you must disclose your
                    affiliation.
                  </li>
                </ul>
                <p>
                  We may, but are under no obligation to, monitor or moderate reviews or comments made by users or
                  content provided by contributors and we are not responsible for the accuracy, completeness,
                  appropriateness or legality of anything posted, depicted or otherwise provided by third‐party users
                  and we disclaim any and all liability relating thereto. Reviews and comments may not contain, and
                  FireEye reserves the right to remove reviews and comments that contain content that:
                </p>
                <ul>
                  <li>
                    is harmful, threatening, abusive, insulting, harassing, defamatory, libelous, profane, sexually
                    explicit, obscene, or otherwise offensive or objectionable;
                  </li>
                  <li>is false, fraudulent or misleading;</li>
                  <li>
                    violates applicable laws, rules, regulations or guidelines or rights of third parties; including
                    without limitation any privacy, intellectual property or other rights of a third party, or suggests
                    or encourages unlawful activity;
                  </li>
                  <li>
                    constitutes advertising or any other form of commercial solicitation; or •impersonates any other
                    person or entity, including any of FireEye’s employees.
                  </li>
                </ul>
              </li>
              <li>
                <em>General</em>.
                <ol type="a">
                  <li>
                    <em>Prohibited Uses</em>. You agree not to access or use the Market in any manner:
                    <ul>
                      <li>
                        That violates any applicable federal, state, local or international law or regulation, or
                        advocates, promotes or assists in any unlawful act, constitutes an illegal threat or violates
                        export control laws.
                      </li>
                      <li>
                        That violates the rights of any person or entity that may give rise to civil or criminal
                        liability under applicable laws or regulations applicable to you, another user, and/or FireEye,
                        including violation of privacy or publicity rights, infringement of any copyright, patent,
                        trademark, trade secret or other intellectual property right, or conflicts with these Terms of
                        Use or the FireEye Privacy Policy.
                      </li>
                      <li>
                        To transmit or introduce offensive materials, including those involving profanity, violence,
                        sexual conduct, pornography or discrimination based on race, sex, religion, nationality,
                        disability, sexual orientation or age.
                      </li>
                      <li>
                        To monitor or attempt to gain unauthorized access to: (a) any data, information or
                        communications on any network or system not owned by you, without authorization; or (b) any
                        system or network user accounts or passwords of other users, without authorization.
                      </li>
                      <li>
                        That is false, deceptive, misleading or fraudulent, including but not limited to: (a) any
                        attempt to impersonate any person or entity; (b) to misrepresent your identity or affiliation
                        with any person or organization; and (c) any attempt to give the impression that you are posting
                        materials from any person or entity other than yourself if that is not the case, including but
                        not limited to altering your IP source address.
                      </li>
                      <li>
                        To monitor or copy materials from the Market for any unauthorized purpose or access the Market
                        via any automatic device, process or means of access such as a robot or spider.
                      </li>
                      <li>
                        That is in violation of export or import laws, rules or regulations. You acknowledge that the
                        Market Products may be subject to the U.S. Export Administration Regulations (the “EAR”) and
                        that you shall comply with the EAR and the provisions of Section 9(e) below.
                      </li>
                      <li>
                        That may have a detrimental effect on the Market’s functionality, performance, availability,
                        integrity, or security, including but not limited to: (a) gaining unauthorized access to, or
                        attempting to compromise the security of, any network, system, computing facility, equipment,
                        data or information; (b) attempting to intercept, redirect or otherwise interfere with
                        communications intended for others; (c) disabling, damaging, overburdening or impairing the
                        Market or any server, computer or database connected to or accessed by the Market; (d)
                        modifying, blocking or otherwise interfering with the display of the Market; (e) interfering
                        with another user’s ability to access, use and enjoy the Market; (f) accessing another user’s
                        registration information or user account without that user’s express written permission; (g)
                        transmitting or introducing any malicious or technologically harmful element to the Market such
                        as a spyware program, virus, Trojan horse, worm or logic bomb; (h) performing, without FireEye’s
                        express prior written authorization, scalability testing, load testing, probing, scanning,
                        penetration or vulnerability testing of the Market; and (i) engaging in any activities that
                        results in any server serving the Market being the target of a denial of service attack.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <em>DISCLAIMER</em>. THE MATERIALS MADE AVAILABLE IN THE MARKET, INCLUDING WITHOUT LIMITATION THE
                    MARKET PRODUCTS AND THEIR RELATED DOCUMENTATION, ARE PROVIDED “AS IS” AND WITHOUT WARRANTY. YOU
                    ACKNOWLEDGE AND AGREE THAT YOU WILL EXERCISE YOUR OWN INDEPENDENT ANALYSIS AND JUDGMENT IN YOUR USE
                    OF THESE MATERIALS AND THE MARKET PRODUCTS. FIREEYE ASSUMES NO LIABILITY FOR YOUR USE OF THESE
                    MATERIALS, THE MARKET PRODUCTS OR ANY ASSISTANCE PROVIDED BY FIREEYE. FIREEYE AND ITS SUPPLIERS
                    ALONG WITH ANY OTHER PUBLISHER EXPRESSLY DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING BUT
                    NOT LIMITED TO, ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, ACCURACY, TITLE AND NON-INFRINGEMENT OF ANY THIRD PARTY INTELLECTUAL PROPERTY RIGHT.
                    FIREEYE MAKES NO WARRANTY AS TO THE RELIABILITY, USEFULNESS, COMPLETENESS, TIMELINESS, QUALITY,
                    SECURITY, SUITABILITY, AVAILABILITY OR ACCURACY OF ANY MARKET PRODUCT OR THAT THE MARKET PRODUCTS
                    WILL BE ERROR FREE OR OPERATE WITHOUT INTERRUPTION. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES
                    WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </li>
                  <li>
                    <em>Indemnification</em>. To the maximum extent permitted by applicable laws, you agree to defend,
                    indemnify and hold us, our affiliates and licensors and their respective officers, directors,
                    employees, contractors, agents, licensors and suppliers harmless from and against any claims,
                    liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable
                    attorneys&#39; fees and other costs of defense) resulting from your violation of these Terms of Use,
                    your violation of the rights of a third party (including any Publisher), or your use of the Market
                    or the Market Products other than as permitted herein. We will be entitled, at our sole expense, to
                    participate in the defense and settlement of the claim or action with counsel of our choosing. You
                    may not settle any claims without our prior written consent (which will not be unreasonably
                    withheld).
                  </li>
                  <li>
                    <em>LIMITATION OF LIABILITY</em>. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                    SHALL FIREEYE OR ITS SUPPLIERS/LICENSORS (INCLUDING PUBLISHERS) BE LIABLE FOR ANY DAMAGES
                    WHATSOEVER, INCLUDING BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF PROFITS, GOODWILL OR BUSINESS, LOSS OR
                    CORRUPTION OF DATA, OR COSTS TO PROCURE SUBTITUTE GOODS OR SERVICES, HOWEVER CAUSED, WHETHER IN AN
                    ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION RESULTING FROM USE OF, OR UNAVAILABILITY OF,
                    THE MARKET OR THE MARKET PRODUCTS OR ARISING OUT OF THE USE OR PERFORMANCE OF THE MATERIALS OR
                    MARKET PRODUCTS AVAILABLE IN THE MARKET, REGARDLESS OF WHETHER FIREEYE, ANY PUBLISHER, OR AN
                    AUTHORIZED FIREEYE REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE
                    LIMITATIONS WILL APPLY EVEN IF THE REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE.
                  </li>
                  <li>
                    <em>Compliance with Laws; Reservation of Rights; Export</em>. You will use the Market and Market
                    Products in compliance with all applicable laws, rules, regulations and guidelines. Except for the
                    rights explicitly granted to you in these Terms of Use and in the EULA for each Market Product, all
                    right, title and interest in the Market and the Market Products are reserved and retained by their
                    respective providers, Publishers, licensors and rights holders. You do not acquire any ownership
                    rights in the Market or the Market Products as a result of using the Market or downloading,
                    installing or using the Market Products. You may not use or otherwise export or re-export the Market
                    Products except as authorized by United States law and the laws of the jurisdiction in which the
                    Market Products originated or were obtained. In particular, but without limitation, the Market
                    Products may not be exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone
                    on the U.S Treasury Department’s list of Specially Designated Nationals or the U.S. Department of
                    Commerce Denied Person’s List or Entity List. If you access the Market from outside the United
                    States, you do so on your own initiative and are responsible for compliance with local laws. In
                    addition, you are responsible for complying with any local laws which may impact your right to
                    import, export, download or use the Market Products. Access to the Market from territories where the
                    Market or any of its services or products are illegal is prohibited.
                  </li>
                  <li>
                    <em>U.S. Government Users</em>. If you are a U.S. Government end user, the Publisher is providing
                    the Market Products to you as a &quot;Commercial Item&quot; as that term is defined in the U.S. Code
                    of Federal Regulations (see 48 C.F.R. § 2.101), and the rights granted to you by the Publisher for
                    the Market Products are the same as the rights the Publisher customarily grant to others under the
                    applicable EULA.
                  </li>
                  <li>
                    <em>Termination</em>. Your rights under these Terms of Use will automatically terminate without
                    notice if you fail to comply with any of its terms, if you cease to have a valid license for a
                    FireEye Product or violate the term of any EULA. Further, FireEye may terminate these Terms of Use
                    and the licenses granted herein at any time and for any or no reason by giving you written notice.
                    In case of any such termination, you must cease all use of the Market and the Market Products, and
                    FireEye may immediately revoke your access to the Market, and if applicable, without refund of any
                    fees paid.
                  </li>
                  <li>
                    <em>Governing Law; Jurisdiction; Jury Trial Waiver</em>. These Terms of Use are governed by the laws
                    of the State of California, without regard to its conflicts of laws rules, and each party
                    irrevocably and unconditionally agrees to submit to the exclusive jurisdiction of the courts located
                    in the Santa Clara County in the State of California. You agree to waive all defenses of lack of
                    personal jurisdiction and forum non- conveniens, irrevocably waive your right to a jury trial and
                    agree that process may be served in a manner authorized by applicable law or court rule. Except in
                    circumstances where a party seeks urgent injunctive relief, before commencing any court proceedings,
                    if any dispute arises under these Terms of Use, the parties will negotiate in good faith to resolve
                    the dispute. The terms of the United Nations Convention on Contracts for the Sale of Goods do not
                    apply to these Terms of Use.
                  </li>
                  <li>
                    <em>DMCA Process</em>. If you believe that any content in the Market violates your copyright, please
                    notify FireEye’s copyright agent in writing. The contact information for FireEye’s copyright agent
                    is at the bottom of this section. FireEye cannot take any action unless you give us all the
                    information required by applicable law. In order for FireEye to take action, you must include the
                    following in your notice:
                    <ul>
                      <li>provide your physical or electronic signature;</li>
                      <li>identify the copyrighted work that you believe is being infringed;</li>
                      <li>
                        identify the item that you think is infringing and include sufficient information about where
                        the material is located (including which website) so that FireEye can find it;
                      </li>
                      <li>provide FireEye with a way to contact you (such as address, telephone number, or email);</li>
                      <li>
                        provide a statement that you believe in good faith that the item identified as infringing is not
                        authorized by the copyright owner, its agent, or the law to be used by FireEye; and
                      </li>
                      <li>
                        provide a statement that the information you provide in the notice is accurate, and that (under
                        penalty of perjury), you are authorized to act on behalf of the copyright owner whose work is
                        being infringed.
                      </li>
                    </ul>
                    <p>Here is the contact information for FireEye’s copyright agent:</p>
                    <p className="terms-of-use__container--address">
                      Copyright Enforcement
                      <br />
                      Attention: Legal Department
                      <br />
                      FireEye, Inc.
                      <br />
                      <a href="mailto:copyrights@FireEye.com">copyrights@FireEye.com</a>
                    </p>
                  </li>
                  <li>
                    <em>Contact Information</em>. For communications concerning these Terms of Use (other than DMCA
                    notices, which you must send in accordance with the procedures above), please write to{' '}
                    <a href="mailto:copyrights@FireEye.com">copyrights@FireEye.com</a>.
                  </li>
                  <li>
                    <em>Assignment</em>. You may not assign or transfer the agreement formed by your agreeing to these
                    Terms of Use. FireEye may freely assign, transfer, and delegate its rights and obligations under
                    these Terms of Use. You acknowledge and agree that FireEye’s affiliates, contractors, Publishers,
                    and service providers may exercise all rights of FireEye under these Terms of Use.
                  </li>
                  <li>
                    <em>Weblinks</em>. Certain links provided herein permit you to leave this site and enter non-
                    FireEye sites. These linked sites are not under FireEye‘s control. FireEye is not responsible for
                    the contents of any linked site or any changes or updates to such sites. FireEye is providing these
                    links to you only as a convenience. The inclusion of any link does not imply endorsement by FireEye
                    of any linked site. FIREEYE’S PUBLICATION OF INFORMATION REGARDING THIRD-PARTY PRODUCTS OR SERVICES
                    DOES NOT CONSTITUTE AN ENDORSEMENT REGARDING THE SUITABILITY OF SUCH PRODUCTS OR SERVICES OR A
                    WARRANTY, REPRESENTATION OR ENDORSEMENT OF SUCH PRODUCTS OR SERVICES, EITHER ALONE OR IN COMBINATION
                    WITH ANY FireEye PRODUCT OR SERVICE. Linking to this site is subject to FireEye policies. If you
                    would like to link to FireEye’s web site, please contact us at:{' '}
                    <a href="mailto:webmaster@FireEye.com">webmaster@FireEye.com</a>
                  </li>
                  <li>
                    <em>Feedback &amp; Suggestions</em>. Direct feedback, comments, suggestions, requests for support
                    and other communications relating to the Market can be sent to:{' '}
                    <a href="mailto:market@fireeye.com">market@FireEye.com</a>. FireEye shall have a perpetual,
                    irrevocable, royalty‐free, fully‐ paid, sublicensable, transferable, non‐exclusive, worldwide
                    license to make, use, sell, offer for sale, import or otherwise use or commercially exploit for any
                    purpose, any feedback and suggestions for improvements or enhancements to the Market and the Market
                    Products (“Feedback”) without obligation of confidentiality or otherwise.
                  </li>
                  <li>
                    <em>Copyright to Market</em>. All content included on the Market website, such as text, graphics,
                    logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the
                    property of FireEye or its content suppliers and protected by United States and international
                    copyright laws. The compilation of all content on the Market website is the exclusive property of
                    FireEye and protected by U.S. and international copyright laws. The Market website or any portion of
                    the Market website may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise
                    exploited for any commercial purpose without express written consent of FireEye. You may not frame
                    or utilize framing techniques to enclose any trademark, logo, or other proprietary information
                    (including images, text, page layout, or form) of the Market website without express written consent
                    of FireEye. You may not use any meta tags or any other “hidden text” utilizing FireEye’s name or
                    trademarks without the express written consent of FireEye. Any unauthorized use terminates the
                    permissions and licenses granted under these Terms of Use by FireEye.
                  </li>
                  <li>
                    <em>Miscellaneous</em>. No waiver of these Terms of Use by us shall be deemed a further or
                    continuing waiver of such term or condition or any other term or condition, and any failure of ours
                    to assert a right or provision under these Terms of Use shall not constitute a waiver of such right
                    or provision. If any provision of these Terms of Use is held by a court of competent jurisdiction to
                    be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited
                    to the minimum extent such that the remaining provisions of the Terms of Use will continue in full
                    force and effect. There are no third‐party beneficiaries to these Terms of Use. These Terms of Use
                    (including any applicable EULA, etc.) and our Privacy Policy constitute the sole and entire
                    agreement between you and us with respect to the Market and Market Products, except as expressly set
                    forth herein, and supersede all prior and contemporaneous understandings, agreements,
                    representations and warranties, both written and oral, with respect to the Market Website. For the
                    avoidance of doubt, these Terms of Use do not supersede any written agreement between you and
                    FireEye for the use of, or the right to which you have been granted the right to use or access, a
                    FireEye Product or Service.
                  </li>
                </ol>
              </li>
            </ol>
            <em>Last Updated</em>: October 2, 2018
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsOfUse;
