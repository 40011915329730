import gql from 'graphql-tag';

const ASSENT_TO_TERMS_MUTATION = gql`
  mutation AssentToTerms($id: ID!, $assent: Boolean!) {
    assentToAppTerms(id: $id, assent: $assent) @client {
      id
      assent
    }
  }
`;

export default ASSENT_TO_TERMS_MUTATION;
