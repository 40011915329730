import gql from 'graphql-tag';

//TODO: Only name and email are currently exposed via the id token
// If you try to request claims that don't exist in the token, this query will fail
const CONTACT_US_QUERY = gql`
  query IdentityForContactUs {
    authentication @client {
      isAuthenticated
      identity {
        email
        name
      }
    }
  }
`;

export default CONTACT_US_QUERY;
