import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, Mutation } from 'react-apollo';
import CONTACT_US_QUERY from './ContactUs.query';
import CONTACT_US_MUTATION from './ContactUs.mutation';
import TOGGLE_MODAL from '../ToggleModal.mutation';
import { CONTACT_CONFIRMATION } from '../modalTypes';
import Analytics from '../../../Analytics';
import CollectData from './CollectData';
import './ContactUs.css';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      company: '',
      role: '',
      phone: '',
      email: '',
      message: '',
      isIdentityPopulated: false,
      errors: []
    };
  }

  handleChange = ({ target: { id, name, value } }) => {
    const { errors } = this.state;
    const filteredErrors = errors.filter(e => e.field !== id);
    this.setState({ [name]: value, errors: filteredErrors });
  };

  onCompleted = (confirmReceipt, data) => {
    if (data.contactUs.errors.length === 0) {
      Analytics.record('ContactUs');
      confirmReceipt();
    } else {
      this.setState({ errors: data.contactUs.errors });
      Analytics.record('ContactUsValidationError');
    }
  };

  onCancel = () => {
    this.setState({ errors: [] });
    Analytics.record('ContactUsAbandoned');
  };

  onError = error => {
    Analytics.record('ContactUsError');
    window.alert(error);
  };

  static getDerivedStateFromProps(props, state) {
    if (state.isIdentityPopulated) return null;
    if (props.data && props.data.authentication && props.data.authentication.identity) {
      let { name, email, phone = '', company = '', role = '' } = props.data.authentication.identity;
      if (!props.data.authentication.isAuthenticated) email = '';
      return { name, email, phone, company, role, isIdentityPopulated: true };
    }
    return null;
  }

  render() {
    const { errors, isIdentityPopulated, message, ...contact } = this.state;
    const { isOpen } = this.props;
    return (
      <Mutation mutation={TOGGLE_MODAL} variables={{ modal: CONTACT_CONFIRMATION }}>
        {confirmReceipt => (
          <Mutation
            mutation={CONTACT_US_MUTATION}
            variables={{ contactAndMessage: { message, ...contact } }}
            onCompleted={data => this.onCompleted(confirmReceipt, data)}
            onError={this.onError}
          >
            {(onSubmit, { loading }) => {
              return (
                <CollectData
                  contact={contact}
                  message={message}
                  onChange={this.handleChange}
                  onSubmit={onSubmit}
                  onCancel={this.onCancel}
                  loading={loading}
                  validationErrors={errors}
                  isOpen={isOpen}
                />
              );
            }}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

ContactUs.propTypes = {
  isOpen: PropTypes.bool
};

export default graphql(CONTACT_US_QUERY)(ContactUs);
