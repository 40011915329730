import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CollapseIcon.css';

class CollapseIcon extends Component {
  state = {
    isOpen: this.props.isOpen,
    disabled: this.props.disabled
  };

  toggle = () => {
    const { disabled } = this.state;
    if (disabled) return;
    this.setState({ isOpen: !this.state.isOpen });
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.disabled !== state.disabled) newState.disabled = props.disabled;
    if (props.isOpen !== state.isOpen) newState.isOpen = props.isOpen;
    if (Object.keys(newState).length > 0) return newState;
    return null;
  }

  render() {
    const { isOpen, disabled } = this.state;
    const { className, icon, ...iconProps } = this.props;
    delete iconProps.isOpen;
    delete iconProps.disabled;

    return (
      <span
        className={classNames(className, 'collapse-icon', {
          'collapse-icon--open': isOpen,
          'collapse-icon--closed': !isOpen,
          pointer: !disabled,
          disabled
        })}
      >
        <FontAwesomeIcon className="collapse-icon__icon" icon={icon} onClick={this.toggle} {...iconProps} />
      </span>
    );
  }
}

CollapseIcon.defaultProps = {
  icon: ['fal', 'chevron-circle-down'],
  isOpen: true,
  disabled: false
};

CollapseIcon.propTypes = {
  icon: PropTypes.array,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default CollapseIcon;
