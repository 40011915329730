import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssentToTerms from '../../AssentToTerms/AssentToTerms';
import Analytics from '../../../Analytics';

class AuthenticatedDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasBeenDownloaded: props.hasBeenDownloaded
    };
  }

  onSubmit = () => {
    const { download } = this.props;
    this.setState({ loading: true });
    download()
      .then(result => this.onDownloadCompleted(result.data))
      .catch(this.onDownloadError);
  };

  onDownloadCompleted = data => {
    this.setState({ loading: false });
    if (data.requestDownload.errors.length === 0) {
      this.onDownloadSuccess();
      window.location.assign(data.requestDownload.download.url);
    } else {
      const { app } = this.props;
      Analytics.record('DownloadValidationError', {
        authenticated: 'true',
        appId: app.id,
        appName: app.name
      });
      window.alert(data.requestDownload.errors.map(e => e.messages.join('\n')).join('\n'));
    }
  };
  onDownloadSuccess = () => {
    const { app, subscribe } = this.props;
    const { hasBeenDownloaded } = this.state;
    if (!hasBeenDownloaded) {
      subscribe();
      Analytics.record('Subscribe', { appId: this.props.app.id });
      this.setState({ hasBeenDownloaded: true });
    }
    Analytics.record('Download', { authenticated: 'true', appId: app.id, appName: app.name });
  };

  onDownloadError = error => {
    this.setState({ loading: false });
    const { app } = this.props;
    Analytics.record('DownloadError', { authenticated: 'true', appId: app.id, appName: app.name });
    window.alert(error);
  };

  render() {
    const { loading } = this.state;
    const { app, assentedToTerms } = this.props;
    return (
      <AssentToTerms
        app={app}
        assentedToTerms={assentedToTerms}
        onSubmit={this.onSubmit}
        onExternalDownload={this.onDownloadSuccess}
        loading={loading}
      />
    );
  }
}

AuthenticatedDownload.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    downloadInfo: PropTypes.shape({
      firstDownloaded: PropTypes.string,
      lastDownloaded: PropTypes.string
    })
  }),
  assentedToTerms: PropTypes.bool,
  download: PropTypes.func.isRequired,
  hasBeenDownloaded: PropTypes.bool,
  subscribe: PropTypes.func.isRequired
};

AuthenticatedDownload.defaultProps = {
  assentedToTerms: false
};

export default AuthenticatedDownload;
