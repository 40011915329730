import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FeButton } from 'fe-fabric-react';
import './MarkAllRead.css';

const MarkAllRead = ({ children, className, color, onClick, ...buttonProps }) => (
  <FeButton
    className={classNames(className, 'mark-all-read', 'pointer')}
    feStyle={color}
    onClick={onClick}
    {...buttonProps}
  >
    {children}
  </FeButton>
);

MarkAllRead.defaultProps = {
  children: 'Mark All As Read',
  color: 'link'
};

MarkAllRead.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func
};

export default MarkAllRead;
