import gql from 'graphql-tag';

const APPS_FRAGMENT = gql`
  fragment AppsSearchResults on AppsPage {
    apps {
      id
      name
      listing {
        uri
        x1Uri
        x2Uri
      }
      type {
        id
        name
      }
      platforms {
        id
        name
      }
    }
  }
`;

const APP_SEARCH_RESULTS_QUERY = gql`
  query PaginatedAppSearchResults(
    $count: Int
    $start: Int
    $order: SortOrder
    $query: String
    $categories: [ID]
    $platforms: [ID]
    $products: [ID]
    $thirdPartyProducts: [ID]
    $types: [ID]
  ) {
    paginatedApps(
      count: $count
      start: $start
      order: $order
      query: $query
      categories: $categories
      platforms: $platforms
      products: $products
      thirdPartyProducts: $thirdPartyProducts
      types: $types
    ) {
      total
      ...AppsSearchResults
    }
  }
  ${APPS_FRAGMENT}
`;

const JUST_THE_APPS_MAAM = gql`
  query PaginatedAppSearchResults(
    $count: Int
    $start: Int
    $order: SortOrder
    $query: String
    $categories: [ID]
    $platforms: [ID]
    $products: [ID]
    $thirdPartyProducts: [ID]
    $types: [ID]
  ) {
    paginatedApps(
      count: $count
      start: $start
      order: $order
      query: $query
      categories: $categories
      platforms: $platforms
      products: $products
      thirdPartyProducts: $thirdPartyProducts
      types: $types
    ) {
      ...AppsSearchResults
    }
  }
  ${APPS_FRAGMENT}
`;

export { APP_SEARCH_RESULTS_QUERY, JUST_THE_APPS_MAAM };
