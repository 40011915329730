import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { Router, Route, Switch, withRouter } from 'react-router-dom';
import { Security, ImplicitCallback } from './components/Authentication';
import { Helmet } from 'react-helmet';
import SentryErrorBoundary from './components/Sentry/ErrorBoundary';
import withAuth from './components/Authentication/withAuth';
import Analytics from './Analytics';
import OktaLoginRedirect from './components/OktaLoginRedirect/OktaLoginRedirect';
import SecureContent from './containers/SecureContent/SecureContent';
import { NotificationsProvider } from './NotificationsContext';
import { FontAwesomeContext } from 'fe-fabric-react';
import { SVGIcon, SVGStack } from './components/Icon';
import AdminContext from './AdminContext';
import './App.css';

const supportsHistory = 'pushState' in window.history;

class App extends Component {
  constructor(props) {
    super(props);
    this.unlisten = props.history.listen(this.onHistoryChanged);
  }

  onHistoryChanged(location, action) {
    const pageViewAttributes = { action, path: location.pathname, queryString: location.search };
    Analytics.record('_pageView', pageViewAttributes);
  }

  componentDidMount() {
    this.onHistoryChanged(this.props.history.location, 'BEGIN');
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  render() {
    const { client, adminConfig, notificationsClient, auth, history } = this.props;
    return (
      <SentryErrorBoundary auth={auth} history={history}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <AdminContext.Provider value={adminConfig}>
              <NotificationsProvider value={{ client: notificationsClient }}>
                <FontAwesomeContext.Provider value={{ Icon: SVGIcon, Stack: SVGStack }}>
                  <OktaLoginRedirect auth={auth} location={history.location} />
                  <Router history={history} forceRefresh={!supportsHistory}>
                    <Security auth={auth}>
                      <Helmet titleTemplate="%s | FireEye Market" defaultTitle="FireEye Market" />
                      <Switch>
                        <Route path="/implicit/callback" component={withAuth(ImplicitCallback)} />
                        <Route component={withAuth(withRouter(SecureContent))} />
                      </Switch>
                    </Security>
                  </Router>
                </FontAwesomeContext.Provider>
              </NotificationsProvider>
            </AdminContext.Provider>
          </ApolloHooksProvider>
        </ApolloProvider>
      </SentryErrorBoundary>
    );
  }
}

App.propTypes = {
  client: PropTypes.object.isRequired,
  adminConfig: PropTypes.object.isRequired,
  notificationsClient: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default App;
