import React from 'react';
import { Query } from 'react-apollo';
import MODAL_QUERY from './AppModal.query';

// Modal Components
import ContactUs from './ContactUs/ContactUs';
import ContactConfirmation from './ContactConfirmation/ContactConfirmation';

// Modal Types
import * as ModalTypes from './modalTypes';

const AppModal = () => (
  <Query query={MODAL_QUERY}>
    {({ data: { modal } }) => (
      <>
        <ContactUs isOpen={modal === ModalTypes.CONTACT_US} />
        <ContactConfirmation isOpen={modal === ModalTypes.CONTACT_CONFIRMATION} />
      </>
    )}
  </Query>
);

export default AppModal;
