import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { RetryLink } from 'apollo-link-retry';
import { createHttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import defaults from './initialState';
import createResolvers from './resolvers';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './graphql/fragmentTypes.json';
import { ConsoleLogger as Logger } from '@aws-amplify/core';

const createFragmentMatcher = () => new IntrospectionFragmentMatcher({ introspectionQueryResultData });

const createAuthLink = auth =>
  setContext(async (_, { headers }) => {
    const session = await auth.resumeOrStartSession();
    if (!session) {
      await auth.login();
      throw new Error('Unable to start a session');
    }

    const token = await auth.getAccessToken();

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

const createOnErrorLink = logger =>
  onError(({ graphQLErrors, networkError }) => {
    logger.error({ graphQLErrors, networkError });
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        logger.error(`[GraphQL error]: Message ${message}, Location:`, locations, `, Path: ${path}`)
      );
    }
    if (networkError) {
      logger.error(`[Network error]: ${networkError}`);
    }
  });

const createRetryingRequestLink = requestLink => {
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  });
  return ApolloLink.from([retryLink, requestLink]);
};

const createClient = (auth, cache) => {
  const logger = new Logger('Market GraphQL');
  const uri = process.env.REACT_APP_GRAPHQL_URL;
  const requestLink = createHttpLink({ uri });
  const links = [createOnErrorLink(logger), createAuthLink(auth), createRetryingRequestLink(requestLink)];

  if (process.env.REACT_APP_APOLLO_LOG !== undefined) {
    links.unshift(apolloLogger);
  }

  const resolvers = createResolvers(auth);

  const client = new ApolloClient({
    link: ApolloLink.from(links),
    cache,
    resolvers,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      },
      query: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
  cache.writeData({ data: defaults });

  // Grab the state from a global variable injected into the server-generated HTML
  const serializedState = window.__APOLLO_STATE__;

  // Allow the passed state to be garbage-collected
  delete window.__APOLLO_STATE__;

  if (serializedState) client.restore(serializedState);

  return client;
};

export default createClient;
export { createFragmentMatcher, createOnErrorLink };
