import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../../Modal';
import { FeButton } from 'fe-fabric-react';

const ContactConfirmation = ({ isOpen }) => (
  <Modal className="contact-confirmation-modal" size="small" isOpen={isOpen}>
    {({ closeModal }) => {
      return (
        <>
          <ModalHeader
            icon={{ family: 'solid', icon: 'check-circle' }}
            title="Confirmation"
            onCloseClick={closeModal}
          />
          <ModalBody>
            <p>
              Thank you for reaching out. We’ll do our best to follow-up within 2 business days. We truly appreciate
              your feedback and support!
            </p>
          </ModalBody>
          <ModalFooter>
            <FeButton type="button" feStyle="primary" onClick={closeModal}>
              OK
            </FeButton>
          </ModalFooter>
        </>
      );
    }}
  </Modal>
);

ContactConfirmation.propTypes = {
  isOpen: PropTypes.bool
};

export default ContactConfirmation;
