import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AppTileFooter = ({ app, linkTo }) => {
  if (!app.summary) return null;
  return (
    <Link to={linkTo} className="fe-btn fe-btn--primary fe-btn--md tile__button">
      Learn More
    </Link>
  );
};
AppTileFooter.propTypes = {
  app: PropTypes.shape({
    summary: PropTypes.string
  }),
  linkTo: PropTypes.object
};

export default AppTileFooter;
