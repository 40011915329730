import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FeIcon } from 'fe-fabric-react';
import isEqual from 'lodash.isequal';

const iconMap = {
  success: 'check-circle',
};
class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      disabled: props.disabled,
      validation: props.validation,
    };

    this.inputRef = React.createRef();

    this.setValue = this.setValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (nextProps.value !== prevState.value) nextState.value = nextProps.value;
    if (nextProps.disabled !== prevState.disabled) nextState.disabled = nextProps.disabled;
    if (!isEqual(nextProps.validation, prevState.validation)) nextState.validation = nextProps.validation;
    if (Object.keys(nextState).length > 0) return nextState;
    return null;
  }

  handleChange(event) {
    const {
      target: { value },
    } = event;
    if (this.props.onChange) this.props.onChange(event);
    this.setState({ value: value });
  }

  setValue(value) {
    const { current: input } = this.inputRef;
    // See https://github.com/facebook/react/issues/11488#issuecomment-347775628
    const lastValue = input.value;
    input.value = value;
    input._valueTracker.setValue(lastValue);
    input.dispatchEvent(new Event('input', { bubbles: true }));
  }

  render() {
    const {
      id,
      type,
      className,
      placeholder,
      label,
      hint,
      children,
      validation: __validation,
      ...controlProps
    } = this.props;
    const { value, disabled, validation } = this.state;
    const { type: validationType, message: validationMessage } = validation || {};
    const hasValidation = !!validationType;
    const validationIcon = iconMap[validationType] || 'exclamation-circle';

    const inputProps = {
      ...controlProps,
      id,
      className: 'fe-input',
      type,
      value: value || '',
      placeholder,
      disabled,
      onChange: this.handleChange,
      ref: this.inputRef,
    };
    const Input = type === 'textarea' ? 'textarea' : 'input';

    const inputWrapperClassNames = classnames(
      'fe-form__input-wrapper',
      {
        [`fe-form__input-wrapper--has-${validationType}`]: hasValidation,
      },
      className
    );

    const validationWrapperClassName = classnames({
      'fe-form__input-wrapper--has-icon': hasValidation,
    });

    const hintText = hasValidation ? validationMessage : hint;

    return (
      <div className={inputWrapperClassNames}>
        {label && (
          <label htmlFor={id} className="fe-input-label caption">
            {label}
          </label>
        )}
        <div className={validationWrapperClassName}>
          <Input {...inputProps} />
          {hasValidation && (
            <span className="fe-input-icon">
              <FeIcon icon={validationIcon} family="solid" />
            </span>
          )}
        </div>
        {hintText && <span className="fe-input-hint-text">{hintText}</span>}
      </div>
    );
  }
}

TextInput.defaultProps = {
  type: 'text',
};

TextInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'tel', 'email', 'password', 'search', 'url', 'textarea', 'number']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.node,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  validation: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'warning', 'success']).isRequired,
    message: PropTypes.string.isRequired,
  }),
};

export default TextInput;
