import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Error.css';

export const GeneralError = () => (
  <div className="error">
    <FontAwesomeIcon className="error__icon" icon={['fal', 'exclamation-circle']} size="5x" />
    <h1>Sorry, something went wrong.</h1>
    <p>
      Please try again, or reach out to us at <a href="mailto:market@fireeye.com">market@fireeye.com</a> and let us know
      what went wrong.
    </p>
  </div>
);

export const UnauthorizedError = ({ reason }) => (
  <div className="error">
    <FontAwesomeIcon className="error__icon" icon={['fas', 'do-not-enter']} size="5x" />
    <h1>Unauthorized</h1>
    {reason && <p>{reason}</p>}
  </div>
);

UnauthorizedError.propTypes = {
  reason: PropTypes.string
};

const Error = ({ error }) => {
  const { networkError } = error;
  if (networkError && networkError.statusCode === 401)
    return <UnauthorizedError reason="You are not authorized to access this resource at this time." />;
  return <GeneralError />;
};

Error.propTypes = {
  error: PropTypes.shape({
    networkError: PropTypes.shape({
      statusCode: PropTypes.number.isRequired
    })
  })
};

export default Error;
