import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FePanel } from 'fe-fabric-react';
import { Link } from 'react-router-dom';
import noImageAvailable from '../../../assets/images/no-image-available.png';
import './Tile.css';

const VendorTile = ({ data, location }) => {
  const state = location.search.length > 1 ? { returnTo: location } : undefined;
  const linkTo = { pathname: `/vendors/${data.id}`, state };
  const id = `vendor-tile-${data.id}`;
  let imageUrl = noImageAvailable;
  if (data.logo) {
    imageUrl = data.logo.x1Uri
      ? data.logo.x1Uri
      : data.logo.x2Uri
      ? data.logo.x2Uri
      : data.logo.uri
      ? data.logo.uri
      : noImageAvailable;
  }
  const tileTitle = data.partnerType || 'Unknown';
  const panelProps = {
    id,
    label: <span>{tileTitle}</span>,
    className: 'tile vendor-tile tile__title'
  };
  if (data.partnerType === 'Technology Partner') {
    panelProps.iconLabel = { family: 'regular', name: 'handshake' };
  }

  return (
    <FePanel {...panelProps}>
      <Link to={linkTo}>
        <div className="tile__image-container">
          <div className="tile__image" style={{ backgroundImage: `url(${imageUrl})` }} />
        </div>
        <h5 className="tile__summary-title">{data.name}</h5>
      </Link>
    </FePanel>
  );
};

VendorTile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    partnerType: PropTypes.string,
    logo: PropTypes.shape({
      x2Uri: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(VendorTile);
