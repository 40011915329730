import React from 'react';
import PropTypes from 'prop-types';
import { FePillBox } from 'fe-fabric-react';
import './Tag.css';

export const Tag = ({ text, onClick, href, pillboxType }) => (
  <FePillBox
    label={text}
    onClick={({ originalEvent }) => onClick(originalEvent)}
    href={href}
    feType={pillboxType}
    className="tag"
  />
);

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  pillboxType: PropTypes.oneOf(['default', 'clear'])
};

export default Tag;
