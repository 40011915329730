import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const VENDOR_DETAILS = loader('./VendorDetails.graphql');

const options = ({
  match: {
    params: { id }
  }
}) => ({
  variables: { id }
});

const props = ({ data: { loading, vendor, error } }) => ({
  loading,
  vendor,
  error
});

const withVendor = graphql(VENDOR_DETAILS, {
  alias: 'withVendor',
  options,
  props
});

export default withVendor;
export { options, props };
