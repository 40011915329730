import AuthListenerForGraphQL from './authListenerForGraphQL';
import AuthListenerForAnalytics from './authListenerForAnalytics';
import { Hub } from '@aws-amplify/core';

const listenToAuthEventsForGraphQL = cache => {
  const authListener = new AuthListenerForGraphQL({ cache });
  Hub.listen('auth', authListener.onAuthEvent);
  Hub.listen('anon_auth', authListener.onAnonAuthEvent);
};

const listenToAuthEventsForAnalytics = () => {
  const analyticsListener = new AuthListenerForAnalytics();
  Hub.listen('auth', analyticsListener.onAuthEvent);
};

export { listenToAuthEventsForAnalytics, listenToAuthEventsForGraphQL };
