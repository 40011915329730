import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';
import { Tag } from '../Tag/Tag';
import { Row, Col } from 'fe-fabric-react';

const FilterBar = ({ className, filters, onRemoveFilter }) => {
  const filterList = [];
  for (var key in filters) {
    var collection = filters[key];
    if (Array.isArray(collection)) filterList.push(...collection);
  }

  const nextFilterSet = removedFilter => {
    const nextFilters = Object.assign({}, filters);
    for (var key in nextFilters) {
      var collection = nextFilters[key];
      if (Array.isArray(collection)) nextFilters[key] = collection.filter(f => f.id !== removedFilter.id);
    }
    return nextFilters;
  };

  const onClick = removedFilter => {
    const nextFilters = nextFilterSet(removedFilter);
    if (onRemoveFilter) onRemoveFilter(nextFilters);
  };

  return (
    <Row className={className}>
      {filterList.map(filter => (
        <Col xs="auto" key={filter.id}>
          <Tag pillboxType="clear" text={filter.name} key={filter.id} onClick={() => onClick(filter)} />
        </Col>
      ))}
    </Row>
  );
};

FilterBar.propTypes = {
  filters: CustomPropTypes.filterSet,
  onRemoveFilter: PropTypes.func,
  className: PropTypes.string
};

FilterBar.defaultPropTypes = {
  filters: {
    products: [],
    categories: [],
    types: [],
    platforms: []
  }
};

export default FilterBar;
