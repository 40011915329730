import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, FeButton } from 'fe-fabric-react';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../../Modal';
import { TextInput } from '../../Forms';
import { validationFor } from '../../../formValidation';

const CollectData = ({ contact, message, onChange, onSubmit, onCancel, loading, validationErrors, isOpen }) => (
  <Modal size="medium" className="contact-us" isOpen={isOpen}>
    {({ closeModal }) => {
      const onCancelClick = event => {
        if (onCancel) onCancel(event);
        closeModal();
      };
      const onSubmitClick = event => {
        event.preventDefault();
        if (onSubmit) onSubmit(event);
      };

      return (
        <form onSubmit={onSubmitClick}>
          <ModalHeader icon={{ icon: 'envelope', family: 'solid' }} title="Contact Us" onCloseClick={onCancelClick} />
          <ModalBody>
            <p>
              Do you have an idea for a new feature? Do you want to contribute content to the Market or have an idea for
              a new app? Or do you just have a question? Contact us and we&apos;ll typically get back to you within 1-2
              business days. Thanks for your engagement and support!
            </p>
            <p className="contact-us__form-title">Your Information</p>
            <Row>
              <Col>
                <TextInput
                  id="contact.name"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  value={contact.name}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'contact.name')}
                  disabled={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  id="contact.company"
                  name="company"
                  label="Company"
                  placeholder="Company"
                  value={contact.company}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'contact.company')}
                  disabled={loading}
                />
              </Col>
              <Col>
                <TextInput
                  id="contact.role"
                  name="role"
                  label="Role"
                  placeholder="Role"
                  value={contact.role}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'contact.role')}
                  disabled={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  id="contact.phone"
                  name="phone"
                  type="tel"
                  label="Phone Number"
                  placeholder="(212) 222-2222"
                  value={contact.phone}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'contact.phone')}
                  disabled={loading}
                />
              </Col>
              <Col>
                <TextInput
                  id="contact.email"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="email.address@domain.com"
                  value={contact.email}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'contact.email')}
                  disabled={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  id="message"
                  type="textarea"
                  name="message"
                  label="Your Message"
                  placeholder="Your Message"
                  value={message}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'message')}
                  disabled={loading}
                  rows={5}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FeButton type="cancel" feStyle="secondary" onClick={onCancelClick} className="modal__cancel">
              Close
            </FeButton>{' '}
            <FeButton type="submit" feStyle="primary" onClick={onSubmitClick} className="modal__submit">
              Submit
            </FeButton>
          </ModalFooter>
        </form>
      );
    }}
  </Modal>
);

CollectData.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  message: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  validationErrors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    })
  ),
  isOpen: PropTypes.bool
};

CollectData.defaulProps = {
  loading: false,
  validationErrors: [],
  onCancel: () => {}
};

export default CollectData;
