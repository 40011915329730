import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import classnames from 'classnames';
import { FeIcon } from 'fe-fabric-react';

const ModalHeader = ({ icon, title, onCloseClick }) => {
  const className = classnames('fe-modal__header', {
    'fe-modal__header--with-icon': !!icon
  });
  return (
    <header className={className}>
      {(title || icon) && (
        <h5 className="fe-modal__title">
          {icon && <FeIcon {...icon} />} {title}
        </h5>
      )}
      <button className="fe-modal-close" type="button" onClick={onCloseClick} aria-label="Close Modal">
        <FeIcon icon="times" family="light" />
      </button>
    </header>
  );
};
ModalHeader.propTypes = {
  icon: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    family: PropTypes.string
  }),
  title: PropTypes.string,
  onCloseClick: PropTypes.func
};

const ModalBody = ({ children }) => <div className="fe-modal__body">{children}</div>;
ModalBody.propTypes = { children: PropTypes.node.isRequired };

const ModalFooter = ({ children }) => <footer className="fe-modal__footer">{children}</footer>;
ModalFooter.propTypes = { children: PropTypes.node.isRequired };

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: !!props.isOpen
    };
    this.node = React.createRef();
    this.close = this.close.bind(this);
    this.checkKeyDown = this.checkKeyDown.bind(this);
  }

  getFocusableElements(parent, includeAll) {
    var selector = 'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled])';

    if (includeAll) {
      selector += ', [tabindex]:not([disabled])';
    } else {
      selector += ', [tabindex]:not([tabindex="-1"  ]):not([disabled])';
    }

    if (parent) {
      return parent.querySelectorAll(selector);
    } else {
      return document.querySelectorAll(selector);
    }
  }

  trapFocus(event, node, includeAll) {
    var focusableElements = this.getFocusableElements(node, includeAll);
    var length = focusableElements.length;

    if (!length) {
      return;
    }

    var elements = Object.assign([], focusableElements);
    var index = elements.findIndex(function(el) {
      return document.activeElement === el;
    });

    if (index < 0) {
      focusableElements[0].focus();
      event.preventDefault();
    } else if ((event.shiftKey && event.keyCode === 9) || event.keyCode === 38) {
      //Shift+Tab || ArrowUp
      index === 0 ? focusableElements[length - 1].focus() : focusableElements[index - 1].focus();
      event.preventDefault();
    } else if (event.keyCode === 9 || event.keyCode === 40) {
      //Tab || ArrowDown
      index === length - 1 ? focusableElements[0].focus() : focusableElements[index + 1].focus();
      event.preventDefault();
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen !== prevState.isOpen) return { isOpen: nextProps.isOpen };
    return null;
  }

  close() {
    if (!this.state.isOpen) return;
    const { closeModal } = this.props;
    this.setState({ isOpen: false }, closeModal);
  }

  checkKeyDown(event) {
    if (!this.state.isOpen) {
      return;
    }
    if (event.keyCode === 27) {
      //Escape
      this.close();
    } else if (event.keyCode === 9) {
      //Tab
      this.trapFocus(event, this.node);
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.checkKeyDown);
    this.assertModalOpenClassApplied();
  }

  componentWillUnmount() {
    this.assertModalOpenClassApplied();
    document.removeEventListener('keydown', this.checkKeyDown);
  }

  componentDidUpdate() {
    this.assertModalOpenClassApplied();
  }

  assertModalOpenClassApplied() {
    const openModals = document.querySelectorAll('.fe-modal');
    if (openModals.length > 0) {
      document.body.classList.add('fe-modal-open');
    } else {
      document.body.classList.remove('fe-modal-open');
    }
  }

  render() {
    if (!this.state.isOpen) return null;
    const { size, children } = this.props;
    const className = classnames('fe-modal__dialog', `fe-modal__dialog--${size}`, this.props.className);
    return (
      <Portal>
        <div className="fe-modal">
          <div className={className} role="dialog" aria-modal>
            <aside ref={node => (this.node = node)} className="fe-modal__content">
              {children({ closeModal: this.close })}
            </aside>
          </div>
        </div>
      </Portal>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullscreen']),
  className: PropTypes.string
};

Modal.defaultProps = {
  size: 'small'
};

export default Modal;
export { ModalHeader, ModalBody, ModalFooter };
