import { graphql } from 'react-apollo';
import MODAL_QUERY from '../../components/AppModal/AppModal.query';
import { DOWNLOAD_APP } from '../../components/AppModal/modalTypes';

const props = ({ data: { modal } }) => ({
  isModalOpen: modal === DOWNLOAD_APP
});

const withDownloadModal = graphql(MODAL_QUERY, {
  alias: 'withDownloadModal',
  props
});

export default withDownloadModal;
export { props };
