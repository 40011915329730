import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { singularize } from 'inflected';
import { FePanel } from 'fe-fabric-react';
import AppTileBody from './AppTileBody';
import AppTileFooter from './AppTileFooter';
import './Tile.css';

function iconLabel(app) {
  if (app.platforms.length > 1) return { family: 'regular', name: 'clone' };
  let iconLabel = { family: 'regular', name: 'file' };
  const platform = app.platforms[0];
  if (platform) {
    const name = platform.name.toLowerCase();
    switch (name) {
      case 'macos':
        iconLabel = { family: 'brand', name: 'app-store' };
        break;
      case 'ios':
        iconLabel = { family: 'brand', name: 'app-store-ios' };
        break;
      case 'windows':
      case 'python':
      case 'android':
      case 'linux':
      case 'chrome':
      case 'firefox':
        iconLabel = { family: 'brand', name };
        break;
      default:
        break;
    }
  }
  return iconLabel;
}

const AppTile = ({ data, location }) => {
  const state = location.search.length > 1 ? { returnTo: location } : undefined;
  const linkTo = { pathname: `/apps/${data.id}`, state };

  let appType = singularize(data.type.name);
  if (appType === 'Open-Source Software') appType = 'Open-Source';

  const id = `app-tile-${data.id}`;
  const classNames = classnames('tile', 'tile__title', {
    'tile__title--without-icons': data.platforms.length === 0
  });

  return (
    <FePanel id={id} iconLabel={iconLabel(data)} label={<span>{appType}</span>} className={classNames}>
      <AppTileBody app={data} linkTo={linkTo} />
      <AppTileFooter app={data} linkTo={linkTo} />
    </FePanel>
  );
};

AppTile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    summary: PropTypes.string,
    listing: PropTypes.shape({
      x2Uri: PropTypes.string
    }),
    type: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    platforms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(AppTile);
