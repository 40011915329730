import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TileList from '../../TileList/TileList';
import { FeBadge } from 'fe-fabric-react';
import './CompatibleApps.css';

const CompatibleApps = ({
  name,
  thirdPartyProducts = [],
  compatibleApps: { apps, total } = { apps: [], total: 0 },
  loading
}) => (
  <TileList tiles={apps} showFourTilesPerRow noWrap loading={loading} className="vendor-compatible-apps">
    <h2 className="vendor-compatible-apps__header">
      Market Apps {!loading && <FeBadge label={String(total)} max={99} />}
    </h2>
    {total > apps.length && (
      <Link
        to={{ pathname: '/apps', search: `?${thirdPartyProducts.map(t => `thirdPartyProducts=${t.id}`).join('&')}` }}
        className="vendor-compatible-apps__by-developer fe-btn fe-btn--md fe-btn--link"
      >
        All {name} Apps
      </Link>
    )}
  </TileList>
);

CompatibleApps.displayName = 'CompatibleApps';
CompatibleApps.propTypes = {
  name: PropTypes.string.isRequired,
  thirdPartyProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  ),
  compatibleApps: PropTypes.shape({
    total: PropTypes.number.isRequired,
    apps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        summary: PropTypes.string,
        listing: PropTypes.shape({
          x2Uri: PropTypes.string
        }),
        type: PropTypes.shape({
          name: PropTypes.string.isRequired
        }),
        platforms: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired
          })
        ).isRequired
      })
    )
  }),
  loading: PropTypes.bool
};

export default CompatibleApps;
