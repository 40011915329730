import { compose } from 'react-apollo';
import BroadcastSettings from './BroadcastSettings';
import withNotificationsClient from '../../../../withNotificationsClient';
import { listBroadcastOptOuts } from './listBroadcastOptOuts';

const WrappedBroadcastSettings = compose(
  withNotificationsClient,
  listBroadcastOptOuts
)(BroadcastSettings);
WrappedBroadcastSettings.displayName = 'BroadcastSettingsWithOptOuts';
export default WrappedBroadcastSettings;
