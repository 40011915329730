import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeButton } from 'fe-fabric-react';
import CustomPropTypes from '../../../customPropTypes';

const EndpointTechPreviewDownload = ({ app, requirement }) => {
  const { name } = app;
  const { description, email } = requirement;
  const subject = encodeURIComponent(`Tech Preview Request: ${name}`);
  const url = `${window.location.origin}/apps/${app.id}`;
  const body = encodeURIComponent(`Requesting access to ${name} at ${url}`);
  return (
    <>
      <p className="no-download">
        <FontAwesomeIcon icon={['fas', 'do-not-enter']} className="unauthorized" title="Auththorization Required" />
        {description} Please contact <a href={`mailto:${email}?subject=${subject}&body=${body}`}>{email}</a> to request
        access.
      </p>
      <FeButton className="no-download__button" feStyle="secondary" disabled>
        <FontAwesomeIcon icon={['fas', 'download']} /> Download
      </FeButton>
    </>
  );
};

EndpointTechPreviewDownload.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  requirement: CustomPropTypes.downloadRequirement.isRequired
};

export default EndpointTechPreviewDownload;
