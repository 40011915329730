import React from 'react';
import PropTypes from 'prop-types';
import Error from '../Error/Error';
import TileList from '../TileList/TileList';

const NewAndNoteworthyApps = props => {
  const { error, tag: Tag, ...rest } = props;
  if (rest.apps) {
    rest.tiles = rest.apps;
    delete rest.apps;
  }
  if (error) return <Error error={error} />;
  return (
    <TileList {...rest} className="new-and-noteworthy">
      <Tag>New & Noteworthy</Tag>
    </TileList>
  );
};

NewAndNoteworthyApps.displayName = 'NewAndNoteworthyApps';
NewAndNoteworthyApps.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  tiles: PropTypes.arrayOf(PropTypes.object),
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
};
NewAndNoteworthyApps.defaultProps = {
  tag: 'h1'
};
export default NewAndNoteworthyApps;
