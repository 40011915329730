import React from 'react';
import Analytics from '../../Analytics';
import Settings from '../../components/Notifications/Settings';

class NotificationSettings extends React.Component {
  componentDidMount = () => {
    Analytics.record('ViewNotificationSettings');
  };

  render() {
    return <Settings />;
  }
}

export default NotificationSettings;
