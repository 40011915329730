import { graphql } from 'react-apollo';
import DOWNLOAD_APP_MUTATION from './DownloadApp.mutation';
import DownloadApp from './DownloadApp';

function createRequestDownload(mutate, appId, assentToTerms) {
  return contactInfo => {
    const variables = {
      downloadRequest: { appId, assentToTerms, contactInfo }
    };
    return mutate({ variables });
  };
}

const withDownload = graphql(DOWNLOAD_APP_MUTATION, {
  alias: 'withDownload',
  props: ({
    mutate,
    ownProps: {
      app: { id },
      assentToTerms
    }
  }) => ({ requestDownload: createRequestDownload(mutate, id, assentToTerms) })
});

export default withDownload(DownloadApp);
export { createRequestDownload };
