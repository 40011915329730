import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const TwitterButton = ({ text }) => {
  const qs = {
    text,
    url: window.location.href,
    related: 'fireeye'
  };
  return (
    <a
      className="fe-btn fe-btn--link fe-btn--md"
      href={`https://twitter.com/intent/tweet?${queryString.stringify(qs)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth /> Twitter
    </a>
  );
};

TwitterButton.propTypes = {
  text: PropTypes.string.isRequired
};

export default TwitterButton;
