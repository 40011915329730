import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Hero from '../../components/Hero/Hero';
import BackToHomeLink from '../../components/BackToHomeLink/BackToHomeLink';
import Tabs from '../../components/Tabs/Tabs';
import { Container, Row, Col, FeIcon } from 'fe-fabric-react';
import { withRouter, Route, Link } from 'react-router-dom';
import ViewAllNotifications from './ViewAllNotifications';
import NotificationSettings from './NotificationSettings';
import './NotificationCenter.css';

const navList = [
  {
    label: 'Notifications',
    route: '/notifications',
    icon: 'bell',
    iconFamily: 'regular'
  },
  {
    label: 'Settings',
    route: '/notifications/settings',
    icon: 'cog',
    iconFamily: 'regular'
  }
];

const NotificationCenter = ({ match }) => {
  let activeIndex;
  if (match) activeIndex = navList.findIndex(entry => entry.route === match.url);
  return (
    <div className="app-content notification-center">
      <Helmet title="Notification Center" />
      <Hero title="Notification Center">
        <BackToHomeLink />
      </Hero>
      <Tabs orientation="horizontal" activeIndex={activeIndex}>
        {navList.map(({ label, route, icon, iconFamily }, index) => (
          <Link key={index} to={route} className="fe-btn fe-btn--link">
            <FeIcon icon={icon} family={iconFamily} /> {label}
          </Link>
        ))}
      </Tabs>
      <Container className="notification-center__container" fluid>
        <Row className="notification-center__container--content">
          <Col xs={12} lg={{ size: 6, offset: 3 }}>
            <Route exact path="/notifications" component={ViewAllNotifications} />
            <Route exact path="/notifications/settings" component={NotificationSettings} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

NotificationCenter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

const NotificationCenterWitHRouter = withRouter(NotificationCenter);

NotificationCenterWitHRouter.displayName = 'NotificationCenterWithRouter';

export default NotificationCenterWitHRouter;
