import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, FeButton } from 'fe-fabric-react';
import Header from '../Header/Header';
import Hero from '../Hero/Hero';
import Footer from '../Footer/Footer';
import './ErrorBoundary.css';

class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, user: null };
    this.getUser = this.getUser.bind(this);
    this.promptForContext = this.promptForContext.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  async getUser() {
    const { auth } = this.props;
    const isAnonymous = await auth.isAnonymous();
    if (isAnonymous) return;
    const { sub, name, email } = await auth.getUser();
    const user = { id: sub, name, email };
    return user;
  }

  async componentDidMount() {
    const user = await this.getUser();
    if (user) this.setState({ user });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!prevState.user) {
      const user = await this.getUser();
      if (user) this.setState({ user });
    }
  }

  componentDidCatch(error, errorInfo) {
    const { user } = this.state;
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      if (user) {
        scope.setUser({
          id: user.sub,
          email: user.email
        });
      }
      Sentry.captureException(error);
    });
  }

  promptForContext() {
    const { user } = this.state;
    if (user) {
      Sentry.showReportDialog({ user });
    } else {
      Sentry.showReportDialog();
    }
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <Container className="app-container">
          <Header
            auth={this.props.auth}
            history={this.props.history}
            showNotifications={false}
            showAuth={false}
            showNav={false}
          />
          <div className="app-content app-error">
            <Hero title="Application Error" />
            <Container className="app-content__container" fluid>
              <div className="error">
                <FontAwesomeIcon className="error__icon" icon={['fal', 'exclamation-circle']} size="5x" />
                <h1>Sorry, something went wrong.</h1>
                <p>
                  Please try again, or{' '}
                  <FeButton onClick={this.promptForContext} className="inline" feStyle="link">
                    provide feedback
                  </FeButton>{' '}
                  to give us more context around what went wrong.
                </p>
              </div>
            </Container>
          </div>
          <Footer showTermsOfUse={false} showFAQ={false} showFeedback={false} />
        </Container>
      );
    }
    return this.props.children;
  }
}

SentryErrorBoundary.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    getUser: PropTypes.func.isRequired,
    isAnonymous: PropTypes.func.isRequired
  }).isRequired
};

export default SentryErrorBoundary;
