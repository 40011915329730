import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Hub } from '@aws-amplify/core';
import Loading from '../Loading/Loading';
import { UnauthorizedError } from '../Error/Error';

class ImplicitCallback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: null,
      error: null
    };

    this.onSignIn = this.onSignIn.bind(this);
  }

  componentDidMount() {
    Hub.listen('auth', this.onSignIn);
    this.props.auth.resetSession();
  }

  componentWillUnmount() {
    Hub.remove('auth', this.onSignIn);
  }

  onSignIn({ payload: { event, data } }) {
    switch (event) {
      case 'signIn':
        this.setState({ authenticated: true, user: data, error: undefined });
        break;
      case 'signIn_failure':
        this.setState({ authenticated: false, user: undefined, error: data });
        break;
      default:
        break;
    }
  }

  render() {
    const { authenticated, error } = this.state;
    if (authenticated === null) {
      return <Loading />;
    }

    if (authenticated) {
      const referrerKey = 'secureRouterReferrerPath';
      const location = JSON.parse(localStorage.getItem(referrerKey) || '{ "pathname": "/" }');
      localStorage.removeItem(referrerKey);
      return <Redirect to={location} push />;
    }

    return <UnauthorizedError reason={error} />;
  }
}

ImplicitCallback.propTypes = {
  auth: PropTypes.shape({
    resetSession: PropTypes.func.isRequired
  }).isRequired
};

export default ImplicitCallback;
