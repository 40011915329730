import React from 'react';
import PropTypes from 'prop-types';
import Stream from '../../components/Notifications/Stream';
import Analytics from '../../Analytics';
import goToNotification from '../../goToNotification';

class ViewAllNotifications extends React.Component {
  componentDidMount() {
    Analytics.record('ViewAllNotifications');
  }

  viewNotification = (notification, event) => {
    if (event && event.preventDefault) event.preventDefault();
    goToNotification(notification, window.location, this.props.history);
  };

  render() {
    return (
      <Stream viewNotification={this.viewNotification} titleClassName="view-all-notifications__container--title" />
    );
  }
}

ViewAllNotifications.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default ViewAllNotifications;
