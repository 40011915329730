import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import { FeListItemGroup } from 'fe-fabric-react';
import Popover from '../Popover';
import './TypeaheadInput.css';

const defaultFilterList = (list, value) => {
  if (String(value).trim().length === 0) return [];
  const forValue = new RegExp(`${value}`, 'i');
  return list.filter(item => item.search(forValue) >= 0).map(item => ({ label: item }));
};

class TypeaheadInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      isActive: false,
      filteredList: []
    };
    this.ref = React.createRef();

    this.deactivate = this.deactivate.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.filterList = this.filterList.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) return { value: props.value };
    return null;
  }

  deactivate() {
    this.setState({ isActive: false });
  }

  handleFocus() {
    const { isActive } = this.state;
    if (!isActive) this.setState({ isActive: true });
  }

  handleBlur() {
    const { isActive } = this.state;
    if (isActive) this.setState({ isActive: false });
  }

  filterList(event) {
    const {
      target: { value }
    } = event;
    const { list, onFilterList, onChange } = this.props;
    const filteredList = onFilterList(list, value);
    if (onChange) onChange(event);
    this.setState({ value, filteredList });
  }

  onItemClick({ itemIndex }) {
    const { filteredList } = this.state;
    const selectedItem = filteredList[itemIndex];
    const { current: textInput } = this.ref;
    if (selectedItem) textInput.setValue(selectedItem.label);
    else textInput.setValue('');
    this.deactivate();
  }

  render() {
    const { isActive, filteredList, value } = this.state;
    const { list, onFilterList, placement, ...inputProps } = this.props;
    const trigger = () => (
      <TextInput
        {...inputProps}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.filterList}
        ref={this.ref}
        value={value}
      />
    );
    const showPopover = isActive && filteredList.length > 0;
    return (
      <Popover
        trigger={trigger}
        isOpen={showPopover}
        placement={placement}
        className="typeahead__popover"
        modifiers={{ offset: { offset: '0, -23' } }}
      >
        <FeListItemGroup list={filteredList} onItemClick={this.onItemClick} feStyle="dropdown" textHighlight={value} />
      </Popover>
    );
  }
}

TypeaheadInput.defaultProps = {
  value: '',
  list: [],
  onFilterList: defaultFilterList
};

TypeaheadInput.propTypes = {
  list: PropTypes.array,
  value: PropTypes.string,
  onFilterList: PropTypes.func,
  onChange: PropTypes.func,
  placement: PropTypes.oneOf([
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end'
  ])
};

export default TypeaheadInput;
export { defaultFilterList };
