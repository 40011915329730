import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../../Modal';
import { TextInput, TypeaheadInput } from '../../Forms';
import { Col, Row, FeButton } from 'fe-fabric-react';
import { validationFor } from '../../../formValidation';
import { countries } from './countries';

const CollectData = ({ contact, onChange, onSubmit, onCancel, onClear, loading, validationErrors, isOpen }) => (
  <Modal className="download-app" isOpen={isOpen} size="medium">
    {({ closeModal }) => {
      const onCancelClick = event => {
        if (onCancel) onCancel(event);
        closeModal();
      };
      const onSubmitClick = event => {
        event.preventDefault();
        if (onSubmit) onSubmit(event);
      };

      return (
        <form onSubmit={onSubmitClick}>
          <ModalHeader title="Download" onCloseClick={onCancelClick} />
          <ModalBody>
            <p>We are interested in learning more from you before initiating this download.</p>
            <p className="contact-us__form-title">Your Information</p>
            <Row>
              <Col>
                <TextInput
                  id="request.contactInfo.name"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  value={contact.name}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'request.contactInfo.name')}
                  disabled={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  id="request.contactInfo.company"
                  name="company"
                  label="Company"
                  placeholder="Company"
                  value={contact.company}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'request.contactInfo.company')}
                  disabled={loading}
                />
              </Col>
              <Col>
                <TextInput
                  id="request.contactInfo.role"
                  name="role"
                  label="Role"
                  placeholder="Role"
                  value={contact.role}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'request.contactInfo.role')}
                  disabled={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  id="request.contactInfo.phone"
                  name="phone"
                  type="tel"
                  label="Phone Number"
                  placeholder="(212) 222-2222"
                  value={contact.phone}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'request.contactInfo.phone')}
                  disabled={loading}
                />
              </Col>
              <Col>
                <TextInput
                  id="request.contactInfo.email"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="email.address@domain.com"
                  value={contact.email}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'request.contactInfo.email')}
                  disabled={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TypeaheadInput
                  id="request.contactInfo.country"
                  name="country"
                  label="Country"
                  placeholder="Country"
                  value={contact.country}
                  onChange={onChange}
                  validation={validationFor(validationErrors, 'request.contactInfo.country')}
                  disabled={loading}
                  list={countries}
                  placement="bottom-start"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FeButton type="reset" feStyle="secondary-danger" onClick={onClear} className="modal__clear">
              Clear
            </FeButton>
            <FeButton type="button" feStyle="secondary" onClick={onCancelClick} className="modal__cancel">
              Cancel
            </FeButton>{' '}
            <FeButton type="submit" feStyle="primary" onClick={onSubmitClick} className="modal__submit">
              Download
            </FeButton>
          </ModalFooter>
        </form>
      );
    }}
  </Modal>
);

CollectData.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onClear: PropTypes.func,
  loading: PropTypes.bool,
  validationErrors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    })
  ),
  isOpen: PropTypes.bool
};

CollectData.defaulProps = {
  loading: false,
  validationErrors: [],
  onCancel: () => {}
};

export default CollectData;
