import gql from 'graphql-tag';

const APP_SUBSCRIPTIONS_QUERY = gql`
  query ListAppSubscriptions {
    subscriptions {
      ... on AppSubscription {
        id
      }
    }
  }
`;

const IS_AUTHENTICATED_QUERY = gql`
  query IsAuthenticated {
    authentication @client {
      isAuthenticated
    }
  }
`;

export { APP_SUBSCRIPTIONS_QUERY, IS_AUTHENTICATED_QUERY };
