import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'fe-fabric-react';
import AppCarousel from '../AppCarousel/AppCarousel';
import AppDetailsTable from '../AppDetailsTable/AppDetailsTable';
import Download from './Download';
import SupportInfo from './SupportInfo';
import SocialShare from '../SocialShare/SocialShare';
import WatchApp from '../WatchApp';
import { TagList, LinkTag } from '../Tag';

const distributionInfo = (app, distribution) => {
  const info = [];
  const { integrationDetails, parserDetails, platforms = [] } = app;

  if (app.version || distribution.version) {
    info.push(['Version', app.version || distribution.version]);
  }
  if (app.type.id === 'endpoint_security_modules' && app.updatedAt) {
    let updatedDate = new Date(app.updatedAt).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    info.push(['Last Updated', updatedDate]);
  }
  if (platforms.length > 0) {
    info.push(['Platform', platforms.map(platform => platform.name).join(', ')]);
  }
  if (app.requirements) {
    info.push(['Requirements', app.requirements]);
  }
  if (distribution.fileSize) {
    info.push(['Size', `${distribution.fileSize} MB`]);
  }
  if (distribution.md5) {
    info.push([
      'MD5',
      <span key={app.id} className="hash hash--md5">
        {distribution.md5}
      </span>
    ]);
  }
  if (distribution.sha1) {
    info.push([
      'SHA1',
      <span key={app.id} className="hash hash--sha1">
        {distribution.sha1}
      </span>
    ]);
  }
  if (distribution.sha256) {
    info.push([
      'SHA256',
      <span key={app.id} className="hash hash--sha256">
        {distribution.sha256}
      </span>
    ]);
  }
  /* Integration Details (Intel Feeds only) */
  if (integrationDetails) {
    if (integrationDetails.apiVersion) info.push(['API Version', integrationDetails.apiVersion]);
    if (integrationDetails.installationType) {
      info.push(['Installation Type', integrationDetails.installationType.map(type => type).join(', ')]);
    }
    if (integrationDetails.license)
      info.push([
        'License',
        <span key="app-license" dangerouslySetInnerHTML={{ __html: integrationDetails.license }} />
      ]);
  }

  /* Parser Details (Helix Parsers only) */
  if (parserDetails) {
    if (parserDetails.supportedVersions) info.push(['Product Versions', parserDetails.supportedVersions]);
    if (parserDetails.supportedFormats) info.push(['Supported Formats', parserDetails.supportedFormats]);
  }

  return info;
};

const appInfoTypeName = typeId => {
  switch (typeId) {
    case 'endpoint_security_modules':
      return 'Module';
    case 'helix_parsers':
      return 'Parser';
    case 'intel_feeds':
      return 'Integration';
    case 'open-source_software':
      return 'OSS';
    case 'orchestration_add-on':
    case 'orchestration_add-ons':
    case 'xagent_add-on':
      return 'Add-On';
    case 'product_extensions':
      return 'Extension';
    default:
      return 'App';
  }
};

const AppDetails = ({ app = {}, assentedToTerms, subscribe, unsubscribe }) => {
  const { categories, distributions = [], platforms, products, thirdPartyProducts, type = {}, downloadInfo = {} } = app;
  const tagSets = { categories, platforms, products, thirdPartyProducts, types: [type] };
  const hasBeenDownloaded = !!downloadInfo && !!downloadInfo.firstDownloaded;
  const [isSubscribed, setIsSubscribed] = useState(!!app.subscriptionInfo);
  let watchAppRef = React.createRef();

  const subscribeAndUpdate = () => {
    watchAppRef.current.subscribe();
    setIsSubscribed(true);
  };
  return (
    <>
      <Row>
        <Col className="text-center" sm={12} md={{ size: 10, offset: 1 }} lg={{ size: 4, offset: 1 }}>
          <AppCarousel {...app} />
        </Col>
        <Col className="app-details__description" md={12} lg={6}>
          <h1 className="app-details__description--app-name">{app.name}</h1>
          <div className="app-details__description--text" dangerouslySetInnerHTML={{ __html: app.description }} />
          <div className="app-details__description--download">
            <Download
              app={app}
              assentedToTerms={assentedToTerms}
              hasBeenDownloaded={hasBeenDownloaded}
              subscribe={subscribeAndUpdate}
            />
            <SocialShare title={app.name} summary={app.summary} enabled />
            <WatchApp
              app={app}
              subscribe={subscribe}
              isSubscribed={isSubscribed}
              unsubscribe={unsubscribe}
              ref={watchAppRef}
            />
          </div>
          <div className="app-details__description--tags">
            <h3>Tags</h3>
            <TagList tagSets={tagSets}>
              {({ tag, group }) => (
                <LinkTag to={{ pathname: '/browse', search: `?${group}=${tag.id}` }}>{tag.name}</LinkTag>
              )}
            </TagList>
          </div>
        </Col>
      </Row>
      <Row className="app-details__info">
        <Col className="app-details__support" xs={12} lg={{ size: 4, offset: 1 }}>
          <h3>Support</h3>
          <SupportInfo app={app} />
        </Col>
        <Col className="app-details__distributions" xs={12} lg={{ size: 6 }}>
          <h3 className="app-details__info--title">{appInfoTypeName(type.id)} Info</h3>
          {distributions.map(distribution => (
            <AppDetailsTable key={distribution.type} rows={distributionInfo(app, distribution)} />
          ))}
        </Col>
      </Row>
    </>
  );
};

AppDetails.propTypes = {
  app: PropTypes.shape({
    developerName: PropTypes.string,
    supportedBy: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    platforms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ),
    thirdPartyProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ),
    distributions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        platforms: PropTypes.arrayOf(PropTypes.string),
        requirements: PropTypes.string,
        fileSize: PropTypes.number,
        md5: PropTypes.string,
        sha1: PropTypes.string,
        sha256: PropTypes.string
      }).isRequired
    ),
    downloadInfo: PropTypes.shape({
      firstDownloaded: PropTypes.string,
      lastDownloaded: PropTypes.string
    }),
    type: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    integrationDetails: PropTypes.shape({
      apiVersion: PropTypes.string,
      installationType: PropTypes.array,
      license: PropTypes.string
    }),
    parserDetails: PropTypes.shape({
      supportedVersions: PropTypes.string,
      supportedFormats: PropTypes.string
    })
  }).isRequired,
  isSubscribable: PropTypes.bool,
  assentedToTerms: PropTypes.bool,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  subscriptionInfo: PropTypes.shape({
    inApp: PropTypes.bool
  })
};

AppDetails.defaultProps = {
  assentedToTerms: false
};

export default AppDetails;
