import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

const LinkedInButton = ({ title, summary }) => {
  const qs = {
    url: window.location.href,
    mini: true,
    source: 'FireEye Market'
  };
  if (title) {
    qs.title = title.length > 200 ? title.substring(0, 197) + '...' : title;
  }
  if (summary) {
    qs.summary = summary.length > 256 ? summary.substring(0, 253) + '...' : summary;
  }

  return (
    <a
      className="fe-btn fe-btn--link fe-btn--md"
      href={`https://www.linkedin.com/shareArticle?${queryString.stringify(qs)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={['fab', 'linkedin-in']} fixedWidth /> LinkedIn
    </a>
  );
};

LinkedInButton.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string
};

export default LinkedInButton;
