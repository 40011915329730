import { graphql } from 'react-apollo';
import TOGGLE_MODAL from '../../components/AppModal/ToggleModal.mutation';

const withCloseModal = graphql(TOGGLE_MODAL, {
  alias: 'withCloseModal',
  name: 'closeModal',
  options: {
    variables: { modal: null }
  }
});

export default withCloseModal;
