import { ConsoleLogger } from '@aws-amplify/core';
import Analytics from '../Analytics';

class AuthListenerForAnalytics {
  constructor({ logger } = { logger: new ConsoleLogger('AuthForAnalytics') }) {
    this.logger = logger;
    this.onAuthEvent = this.onAuthEvent.bind(this);
  }

  onAuthEvent({ payload: { event, data } }) {
    switch (event) {
      case 'signIn':
        Analytics.identify(data);
        break;
      case 'signOut':
        Analytics.reset();
        break;
      default:
        break;
    }
  }
}

export default AuthListenerForAnalytics;
