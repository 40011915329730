import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeAvatar, FeButton } from 'fe-fabric-react';
import { NavLink } from 'react-router-dom';
import AdminContext from '../../../AdminContext';
import Popover from '../../Popover';
import './AvatarMenu.css';

// eslint-disable-next-line react/prop-types
export const AdminMenuItems = ({ identity: { groups } }) => (
  <AdminContext.Consumer>
    {({ vendorAdminGroups }) => {
      const isVendorAdmin = groups.some((group) => vendorAdminGroups.includes(group));
      return (
        <>
          {isVendorAdmin && (
            <NavLink className="fe-btn fe-btn--link fe-btn--md" to="/admin/apps">
              Admin
            </NavLink>
          )}
        </>
      );
    }}
  </AdminContext.Consumer>
);

// eslint-disable-next-line react/prop-types
export const AvatarMenuContent = ({ identity, onLogoutClick }) => (
  <>
    <FeAvatar
      userInfo={{
        userName: identity.name,
      }}
      isLabelVisible
      isLabelInline={false}
    />
    <AdminMenuItems identity={identity} />
    <FeButton feStyle="link" className="avatar-menu__popover--sign-out" onClick={onLogoutClick}>
      <FontAwesomeIcon className="avatar-menu__popover--sign-out-icon" icon={['far', 'sign-out-alt']} fixedWidth />
      Sign Out
    </FeButton>
  </>
);

const AvatarMenu = ({ identity, onLogoutClick }) => {
  // eslint-disable-next-line react/prop-types
  const AvatarMenuTrigger = ({ toggle }) => (
    <FeAvatar
      userInfo={{
        userName: identity.name,
      }}
      isSmall
      onClick={toggle}
      role="button"
      tabIndex={0}
    />
  );

  return (
    <div className="avatar-menu">
      <Popover
        trigger={AvatarMenuTrigger}
        className="avatar-menu__popover"
        placement="bottom-end"
        modifiers={{ offset: { offset: 4 } }}
      >
        <AvatarMenuContent identity={identity} onLogoutClick={onLogoutClick} />
      </Popover>
    </div>
  );
};

AvatarMenu.propTypes = {
  identity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    preferred_username: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default AvatarMenu;
