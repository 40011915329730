import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FeBadge } from 'fe-fabric-react';
import TileList from '../TileList/TileList';
import ListSort from '../ListSort/ListSort';
import FilterBar from '../FilterBar/FilterBar';
import Loading from '../Loading/Loading';

const SearchResults = ({
  items,
  total,
  sortOptions,
  batchSize,
  loading,
  hasMore,
  loadMore,
  order,
  onSort,
  filters,
  onRemoveFilter
}) => (
  <TileList tiles={items} batchSize={batchSize} loading={loading} hasMore={hasMore} onLoadMore={loadMore}>
    <Row className="results-header__row">
      <Col>
        <h2 className="results-header__title">
          Results <FeBadge label={String(total)} max={100000} /> {loading && <Loading size="l" inline text="" />}
        </h2>
      </Col>
      <Col className="results-header__sort">
        <ListSort label="Sort" sortOptions={sortOptions} selectedKey={order} onOptionSelected={onSort} />
      </Col>
    </Row>
    <hr />
    <FilterBar filters={filters} onRemoveFilter={onRemoveFilter} className="results-header__filters" />
  </TileList>
);

SearchResults.defaultProps = {
  order: null,
  items: [],
  total: 0,
  sortOptions: [],
  batchSize: 0,
  filters: {}
};

SearchResults.propTypes = {
  items: PropTypes.array,
  total: PropTypes.number,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  order: PropTypes.string,
  batchSize: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  onSort: PropTypes.func,
  filters: PropTypes.object.isRequired,
  onRemoveFilter: PropTypes.func.isRequired
};

SearchResults.displayName = 'SearchResults';

export default SearchResults;
