import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Notification from './Notification/Notification';
import Loading from '../Loading/Loading';
import './NotificationList.css';

const NotificationsSection = ({ title, items, viewNotification, showDisposition }) => {
  if (items.length === 0) return null;
  return (
    <section className={classNames('notifications__section', `notifications__section--${title.toLowerCase()}`)}>
      <div className="notifications__section-title">{title}</div>
      {items.map((notification, index) => (
        <CSSTransition key={notification.id} classNames="animate" timeout={1000} in mountOnEnter unmountOnExit>
          <Notification
            notification={notification}
            viewNotification={viewNotification}
            showDisposition={showDisposition}
            style={{ transitionDelay: `${index * 0.01}s` }}
          />
        </CSSTransition>
      ))}
    </section>
  );
};

NotificationsSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  viewNotification: PropTypes.func,
  showDisposition: PropTypes.bool
};

class NotificationList extends React.Component {
  state = {
    noItems: true,
    newItems: [],
    earlierItems: []
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const noItems = nextProps.items.length === 0;
    const newItems = nextProps.items.filter(item => item.createdAt > yesterday);
    const earlierItems = nextProps.items.slice(newItems.length);
    return Object.assign({}, nextProps, { noItems, newItems, earlierItems });
  }

  render() {
    const { loading, noItems, newItems, earlierItems } = this.state;
    const { viewNotification, showDisposition } = this.props;

    return (
      <div className={classNames('notifications', { 'notifications--empty': noItems })}>
        {loading && <Loading size="l" inline />}
        {!loading && noItems && 'No Notifications'}
        <TransitionGroup component={null}>
          <NotificationsSection
            title="new"
            items={newItems}
            viewNotification={viewNotification}
            showDisposition={showDisposition}
          />
          <NotificationsSection
            title="earlier"
            items={earlierItems}
            viewNotification={viewNotification}
            showDisposition={showDisposition}
          />
        </TransitionGroup>
      </div>
    );
  }
}

NotificationList.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array.isRequired,
  viewNotification: PropTypes.func.isRequired,
  showDisposition: PropTypes.bool
};

NotificationList.defaultProps = {
  loading: false,
  items: []
};

export default NotificationList;
