import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withAppSubscriptions from '../../WatchApp/withAppSubscriptions';
import WatchApp from '../../WatchApp';
import './AppSubscription.css';

const AppSubscription = ({ app, subscribe, unsubscribe }) => (
  <section className="app-subscription">
    <header className="app-subscription__header">
      <h3>
        <Link to={`/apps/${app.id}`}>{app.name}</Link>
      </h3>
      <p>{app.summary}</p>
    </header>
    <main className="app-subscription__body">
      <WatchApp app={app} isSubscribed={!!app.subscriptionInfo} subscribe={subscribe} unsubscribe={unsubscribe} />
    </main>
  </section>
);

AppSubscription.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isSubscribable: PropTypes.bool,
    subscriptionInfo: PropTypes.shape({
      inApp: PropTypes.bool
    })
  }),
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired
};

export default withAppSubscriptions(AppSubscription);
