import { getAuthDataForGraphQL } from './authUtils';

const createResolvers = auth => ({
  Query: {
    // Delegate to Amplify for initial state. This allows us to use
    // Amplify's persistence mechanism to load Auth state across page loads
    authentication: () => auth.getUser().then(getAuthDataForGraphQL),
    assentedToTerms: (_, { id }) => {
      const item = sessionStorage.getItem(`AppTermsAssent-${id}`);
      if (item) return JSON.parse(item);
      return { id, assent: false, __typename: 'AppTermsAssent' };
    }
  },
  // fieldName(obj, args, context, info) { result }
  // See https://www.apollographql.com/docs/graphql-tools/resolvers.html#Resolver-function-signature
  Mutation: {
    assentToAppTerms: (_, { id, assent }, { cache }) => {
      const assentedToTerms = { id, assent, __typename: 'AppTermsAssent' };
      cache.writeData({ data: { assentedToTerms } });
      sessionStorage.setItem(`AppTermsAssent-${id}`, JSON.stringify(assentedToTerms));
      return assentedToTerms;
    },
    toggleModal: (_, { modal }, { cache }) => {
      cache.writeData({ data: { modal } });
      return { modal: modal };
    }
  }
});

export default createResolvers;
