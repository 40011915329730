import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { titleize } from 'inflected';
import Analytics from '../../../../Analytics';
import SubscriptionToggle from '../../SubscriptionToggle/SubscriptionToggle';

class BroadcastToggle extends Component {
  subscribe = () => {
    this.props.subscribe();
    Analytics.record('Subscribe', { broadcast: true, category: this.props.category });
  };

  unsubscribe = () => {
    this.props.unsubscribe();
    Analytics.record('Unsubscribe', { broadcast: true, category: this.props.category });
  };

  render() {
    const { category, isSubscribed, disabled } = this.props;
    const id = `market-broadcast-${category}`;
    const label = `Subscribe to ${titleize(category)}`;
    const onChange = isSubscribed ? this.unsubscribe : this.subscribe;
    return (
      <SubscriptionToggle id={id} label={label} isSubscribed={isSubscribed} onChange={onChange} disabled={disabled} />
    );
  }
}

BroadcastToggle.propTypes = {
  category: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default BroadcastToggle;
