import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell as fasBell,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faCheckSquare as fasCheckSquare,
  faCog as fasCog,
  faDoNotEnter as fasDoNotEnter,
  faDownload as fasDownload,
  faEnvelope as fasEnvelope,
  faExclamationCircle as fasExclamationCircle,
  faExternalLinkAlt as fasExternalLinkAlt,
  faInfoCircle as fasInfoCircle,
  faSpinnerThird as fasSpinnerThird,
  faTimesCircle as fasTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faArrowAltCircleLeft as farArrowAltCircleLeft,
  faArrowCircleLeft as farArrowCircleLeft,
  faAngleDown as farAngleDown,
  faAngleUp as farAngleUp,
  faBell as farBell,
  faBox as farBox,
  faCameraRetro as farCameraRetro,
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faClone as farClone,
  faCog as farCog,
  faComment as farComment,
  faDesktop as farDesktop,
  faDownload as farDownload,
  faEye as farEye,
  faExclamationTriangle as farExclamationTriangle,
  faFile as farFile,
  faFileUpload as farFileUpload,
  faFilter as farFilter,
  faHandshake as farHandshake,
  faInfoCircle as farInfoCircle,
  faMobile as farMobile,
  faSearch as farSearch,
  faSignOutAlt as farSignOutAlt,
  faShareAlt as farShareAlt,
  faSpinnerThird as farSpinnerThird,
  faPlusCircle as farPlusCircle,
  faTimesCircle as farTimesCircle,
  faUnlink as farUnlink,
  faLink as farLink,
  faEdit as farEdit,
  faTrashAlt as farTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faChevronCircleDown as falChevronCircleDown,
  faChevronCircleRight as falChevronCircleRight,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faChevronUp as falChevronUp,
  faExclamationCircle as falExclamationCircle,
  faQuestionCircle as falQuestionCircle,
  faSquare as falSquare,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
} from '@fortawesome/pro-light-svg-icons';

import {
  faApple as fabrApple,
  faAppStore as fabrAppStore,
  faAppStoreIos as fabrAppStoreIos,
  faAndroid as fabrAndroid,
  faChrome as fabrChrome,
  faFacebookF as fabrFacebookF,
  faFirefox as fabrFirefox,
  faGithub as fabrGithub,
  faGooglePlay as fabrGooglePlay,
  faLinkedinIn as fabrLinkedinIn,
  faLinux as fabrLinux,
  faPython as fabrPython,
  faTwitter as fabrTwitter,
  faWindows as fabrWindows,
} from '@fortawesome/free-brands-svg-icons';

import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

library.add(
  fasBell,
  fasCheck,
  fasCheckCircle,
  fasCheckSquare,
  fasCog,
  farCircle,
  fasDoNotEnter,
  fasDownload,
  fasEnvelope,
  fasExclamationCircle,
  fasExternalLinkAlt,
  fasInfoCircle,
  fasSpinnerThird,
  fasTimesCircle,
  farArrowAltCircleLeft,
  farArrowCircleLeft,
  farAngleDown,
  farAngleUp,
  farBell,
  farBox,
  farCameraRetro,
  farCheckCircle,
  farClone,
  farCog,
  farComment,
  farDesktop,
  farDownload,
  farEye,
  farExclamationTriangle,
  farFile,
  farFileUpload,
  farFilter,
  farHandshake,
  farInfoCircle,
  farPlusCircle,
  farMobile,
  farSearch,
  farShareAlt,
  farSpinnerThird,
  farTimesCircle,
  farUnlink,
  falChevronCircleDown,
  falChevronCircleRight,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falChevronUp,
  falExclamationCircle,
  falQuestionCircle,
  falSquare,
  farSignOutAlt,
  falTimes,
  fabrApple,
  fabrAppStore,
  fabrAppStoreIos,
  fabrAndroid,
  fabrChrome,
  fabrFacebookF,
  fabrFirefox,
  fabrGithub,
  fabrGooglePlay,
  fabrLinux,
  fabrLinkedinIn,
  fabrPython,
  fabrTwitter,
  fabrWindows,
  farEdit,
  farLink,
  farTrashAlt,
  falTimesCircle
);
