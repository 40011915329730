function errorFor(validationErrors, field) {
  if (!validationErrors) return undefined;
  return validationErrors.find(el => el.field === field);
}

function isFieldInvalid(validationErrors, field) {
  return errorFor(validationErrors, field) !== undefined;
}

function feedbackFor(validationErrors, field) {
  const error = errorFor(validationErrors, field);
  if (error) return error.messages.join('\n');
  return undefined;
}

function validationFor(validationErrors, field) {
  const errorForField = errorFor(validationErrors, field);
  if (!errorForField) return undefined;
  return {
    type: 'error',
    message: errorForField.messages.join('\n')
  };
}

export { errorFor, isFieldInvalid, feedbackFor, validationFor };
