import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '../ExternalLink/ExternalLink';
import CustomPropTypes from '../../customPropTypes';
import ContactListRow from './ContactListRow';

const VendorOverview = ({ websiteUrl, partnerType, contacts }) => {
  const hasContacts = contacts && contacts.length > 0;
  return (
    <table>
      <tbody>
        {websiteUrl && (
          <tr>
            <th>Website</th>
            <td>
              <ExternalLink href={websiteUrl}>{websiteUrl}</ExternalLink>
            </td>
          </tr>
        )}
        <tr>
          <th>Partnership Type</th>
          <td>{partnerType}</td>
        </tr>
        {hasContacts && <ContactListRow title="Contacts" list={contacts} />}
      </tbody>
    </table>
  );
};
VendorOverview.displayName = 'VendorOverview';
VendorOverview.propTypes = {
  websiteUrl: PropTypes.string,
  partnerType: PropTypes.string,
  contacts: PropTypes.arrayOf(CustomPropTypes.contact)
};

export default VendorOverview;
