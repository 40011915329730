import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { IS_AUTHENTICATED_QUERY } from './AppSubscriptions.query';
import { SUBSCRIBE_TO_APP, UNSUBSCRIBE_FROM_APP } from './WatchApp.mutation';

const checkAuthentication = graphql(IS_AUTHENTICATED_QUERY, {
  props: ({ data: { authentication: { isAuthenticated } = { isAuthenticated: false } } }) => ({ isAuthenticated })
});

const ifUnauthenticated = ({ isAuthenticated }) => !isAuthenticated;

const fragment = gql`
  fragment appSubscription on App {
    subscriptionInfo {
      inApp
    }
  }
`;

const subscribeToApp = graphql(SUBSCRIBE_TO_APP, {
  name: 'subscribe',
  skip: ifUnauthenticated,
  options: ({ app }) => ({
    variables: { id: app.id },
    optimisticResponse: {
      __typename: 'Mutation',
      subscribe: {
        __typename: 'AppSubscriptionResult',
        item: {
          __typename: 'AppSubscription',
          id: app.id
        }
      }
    },
    update: proxy => {
      const id = defaultDataIdFromObject(app);
      const data = proxy.readFragment({ id, fragment });
      if (data) {
        const updatedData = Object.assign(data, data.subscriptionInfo, {
          __typename: 'AppSubscriptionInfo',
          inApp: true
        });
        proxy.writeFragment({ id, fragment, data: updatedData });
      }
    }
  })
});

const unsubscribeFromApp = graphql(UNSUBSCRIBE_FROM_APP, {
  name: 'unsubscribe',
  skip: ifUnauthenticated,
  options: ({ app }) => ({
    variables: { id: app.id },
    optimisticResponse: {
      __typename: 'Mutation',
      unsubscribe: {
        __typename: 'AppSubscriptionResult',
        item: {
          __typename: 'AppSubscription',
          id: app.id
        }
      }
    },
    update: proxy => {
      const id = defaultDataIdFromObject(app);
      const data = proxy.readFragment({ id, fragment });
      if (data) {
        const subscriptionInfo = Object.assign({}, data.subscriptionInfo, {
          __typename: 'AppSubscriptionInfo',
          inApp: false
        });
        const updatedData = Object.assign({}, data, { subscriptionInfo });
        proxy.writeFragment({ id, fragment, data: updatedData });
      }
    }
  })
});

export { fragment };

export default compose(checkAuthentication, subscribeToApp, unsubscribeFromApp);
