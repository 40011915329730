import React from 'react';
import PropTypes from 'prop-types';
import AppDetailsTable from '../AppDetailsTable/AppDetailsTable';
import ExternalLink from '../ExternalLink/ExternalLink';

const kbLinkText = app => (app.type.id === 'open-source_software' ? 'Issues' : 'Community');

const SupportInfo = ({ app }) => {
  const { supportInfo = {}, supportedBy = [] } = app;
  const rows = [];

  if (app.developerName) rows.push(['Developer', app.developerName]);
  supportedBy.forEach((val, index) => rows.push([index === 0 ? 'Supported By' : null, val.name]));
  if (supportInfo.description) rows.push(['Support', supportInfo.description]);
  [
    supportInfo.email ? (
      <a key={app.id} href={`mailto:${supportInfo.email}`}>
        Email
      </a>
    ) : null,
    supportInfo.phone ? (
      <a key={app.id} href={`tel:${supportInfo.phone}`}>
        {supportInfo.phone}
      </a>
    ) : null,
    supportInfo.knowledgebaseUrl ? (
      <ExternalLink key={app.id} href={supportInfo.knowledgebaseUrl}>
        {kbLinkText(app)}
      </ExternalLink>
    ) : null
  ]
    .filter(Boolean)
    .forEach((link, index) => rows.push([index === 0 ? 'Contact' : null, link]));

  const resources = app.documents.concat(app.links);
  if (app.documentationUri) resources.unshift({ name: 'Documentation', uri: app.documentationUri });

  resources.forEach((doc, index) =>
    rows.push([
      index === 0 ? 'Resources' : null,
      <ExternalLink key={app.id} href={doc.uri}>
        {doc.name}
      </ExternalLink>
    ])
  );

  return <AppDetailsTable rows={rows} />;
};

SupportInfo.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    supportInfo: PropTypes.shape({
      description: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      knowledgebaseUrl: PropTypes.string
    }),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired
      })
    ).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};

export default SupportInfo;
