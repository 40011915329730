import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo';

const SNAPSHOT_COMPATIBLE_APPS = loader('./SnapshotCompatibleApps.graphql');

const skip = ({ vendor }) => vendor === undefined;

const options = ({ vendor, version }) => ({
  variables: {
    vendor,
    version,
    limit: 4
  }
});

const props = ({ data: { loading, vendorSnapshot, error } }) => {
  let name = 'Vendor';
  let compatibleApps = { total: 0, apps: [] };
  if (vendorSnapshot) {
    name = vendorSnapshot.name;
    compatibleApps = vendorSnapshot.compatibleApps;
  }
  return {
    loading,
    name,
    compatibleApps,
    error
  };
};

const withSnapshotCompatibleApps = graphql(SNAPSHOT_COMPATIBLE_APPS, {
  alias: 'withSnapshotCompatibleApps',
  skip,
  options,
  props
});

export default withSnapshotCompatibleApps;
export { skip, options, props };
