import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../customPropTypes';

const ContactListRow = ({ title, list }) => (
  <tr>
    <th>{title}</th>
    <td>
      <ul>
        {list.map(contact => (
          <li key={contact.email}>
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </li>
        ))}
      </ul>
    </td>
  </tr>
);
ContactListRow.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(CustomPropTypes.contact).isRequired
};

export default ContactListRow;
