import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MarketLogo } from './market.svg';
import './Logo.css';

const Logo = () => (
  <Link to="/" className="logo">
    <MarketLogo role="img" title="FireEye Market" />
  </Link>
);

export default Logo;
