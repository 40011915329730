const getAuthDataForGraphQL = userInfo => {
  let identity = null;
  if (userInfo) {
    const cognitoGroups = userInfo['cognito:groups'];
    identity = Object.assign(
      {
        name: '',
        email: '',
        preferred_username: '',
        groups: cognitoGroups || [],
        __typename: 'Identity'
      },
      userInfo
    );
    delete identity['cognito:groups'];
  }

  const isAuthenticated = !!(userInfo && userInfo.identities && userInfo.identities.length > 0);
  const data = {
    __typename: 'Authentication',
    isAuthenticated,
    identity
  };
  return data;
};

export { getAuthDataForGraphQL };
