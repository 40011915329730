import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const TOGGLE_MODAL = gql`
  mutation ToggleModal($modal: String) {
    toggleModal(modal: $modal) @client
  }
`;

const withCloseModal = graphql(TOGGLE_MODAL, {
  name: 'closeModal',
  options: {
    variables: { modal: null }
  }
});

export default withCloseModal;
