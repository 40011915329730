import React from 'react';
import PropTypes from 'prop-types';
import { FeButton } from 'fe-fabric-react';

const IncomingQueue = ({ notifications, release, countThreshold }) => {
  const length = notifications.length;
  if (length === 0) return null;
  const count = length > countThreshold ? `${countThreshold}+` : length;
  let noun = 'Notification';
  if (length > 1) noun = noun + 's';
  return (
    <div className="incoming-queue">
      <FeButton onClick={release} feStyle="primary" size="lg">
        See {count} more {noun}
      </FeButton>
    </div>
  );
};

IncomingQueue.propTypes = {
  notifications: PropTypes.array.isRequired,
  release: PropTypes.func,
  countThreshold: PropTypes.number
};

IncomingQueue.defaultProps = {
  notifications: [],
  countThreshold: 10
};

export default IncomingQueue;
