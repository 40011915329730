import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPropTypes from '../../customPropTypes';

const BackLink = ({ location, fallback }) => {
  const hasReturnTo = location.state && location.state.returnTo;
  const hasReturnTitle = location.state && location.state.returnToTitle;
  const backToLocation = hasReturnTo ? location.state.returnTo : fallback.location;
  const backButtonText = hasReturnTitle ? location.state.returnToTitle : fallback.title;

  return (
    <div className="go-back">
      <Link to={backToLocation} className="link">
        <FontAwesomeIcon icon={['far', 'arrow-circle-left']} fixedWidth />
        <span>Back to {backButtonText}</span>
      </Link>
    </div>
  );
};

BackLink.displayName = 'BackLink';

BackLink.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      returnTo: CustomPropTypes.reactRouterLocation(),
      returnToTitle: PropTypes.string
    })
  }).isRequired,
  fallback: PropTypes.shape({
    location: CustomPropTypes.reactRouterLocation().isRequired,
    title: PropTypes.string.isRequired
  })
};

BackLink.defaultProps = {
  fallback: {
    location: '/',
    title: 'Home'
  }
};

export default BackLink;
