import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Analytics from '../../Analytics';
import SubscriptionToggle from '../Notifications/SubscriptionToggle/SubscriptionToggle';

class WatchApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: !props.app.isSubscribable,
      isSubscribed: props.isSubscribed
    };
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  subscribe = () => {
    this.props.subscribe();
    this.setState({ isSubscribed: true });
    Analytics.record('Subscribe', { appId: this.props.app.id });
  };

  unsubscribe = () => {
    this.props.unsubscribe();
    this.setState({ isSubscribed: false });
    Analytics.record('Unsubscribe', { appId: this.props.app.id });
  };

  render() {
    const { app } = this.props;
    const { disabled, isSubscribed } = this.state;
    const id = `watch-app-${app.id}`;
    const label = 'Subscribe to Updates?';
    const onChange = isSubscribed ? this.unsubscribe : this.subscribe;
    return (
      <SubscriptionToggle id={id} label={label} isSubscribed={isSubscribed} disabled={disabled} onChange={onChange} />
    );
  }
}

WatchApp.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isSubscribable: PropTypes.bool,
    subscriptionInfo: PropTypes.shape({
      inApp: PropTypes.bool
    })
  }),
  isSubscribed: PropTypes.bool,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired
};

export default WatchApp;
