import gql from 'graphql-tag';

const SUBSCRIBE_TO_APP = gql`
  mutation SubscribeToApp($id: ID!) {
    subscribe(id: $id) {
      item {
        id
      }
    }
  }
`;

const UNSUBSCRIBE_FROM_APP = gql`
  mutation UnsubscribeFromApp($id: ID!) {
    unsubscribe(id: $id) {
      item {
        id
      }
    }
  }
`;

export { SUBSCRIBE_TO_APP, UNSUBSCRIBE_FROM_APP };
