import React from 'react';
import PropTypes from 'prop-types';
import './AppDetailsTable.css';

const AppDetailsTable = props => {
  return (
    <table className="app-details-table">
      <tbody>
        {props.rows.map((row, index) => {
          const title = row[0] ? `${row[0]}:` : null;
          return (
            <tr key={index}>
              <td className="labels-column">{title}</td>
              <td>{row[1]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

AppDetailsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default AppDetailsTable;
